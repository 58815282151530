export const MEDIA_TYPE = [
    { name: 'Image', key: 'IMAGE', id: 1 },
    { name: 'Video', key: 'VIDEO', id: 2 },
    { name: 'Reel', key: 'REEL', id: 3 },
    { name: 'Shorts', key: 'SHORTS', id: 3 }
]

export const LINK_COUNT_TYPE = [
    { name: 'Views', key: 'VIEWS', id: 1 },
    { name: 'Comments', key: 'COMMENTS', id: 2 },
    { name: 'Likes', key: 'LIKES', id: 3 },
    { name: 'Share', key: 'SHARE', id: 4 },
]