import { takeLatest, call, put } from 'redux-saga/effects'
import {
    customerAddBankInfoAPI, customerDetailAPI, customerListAPI,
    customerUpdateInfoAPI, customerUpdateBankInfoAPI
} from '../api-requests/customer';
import {
    customerAddBankDetailRequestError, customerAddBankDetailRequestSuccess,
    customerDetailRequestError, customerDetailRequestSuccess, customerListRequestError,
    customerListRequestSuccess, customerUpdateInfoRequestError, customerUpdateInfoRequestSuccess,
    customerUpdateBankDetailRequestError, customerUpdateBankDetailRequestSuccess
} from './customer.action';
import { CustomerActionTypes } from './customer.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleCustomerUpdateBankInfo({ payload }) {
    try {
        const response = yield call(customerUpdateBankInfoAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerUpdateBankDetailRequestSuccess(decryptedData))
            } else {
                yield put(customerUpdateBankDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerUpdateBankDetailRequestError(error.response.data))
        }
    }
}

export function* customerUpdateBankInfoReq() {
    yield takeLatest(CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST, handleCustomerUpdateBankInfo)
}

function* handleCustomerUpdateInfo({ payload }) {
    try {
        const response = yield call(customerUpdateInfoAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerUpdateInfoRequestSuccess(decryptedData))
            } else {
                yield put(customerUpdateInfoRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerUpdateInfoRequestError(error.response.data))
        }
    }
}

export function* customerUpdateInfoReq() {
    yield takeLatest(CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST, handleCustomerUpdateInfo)
}

function* handleCustomerList({ payload }) {
    try {
        const response = yield call(customerListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerListRequestSuccess(decryptedData))
            } else {
                yield put(customerListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerListRequestError(error.response.data))
        }
    }
}

export function* customerListReq() {
    yield takeLatest(CustomerActionTypes.CUSTOMER_LIST_REQUEST, handleCustomerList)
}

function* handleCustomerDetail({ payload }) {
    try {
        const response = yield call(customerDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerDetailRequestSuccess(decryptedData))
            } else {
                yield put(customerDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerDetailRequestError(error.response.data))
        }
    }
}

export function* customerDetailReq() {
    yield takeLatest(CustomerActionTypes.CUSTOMER_DETAIL_REQUEST, handleCustomerDetail)
}

function* handleCustomerAddBank({ payload }) {
    try {
        const response = yield call(customerAddBankInfoAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerAddBankDetailRequestSuccess(decryptedData))
            } else {
                yield put(customerAddBankDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: CustomerActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerAddBankDetailRequestError(error.response.data))
        }
    }
}

export function* customerAddBankDetailReq() {
    yield takeLatest(CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST, handleCustomerAddBank)
}