import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { addMediaRequest, addMediaRequestStatus } from '../../../redux/media/media.action'
import { schemeListRequest } from '../../../redux/scheme/scheme.action'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../redux/user/user.action'
import Select from 'react-select'
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { isNull, isEmpty, displayErrorToast, displaySuccessToast, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import "react-datepicker/dist/react-datepicker.css";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import { LINK_COUNT_TYPE, MEDIA_TYPE } from "../../../utils/MediaType";
import { useNavigate } from 'react-router-dom';

const UploadMedia = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const { addMediaSuccess, addMediaError, addMediaReqStatus } = useSelector(state => state.media)
    const { schemeListSuccess, schemeListError, commonError } = useSelector((state) => state.scheme)
    const dispatch = useDispatch()
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [schemeDropdown, setSchemeDropdown] = useState()
    const [countryCode, setCountryCode] = useState('')
    const [mediaLink, setMediaLink] = useState('')
    const [mediaPlatform, setMediaPlatform] = useState('')
    const [mediaType, setMediaType] = useState('')
    const [viewCount, setViewCount] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [filteredSchemeList, setFilteredSchemeList] = useState([])
    const [mediaTypeDropdown, setMediaTypeDropdown] = useState()
    const imageRef = useRef(null)
    const [mediaList, setMediaList] = useState([])
    const [countTypeDropdown, setCountTypeDropdown] = useState()
    let navigate = useNavigate();

    useEffect(() => {
        if (!isNull(schemeListSuccess)) {
            setLoading(false)
            if (!isEmpty(schemeListSuccess.data)) {
                setFilteredSchemeList(schemeListSuccess.data)
            }
        } else if (!isNull(schemeListError)) {
            setLoading(false)
            setFilteredSchemeList([])
        }
    }, [schemeListSuccess, schemeListError])

    useEffect(() => {
        if (addMediaReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addMediaSuccess.message)
            clearFields()
            setTimeout(() => {
                navigate(-1)
            }, 700)
        } else if (addMediaReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addMediaError.message)
        }
        if (addMediaReqStatus.length) {
            dispatch(addMediaRequestStatus(''))
        }
    }, [addMediaSuccess, addMediaError, addMediaReqStatus])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
        setFilteredSchemeList([])
    }, [])

    const fetchStateList = (countryId) => {
        setFilteredSchemeList([])
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setFilteredSchemeList([])
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
        setFilteredSchemeList([])
        setImagePath(null)
        setImageName('')
    }

    const fetchSchemeList = (city) => {
        const data = {
            country: countryDropdown.name,
            state: stateDropdown.name,
            city: city.name
        }
        setLoading(true)
        dispatch(schemeListRequest(data))
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    const clearFields = () => {
        setMediaLink('')
        setMediaPlatform('')
        setMediaType('')
        setViewCount('')
        setMediaList([])
        setImagePath(null)
        setImageName('')
        if (imageRef.current) {
            imageRef.current.value = null
        }
    }

    const handleUploadMediaClick = () => {
        if (isNull(countryDropdown)) {
            displayErrorToast('Country not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('State not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('City not selected')
        } else if (isNull(schemeDropdown)) {
            displayErrorToast('Scheme not selected')
        } else if (isEmpty(mediaList)) {
            displayErrorToast('Media link not entered')
        } else {
            let fd = new FormData()
            fd.append('customerId', user.data.userDetails.customerId)
            fd.append('businessId', schemeDropdown.businessId)
            fd.append('businessName', schemeDropdown.businessDetail.businessName)
            fd.append('mediaLinkList', JSON.stringify(mediaList))
            fd.append('schemeId', schemeDropdown.schemeId)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            if (!isEmpty(imagePath) && !isEmpty(imageName)) {
                fd.append('image', imagePath, imageName)
            }
            setLoading(true)
            dispatch(addMediaRequest(fd))
        }
    }

    const handleSaveAsDraftClick = () => {
        if (isNull(countryDropdown)) {
            displayErrorToast('Country not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('State not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('City not selected')
        } else if (isNull(schemeDropdown)) {
            displayErrorToast('Promotion not selected')
        } else if (isEmpty(mediaList)) {
            displayErrorToast('Media link not entered')
        } else {
            let fd = new FormData()
            fd.append('customerId', user.data.userDetails.customerId)
            fd.append('businessId', schemeDropdown.businessId)
            fd.append('businessName', schemeDropdown.businessDetail.businessName)
            fd.append('mediaLinkList', JSON.stringify(mediaList))
            fd.append('schemeId', schemeDropdown.schemeId)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('mediaStatus', APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DRAFT)
            if (!isEmpty(imagePath) && !isEmpty(imageName)) {
                fd.append('image', imagePath, imageName)
            }
            setLoading(true)
            dispatch(addMediaRequest(fd))
        }
    }

    const handleClearLinkClear = () => {
        setMediaLink('')
        setMediaPlatform('')
        setMediaType('')
        setViewCount('')
    }

    const handleMediaAddClick = () => {
        if (isEmpty(mediaLink)) {
            displayErrorToast('Media link cannot be blank')
        } else if (isEmpty(mediaTypeDropdown)) {
            displayErrorToast('Link type not selected')
        } else if (isEmpty(mediaPlatform)) {
            displayErrorToast('Link platform cannot be blank')
        } else if (isEmpty(viewCount)) {
            displayErrorToast('Link count/number cannot be blank')
        } else if (parseFloat(viewCount) <= 0) {
            displayErrorToast('Link count/number cannot be less than 0')
        } else if (isEmpty(countTypeDropdown)) {
            displayErrorToast('Count type not selected')
        } else {
            const data = {
                mediaLink: mediaLink,
                mediaType: mediaTypeDropdown.key,
                mediaPlatform: mediaPlatform,
                mediaViewCount: viewCount,
                countType: countTypeDropdown.key
            }
            setMediaList((currentList) => {
                return [...currentList, data]
            })
            handleClearLinkClear()
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Social Media Link' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter below details to share social media link' />
                    <FormFieldLabel className='up-bold-label' label='Select Location' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow className='margin-bottom-10'>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                                fetchSchemeList(e)
                            }} />}
                    </CommonInputRow>
                    {!isNull(countryDropdown) && !isNull(stateDropdown) && !isNull(cityDropdown) && !isNull(schemeListSuccess) && !isEmpty(filteredSchemeList) && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={schemeDropdown}
                        getOptionLabel={e => e.schemeName}
                        getOptionValue={e => e}
                        options={filteredSchemeList}
                        placeholder='Select Promotion'
                        onChange={e => {
                            setSchemeDropdown(e)
                            // handleCountrySelectChange(e)
                        }} />}
                    {!isNull(countryDropdown) && !isNull(stateDropdown) && !isNull(cityDropdown) && isEmpty(filteredSchemeList) && !isNull(schemeListError) && <div>No Promotion found</div>}
                    <FormFieldLabel className='up-bold-label' label='Social Media Link Information' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='mediaLink'
                            onChange={e => setMediaLink(e.target.value)}
                            placeholder='Media Link'
                            value={mediaLink}
                            subText='Link should be publicly accessible'
                            isSubtext={true}

                        />
                        <div className="select-half-container">
                            <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={mediaTypeDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={MEDIA_TYPE}
                                placeholder='Media Type'
                                onChange={e => {
                                    setMediaTypeDropdown(e);
                                }} />
                        </div>
                    </CommonInputRow>
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='text'
                            id='mediaPlatform'
                            onChange={e => setMediaPlatform(e.target.value)}
                            placeholder='Link Platform'
                            value={mediaPlatform}
                        />
                        <CommonInputFloatHalf
                            type='number'
                            id='viewCount'
                            onChange={e => setViewCount(e.target.value)}
                            placeholder='Count/Number'
                            value={viewCount}
                        />
                    </CommonInputRow>
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countTypeDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={LINK_COUNT_TYPE}
                        placeholder='Link Count Type'
                        onChange={e => {
                            setCountTypeDropdown(e);
                        }} />
                    <div className="media-btn-parent-container">
                        <div className="media-btn-container" onClick={() => { handleClearLinkClear() }}>
                            <img src={require('../../../assets/ic_delete_red.png')} className="img" />
                        </div>
                        <div className="media-btn-container" onClick={() => { handleMediaAddClick() }}>
                            <img src={require('../../../assets/ic_done_big.png')} className="img" />
                        </div>
                    </div>
                    {!isEmpty(mediaList) && <div className="media-list-container">
                        {mediaList.map(e => (
                            <div className="media-list-row-container">
                                <div className="media-type-label">{e.mediaPlatform} {removeSpecialCharAndChangeToPascal(e.mediaType)}</div>
                                <div className="media-link-label">{e.mediaLink}</div>
                                <div className="media-platform-label"><span className="media-view-count-label">{e.mediaViewCount}</span> {removeSpecialCharAndChangeToPascal(e.countType)}</div>
                            </div>
                        ))}
                    </div>}
                    <FormFieldLabel label='Select Image (if any)' />
                    <div className="input-group">
                        <input type="file" ref={imageRef} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                    <ButtonRow isVertical={true}>
                        <CommonButton label='Earn Reward' onClick={() => {
                            handleUploadMediaClick()
                        }} />
                        <CommonButton label='Save as Draft' onClick={() => {
                            handleSaveAsDraftClick()
                        }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default UploadMedia