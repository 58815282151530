export const MediaActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_MEDIA_REQUEST: 'ADD_MEDIA_REQUEST',
    ADD_MEDIA_REQUEST_SUCCESS: 'ADD_MEDIA_REQUEST_SUCCESS',
    ADD_MEDIA_REQUEST_ERROR: 'ADD_MEDIA_REQUEST_ERROR',
    ADD_MEDIA_REQUEST_STATUS: 'ADD_MEDIA_REQUEST_STATUS',

    MEDIA_LIST_REQUEST: 'MEDIA_LIST_REQUEST',
    MEDIA_LIST_REQUEST_SUCCESS: 'MEDIA_LIST_REQUEST_SUCCESS',
    MEDIA_LIST_REQUEST_ERROR: 'MEDIA_LIST_REQUEST_ERROR',

    MEDIA_DETAIL_REQUEST: 'MEDIA_DETAIL_REQUEST',
    MEDIA_DETAIL_REQUEST_SUCCESS: 'MEDIA_DETAIL_REQUEST_SUCCESS',
    MEDIA_DETAIL_REQUEST_ERROR: 'MEDIA_DETAIL_REQUEST_ERROR',

    MEDIA_UPDATE_STATUS_REQUEST: 'MEDIA_UPDATE_STATUS_REQUEST',
    MEDIA_UPDATE_STATUS_REQUEST_SUCCESS: 'MEDIA_UPDATE_STATUS_REQUEST_SUCCESS',
    MEDIA_UPDATE_STATUS_REQUEST_ERROR: 'MEDIA_UPDATE_STATUS_REQUEST_ERROR',
    MEDIA_UPDATE_STATUS_REQUEST_STATUS: 'MEDIA_UPDATE_STATUS_REQUEST_STATUS',

    MEDIA_ADD_COMMENT_REQUEST: 'MEDIA_ADD_COMMENT_REQUEST',
    MEDIA_ADD_COMMENT_REQUEST_SUCCESS: 'MEDIA_ADD_COMMENT_REQUEST_SUCCESS',
    MEDIA_ADD_COMMENT_REQUEST_ERROR: 'MEDIA_ADD_COMMENT_REQUEST_ERROR',
    MEDIA_ADD_COMMENT_REQUEST_STATUS: 'MEDIA_ADD_COMMENT_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',
}
