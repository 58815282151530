import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAzD6LBWajT78AjgOVNGrbU_xWM3jc2Ajg",
    authDomain: "buzz-2dc49.firebaseapp.com",
    projectId: "buzz-2dc49",
    storageBucket: "buzz-2dc49.appspot.com",
    messagingSenderId: "365098439504",
    appId: "1:365098439504:web:720a4b20d096e265ae9255",
    measurementId: "G-ZF910Y8ERY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });