import React, { useState, useContext } from 'react'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar'
import './sidebar.styles.scss'
import { Link, Navigate } from 'react-router-dom';
import { ROUTES } from '../../utils/AppConstants'
import {
    FaSignOutAlt, FaUserAlt, FaPercent, FaBook, FaReceipt, FaFileInvoiceDollar, FaUserCircle, FaMusic, FaBullhorn, FaQrcode
} from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { APP_CONSTANTS } from '../../utils/AppConstants'
import { clearLocalStorage, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserProductOwner, isLoggedInUserVendor } from '../../utils/Utils';

const CustomSidebar = props => {
    const [isCollapsed, setCollapsed] = useState(true)
    const { isLogin, user } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    return (
        <ProSidebar
            collapsed={isCollapsed}
        // toggled={false}
        // breakPoint="md"
        >
            <SidebarHeader>
                <div className='headerContainer' >
                    <div className='toggle-container'>
                        <div className='toggle' onClick={() => {
                            setCollapsed(!isCollapsed)
                        }}>
                            {isCollapsed ?
                                (<div>
                                    <img className='img' src={require('../../assets/hamburger_icon.png')} />
                                </div>) :
                                (<div>
                                    <img className='img' src={require('../../assets/hamburger_back.png')} />
                                </div>)}
                        </div>
                    </div>
                    <div className='logo-container'>
                        {isCollapsed === false ? (<div className='img-container'>
                            <img className='img' src={require('../../assets/logo_new.png')} />
                        </div>) : (
                            <div className='img-container-collapse'>
                                <img className='img' src={require('../../assets/logo_new.png')} />
                            </div>
                        )}
                    </div>
                </div>
            </SidebarHeader>
            {isLogin && (<SidebarContent>
                <Menu iconShape='circle'>
                    {isLoggedInUserProductOwner(user) && <MenuItem icon={<FaUserAlt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.OPERATOR_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.operatorList} />
                    </MenuItem>}
                    {isLoggedInUserOperator(user) && <MenuItem icon={<FaUserAlt />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.VENDOR_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.vendorList} />
                    </MenuItem>}
                    {(isLoggedInUserVendor(user) || isLoggedInUserCustomer(user)) && <MenuItem icon={<FaBullhorn />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.PROMOTION_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.promotionList} />
                    </MenuItem>}
                    {(isLoggedInUserCustomer(user) || isLoggedInUserVendor(user)) && <MenuItem icon={<FaMusic />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.MEDIA_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.mediaList} />
                    </MenuItem>}
                    {isLoggedInUserVendor(user) && <MenuItem icon={<FaBook />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.POINTS_REWARD}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.pointsRewardList} />
                    </MenuItem>}
                    {isLoggedInUserVendor(user) && <MenuItem icon={<FaQrcode />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.QR_CODE}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.qrCode} />
                    </MenuItem>}
                    {/* {(isLoggedInUserVendor(user) || isLoggedInUserOperator(user)) && <MenuItem icon={<FaBook />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.INVOICE_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.invoiceList} />
                    </MenuItem>} */}
                    {/* {(isLoggedInUserOperator(user) || isLoggedInUserCustomer(user)) && <MenuItem icon={<FaFileInvoiceDollar />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.CASHBACK_LIST}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.pointsRequestList} />
                    </MenuItem>} */}
                    {isLoggedInUserCustomer(user) && <MenuItem icon={<FaUserCircle />}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.PROFILE}
                        </div>
                        <Link style={{ backgroundColor: 'transparent' }} to={ROUTES.customerProfile} />
                    </MenuItem>}
                    <MenuItem icon={<FaSignOutAlt />} onClick={() => {
                        clearLocalStorage()
                        dispatch({ type: APP_CONSTANTS.LOGOUT })
                    }}>
                        <div style={{ color: '#382f1e', fontSize: 15, fontWeight: '600' }} onClick={() => {
                            clearLocalStorage()
                            dispatch({ type: APP_CONSTANTS.LOGOUT })
                        }}>
                            {APP_CONSTANTS.MENU_ITEM_DISPLAY_NAME.LOGOUT}
                        </div>
                    </MenuItem>
                </Menu>
            </SidebarContent>)}
            <SidebarFooter
                style={{ textAlign: 'center' }}>
                <div
                    className="sidebar-btn-wrapper"
                    style={{
                        padding: '10px 10px',
                    }}
                >
                    <span className='footer-text'>&#169; Copyright {new Date().getFullYear()}</span>
                </div>
            </SidebarFooter>
        </ProSidebar>
    )
}

export default CustomSidebar