import { SchemeActionTypes } from './scheme.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addSchemeSuccess: null,
    addSchemeError: null,
    addSchemeReqStatus: '',
    schemeListSuccess: null,
    schemeListError: null,
    schemeDetailSuccess: null,
    schemeDetailError: null,
    updateStatusSchemeSuccess: null,
    updateStatusSchemeError: null,
    updateStatusSchemeReqStatus: ''
}

const schemeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SchemeActionTypes.ADD_SCHEME_REQUEST_SUCCESS:
            return {
                ...state,
                addSchemeSuccess: action.payload,
                addSchemeError: null,
                addSchemeReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case SchemeActionTypes.ADD_SCHEME_REQUEST_ERROR:
            return {
                ...state,
                addSchemeSuccess: null,
                addSchemeError: action.payload,
                addSchemeReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case SchemeActionTypes.ADD_SCHEME_REQUEST_STATUS:
            return {
                ...state,
                addSchemeReqStatus: ''
            }
        case SchemeActionTypes.SCHEME_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                schemeListSuccess: action.payload,
                schemeListError: null
            }
        case SchemeActionTypes.SCHEME_LIST_REQUEST_ERROR:
            return {
                ...state,
                schemeListSuccess: null,
                schemeListError: action.payload
            }
        case SchemeActionTypes.SCHEME_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                schemeDetailSuccess: action.payload,
                schemeDetailError: null
            }
        case SchemeActionTypes.SCHEME_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                schemeDetailSuccess: null,
                schemeDetailError: action.payload
            }
        case SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateStatusSchemeSuccess: action.payload,
                updateStatusSchemeError: null,
                updateStatusSchemeReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateStatusSchemeSuccess: null,
                updateStatusSchemeError: action.payload,
                updateStatusSchemeReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateStatusSchemeReqStatus: ''
            }
        case SchemeActionTypes.LOGOUT:
            return {
                ...state,
                addSchemeSuccess: null,
                addSchemeError: null,
                addSchemeReqStatus: '',
                schemeDetailSuccess: null,
                schemeDetailError: null,
                updateStatusSchemeSuccess: null,
                updateStatusSchemeError: null,
                updateStatusSchemeReqStatus: ''
            }
        default:
            return state
    }
}

export default schemeReducer