import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addSchemeAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.scheme.addScheme}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function schemeListAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.scheme.schemeList}`,
        params: payload
    })
}

export function schemeDetailAPI(payload) {
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.scheme.schemeDetail}/${payload}`
    })
}

export function schemeUpdateStatusAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.scheme.updateStatus}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}