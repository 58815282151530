import { PointsRewardActionTypes } from './pointsReward.types'

//Points List
export const pointsRewardListRequest = data => ({
    type: PointsRewardActionTypes.POINTS_REWARD_LIST_REQUEST,
    payload: data
})

export const pointsRewardListRequestSuccess = data => ({
    type: PointsRewardActionTypes.POINTS_REWARD_LIST_REQUEST_SUCCESS,
    payload: data
})

export const pointsRewardListRequestError = data => ({
    type: PointsRewardActionTypes.POINTS_REWARD_LIST_REQUEST_ERROR,
    payload: data
})

//Redeem points
export const redeemPointsRequest = data => ({
    type: PointsRewardActionTypes.REDEEM_POINTS_REQUEST,
    payload: data
})

export const redeemPointsRequestSuccess = data => ({
    type: PointsRewardActionTypes.REDEEM_POINTS_REQUEST_SUCCESS,
    payload: data
})

export const redeemPointsRequestError = data => ({
    type: PointsRewardActionTypes.REDEEM_POINTS_REQUEST_ERROR,
    payload: data
})

export const redeemPointsRequestStatus = data => ({
    type: PointsRewardActionTypes.REDEEM_POINTS_REQUEST_STATUS,
    payload: data
})