import { PointsRequestActionTypes } from './pointsRequest.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addPointRequestSuccess: null,
    addPointRequestError: null,
    addPointRequestReqStatus: '',
    pointRequestListSuccess: null,
    pointRequestListError: null,
    pointRequestUpdatePaymentSuccess: null,
    pointRequestUpdatePaymentError: null,
    pointRequestUpdatePaymentReqStatus: ''
}

const pointsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PointsRequestActionTypes.ADD_POINTS_REQUEST_SUCCESS:
            return {
                ...state,
                addPointRequestSuccess: action.payload,
                addPointRequestError: null,
                addPointRequestReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case PointsRequestActionTypes.ADD_POINTS_REQUEST_ERROR:
            return {
                ...state,
                addPointRequestSuccess: null,
                addPointRequestError: action.payload,
                addPointRequestReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case PointsRequestActionTypes.ADD_POINTS_REQUEST_STATUS:
            return {
                ...state,
                addPointRequestReqStatus: ''
            }
        case PointsRequestActionTypes.POINTS_REQUEST_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                pointRequestListSuccess: action.payload,
                pointRequestListError: null
            }
        case PointsRequestActionTypes.POINTS_REQUEST_LIST_REQUEST_ERROR:
            return {
                ...state,
                pointRequestListSuccess: null,
                pointRequestListError: action.payload
            }
        case PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                pointRequestUpdatePaymentSuccess: action.payload,
                pointRequestUpdatePaymentError: null,
                pointRequestUpdatePaymentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST_ERROR:
            return {
                ...state,
                pointRequestUpdatePaymentSuccess: null,
                pointRequestUpdatePaymentError: action.payload,
                pointRequestUpdatePaymentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST_STATUS:
            return {
                ...state,
                pointRequestUpdatePaymentReqStatus: ''
            }
        case PointsRequestActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default pointsReducer