import React from "react";
import TextField from '@mui/material/TextField';
import './styles.css'

const CommonInputFloatHalf = ({ type, classname = "", placeholder, value, onChange, id, isRequired = false, onFocus, isSubtext = false, subText }) => {
    return (
        <div className='common-input-half'>
            <TextField
                className="common-input-full"
                type={type}
                required={isRequired}
                id={id}
                label={placeholder}
                value={value}
                fullWidth
                onChange={onChange}
                onFocus={onFocus ? onFocus : null}
                color='primary'
                size="medium"
                helperText={isSubtext ? subText : ''}
            />
        </div>
    )
}

export default CommonInputFloatHalf