import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addMediaAPI, mediaDetailAPI, mediaListAPI, mediaUpdateStatusAPI,
    mediaAddCommentAPI
} from '../api-requests/customerMedia';
import {
    addMediaRequestError, addMediaRequestSuccess, mediaDetailRequestError,
    mediaDetailRequestSuccess, mediaListRequestError, mediaListRequestSuccess,
    mediaUpdateStatusRequestError, mediaUpdateStatusRequestSuccess,
    mediaAddCommentRequestError, mediaAddCommentRequestSuccess
} from './media.action';
import { MediaActionTypes } from './media.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleMediaAddComment({ payload }) {
    try {
        const response = yield call(mediaAddCommentAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(mediaAddCommentRequestSuccess(decryptedData))
            } else {
                yield put(mediaAddCommentRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: MediaActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(mediaAddCommentRequestError(error.response.data))
        }
    }
}

export function* mediaAddCommentReq() {
    yield takeLatest(MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST, handleMediaAddComment)
}

function* handleMediaUpdateStatus({ payload }) {
    try {
        const response = yield call(mediaUpdateStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(mediaUpdateStatusRequestSuccess(decryptedData))
            } else {
                yield put(mediaUpdateStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: MediaActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(mediaUpdateStatusRequestError(error.response.data))
        }
    }
}

export function* mediaUpdateReq() {
    yield takeLatest(MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST, handleMediaUpdateStatus)
}

function* handleMediaist({ payload }) {
    try {
        const response = yield call(mediaListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(mediaListRequestSuccess(decryptedData))
            } else {
                yield put(mediaListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: MediaActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(mediaListRequestError(error.response.data))
        }
    }
}

export function* mediaListReq() {
    yield takeLatest(MediaActionTypes.MEDIA_LIST_REQUEST, handleMediaist)
}

function* handleMediaDetail({ payload }) {
    try {
        const response = yield call(mediaDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(mediaDetailRequestSuccess(decryptedData))
            } else {
                yield put(mediaDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: MediaActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(mediaDetailRequestError(error.response.data))
        }
    }
}

export function* mediaDetailReq() {
    yield takeLatest(MediaActionTypes.MEDIA_DETAIL_REQUEST, handleMediaDetail)
}

function* handleAddMedia({ payload }) {
    try {
        const response = yield call(addMediaAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addMediaRequestSuccess(decryptedData))
            } else {
                yield put(addMediaRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: MediaActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addMediaRequestError(error.response.data))
        }
    }
}

export function* addMediaReq() {
    yield takeLatest(MediaActionTypes.ADD_MEDIA_REQUEST, handleAddMedia)
}