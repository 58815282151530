import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import {
    customerAddBankDetailRequest, customerAddBankDetailRequestStatus, customerDetailRequest,
    customerUpdateInfoRequest, customerUpdateInfoRequestStatus,
    customerUpdateBankDetailRequest, customerUpdateBankDetailRequestStatus
} from '../../../redux/customer/customer.action'
import { addPointsRequest, addPointsRequestStatus } from '../../../redux/points-request/points.action'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../redux/user/user.action'
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import Select from 'react-select'
import moment from "moment";

const CustomerProfile = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, countryListSuccess, countryListError, stateListSuccess,
        stateListError, cityListSuccess, cityListError } = useSelector(state => state.user)
    const { addPointRequestSuccess, addPointRequestError, addPointRequestReqStatus } = useSelector(state => state.points)
    const { customerDetailSuccess, customerDetailError,
        customerUpdateInfoSuccess, customerUpdateInfoError, customerUpdateInfoReqStatus,
        customerAddBankDetailsSuccess, customerAddBankDetailsError, customerAddBankDetailsReqStatus,
        customerUpdateBankDetailsSuccess, customerUpdateBankDetailsError, customerUpdateBankDetailsReqStatus } = useSelector(state => state.customer)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [accountHolderName, setAccountHolderName] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('')
    const [ifscCode, setIfscCode] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [updateAccountHolderName, setUpdateAccountHolderName] = useState('')
    const [updateBankName, setUpdateBankName] = useState('')
    const [updateAccountNumber, setUpdateAccountNumber] = useState('')
    const [updateConfirmAccountNumber, setUpdateConfirmAccountNumber] = useState('')
    const [updateIfscCode, setUpdateIfscCode] = useState('')
    const [updateCountryDropdown, setUpdateCountryDropdown] = useState();
    const [updateStateDropdown, setUpdateStateDropdown] = useState();
    const [updateCityDropdown, setUpdateCityDropdown] = useState();
    const [updateCountryCode, setUpdateCountryCode] = useState('')
    const [selectedBank, setSelectedBank] = useState('')
    const [isEditBankClick, setIsEditBankClick] = useState(false)

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        fetchCustomerDetails()
        dispatch(countryListRequest())
    }, [])

    const fetchCustomerDetails = () => {
        setLoading(true)
        dispatch(customerDetailRequest(user.data.userDetails.customerId))
    }

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
            if (isEditBankClick === true && !isNull(selectedBank)) {
                let stateObj = stateListSuccess.find(e => e.name === selectedBank.state)
                if (!isNull(stateObj)) {
                    setUpdateStateDropdown(stateObj)
                    setUpdateCityDropdown(null)
                    fetchCityList(updateCountryCode, stateObj.iso2)
                }
            }
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
            if (isEditBankClick === true && !isNull(selectedBank)) {
                let cityObj = cityListSuccess.find(e => e.name === selectedBank.city)
                if (!isNull(cityObj)) {
                    setUpdateCityDropdown(cityObj)
                }
            }
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setUpdateCountryDropdown(e)
        setCountryCode(e.iso2)
        setUpdateCountryCode(e.iso2)
        setUpdateStateDropdown(null)
        setUpdateCityDropdown(null)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    useEffect(() => {
        if (!isNull(customerDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(customerDetailError)) {
            setLoading(false)
            displayErrorToast(customerDetailError.message)
        }
    }, [customerDetailSuccess, customerDetailError])

    useEffect(() => {
        if (customerAddBankDetailsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            fetchCustomerDetails()
            displaySuccessToast(customerAddBankDetailsSuccess.message)
        } else if (customerAddBankDetailsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(customerAddBankDetailsError.message)
        }
        if (customerAddBankDetailsReqStatus.length) {
            dispatch(customerAddBankDetailRequestStatus(''))
        }
    }, [customerAddBankDetailsSuccess, customerAddBankDetailsError, customerAddBankDetailsReqStatus])

    useEffect(() => {
        if (customerUpdateBankDetailsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            fetchCustomerDetails()
            displaySuccessToast(customerUpdateBankDetailsSuccess.message)
        } else if (customerUpdateBankDetailsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(customerUpdateBankDetailsError.message)
        }
        if (customerUpdateBankDetailsReqStatus.length) {
            dispatch(customerUpdateBankDetailRequestStatus(''))
        }
    }, [customerUpdateBankDetailsSuccess, customerUpdateBankDetailsError, customerUpdateBankDetailsReqStatus])

    useEffect(() => {
        if (addPointRequestReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            fetchCustomerDetails()
            displaySuccessToast(addPointRequestSuccess.message)
        } else if (addPointRequestReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addPointRequestError.message)
        }
        if (addPointRequestReqStatus.length) {
            dispatch(addPointsRequestStatus(''))
        }
    }, [addPointRequestSuccess, addPointRequestError, addPointRequestReqStatus])

    useEffect(() => {
        if (customerUpdateInfoReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            fetchCustomerDetails()
            displaySuccessToast(customerUpdateInfoSuccess.message)
        } else if (customerUpdateInfoReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(customerUpdateInfoError.message)
        }
        if (customerUpdateInfoReqStatus.length) {
            dispatch(customerUpdateInfoRequestStatus(''))
        }
    }, [customerUpdateInfoSuccess, customerUpdateInfoError, customerUpdateInfoReqStatus])

    const handleUpdateProfileClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast('First name cannot be blank')
        } else if (isEmpty(lastName)) {
            displayErrorToast('Last name cannot be blank')
        } else if (isEmpty(email)) {
            displayErrorToast('Email cannot be blank')
        } else {
            setLoading(true)
            const data = {
                customerId: user.data.userDetails.customerId,
                firstName: firstName,
                lastName: lastName,
                email: email
            }
            dispatch(customerUpdateInfoRequest(data))
        }
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleRequestCashbackClick = (e) => {
        if (isNull(customerDetailSuccess)) {
            displayErrorToast('User details not found')
        } else if (isNull(customerDetailSuccess.data)) {
            displayErrorToast('User details not found')
        } else if (isEmpty(customerDetailSuccess.data.firstName)) {
            displayErrorToast('Customer name not available. Kindly update name and then try again')
        } else if (isEmpty(customerDetailSuccess.data.email)) {
            displayErrorToast('Customer email not available. Kindly update name and then try again')
        } else if (isEmpty(customerDetailSuccess.data.bankDetails)) {
            displayErrorToast('Customer bank details not found. Kindly add bank details before requesting cashback')
        } else {
            setLoading(true)
            const data = {
                customerId: user.data.userDetails.customerId,
                points: e.points,
                amount: e.amount,
                currencyLabel: e.currencyLabel,
                currencySymbol: e.currencySymbol,
                currencyRegion: e.currencyRegion,
                country: e.country,
                state: e.state,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(addPointsRequest(data))
        }
    }

    const handleAddBankDetailsClick = () => {
        if (isEmpty(accountHolderName)) {
            displayErrorToast('Account holder name cannot be blank')
        } else if (isEmpty(bankName)) {
            displayErrorToast('Bank name cannot be blank')
        } else if (isEmpty(accountNumber)) {
            displayErrorToast('Account number cannot be blank')
        } else if (isEmpty(confirmAccountNumber)) {
            displayErrorToast('Confirm Account number cannot be blank')
        } else if (accountNumber !== confirmAccountNumber) {
            displayErrorToast('Account number does not match entered confirm account number')
        } else if (isEmpty(ifscCode)) {
            displayErrorToast('Bank code cannot be blank')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Bank country not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Bank country state not selected')
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Bank city not selected')
        } else {
            setLoading(true)
            const data = {
                bankName: bankName,
                accountHolderName: accountHolderName,
                accountNumber: accountNumber,
                country: countryDropdown.name,
                state: stateDropdown.name,
                city: cityDropdown.name,
                userType: APP_CONSTANTS.USER_ROLE_TYPE.CUSTOMER,
                customerId: user.data.userDetails.customerId,
                ifscCode: ifscCode,
                productCode: APP_CONSTANTS.PRODUCT_NAME
            }
            dispatch(customerAddBankDetailRequest(data))
        }
    }

    const handleEditBankDetailsSubmit = () => {
        if (isNull(selectedBank)) {
            displayErrorToast('Bank information not selected')
        } else if (isEmpty(updateAccountHolderName)) {
            displayErrorToast('Account holder name cannot be blank')
        } else if (isEmpty(updateBankName)) {
            displayErrorToast('Bank name cannot be blank')
        } else if (isEmpty(updateAccountNumber)) {
            displayErrorToast('Account number cannot be blank')
        } else if (isEmpty(updateConfirmAccountNumber)) {
            displayErrorToast('Confirm Account number cannot be blank')
        } else if (updateAccountNumber !== updateConfirmAccountNumber) {
            displayErrorToast('Account number does not match entered confirm account number')
        } else if (isEmpty(updateIfscCode)) {
            displayErrorToast('Bank code cannot be blank')
        } else if (isNull(updateCountryDropdown)) {
            displayErrorToast('Bank country not selected')
        } else if (isNull(updateStateDropdown)) {
            displayErrorToast('Bank country state not selected')
        } else if (isNull(updateCityDropdown)) {
            displayErrorToast('Bank city not selected')
        } else {
            setLoading(true)
            const data = {
                bankDetailId: selectedBank.bankDetailId,
                bankName: updateBankName,
                accountHolderName: updateAccountHolderName,
                accountNumber: updateAccountNumber,
                country: updateCountryDropdown.name,
                state: updateStateDropdown.name,
                city: updateCityDropdown.name,
                ifscCode: updateIfscCode,
            }

            dispatch(customerUpdateBankDetailRequest(data))
            setIsEditBankClick(false)
        }
    }

    const handleEditBankDetailsClick = e => {
        setSelectedBank(e)
        setUpdateBankName(e.bankName)
        setUpdateAccountHolderName(e.accountHolderName)
        setUpdateAccountNumber(e.accountNumber)
        setUpdateConfirmAccountNumber(e.accountNumber)
        setUpdateIfscCode(e.ifscCode)
        setIsEditBankClick(!isEditBankClick)
        if (!isEmpty(countryListSuccess)) {
            console.log('e', e)
            let countryObj = countryListSuccess.find(e1 => e1.name === e.country)
            console.log('countryObj', countryObj)
            if (!isNull(countryObj)) {
                handleCountrySelectChange(countryObj)
            }
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Profile' />
                {!isNull(customerDetailSuccess) && !isNull(customerDetailSuccess.data) && < CommonFormBorder >
                    <div className="cp-name-container">{!isEmpty(customerDetailSuccess.data.firstName) ? customerDetailSuccess.data.firstName : '-'} {!isEmpty(customerDetailSuccess.data.lastName) ? customerDetailSuccess.data.lastName : '-'}</div>
                    <FormFieldLabel className='up-bold-label' label='Profile' />
                    {isEmpty(customerDetailSuccess.data.firstName) && isEmpty(customerDetailSuccess.data.lastName) &&
                        <CommonInputRow>
                            <CommonInputFloatHalf
                                type='text'
                                id='firstName'
                                onChange={e => setFirstName(e.target.value)}
                                placeholder='First Name'
                                value={firstName} />
                            <CommonInputFloatHalf
                                type='text'
                                id='lastName'
                                onChange={e => setLastName(e.target.value)}
                                placeholder='Last Name'
                                value={lastName} />
                        </CommonInputRow>}
                    {isEmpty(customerDetailSuccess.data.email) &&
                        <CommonInputFloatFull
                            type='text'
                            id='email'
                            onChange={e => setEmail(e.target.value)}
                            placeholder='Email'
                            value={email}
                        />
                    }
                    {!isEmpty(customerDetailSuccess.data.email) &&
                        <div>
                            <div className='form-field-label-grey'>Email</div>
                            <div className="form-field-text-label">{customerDetailSuccess.data.email}</div>
                        </div>}
                    <div className='form-field-label-grey'>Mobile Number</div>
                    <div className="form-field-text-label">{customerDetailSuccess.data.mobile}</div>
                    <FormFieldLabel className='up-bold-label' label='Points Earned' />
                    {isEmpty(customerDetailSuccess.data.pointList) && <div className="content-error">No Points Earned</div>}
                    {!isEmpty(customerDetailSuccess.data.pointList) && customerDetailSuccess.data.pointList.map(e => (
                        <div className="cp-cashback-row-container">
                            <div className="cp-name-label">{e.businessName}</div>
                            <div className="cp-country-label">{e.city}, {e.state}, {e.country}</div>
                            <div className="cp-country-label">{e.points} (Points)</div>
                            {/* <div className="cp-amount-container">
                                <div className="cp-currency-label">{getAmountWithCurrency(e, e.amount)}</div>
                                <div className="cp-request-cashback-btn" onClick={() => { handleRequestCashbackClick(e) }}>Request Cashback</div>
                            </div> */}

                        </div>
                    ))}
                    {/* <FormFieldLabel className='up-bold-label' label='Bank Details' />
                    {isEmpty(customerDetailSuccess.data.bankDetails) && <div div className="content-error">Add new bank details</div>}
                    {!isEmpty(customerDetailSuccess.data.bankDetails) && customerDetailSuccess.data.bankDetails.map(e => (
                        <div className="cp-bank-row-parent-container">
                            <div className="cp-bank-row-container">
                                <div className="cp-country-label">Bank: {e.bankName}</div>
                                <div className="cp-name-label">A/c Holder: {e.accountHolderName}</div>
                                <div className="cp-currency-label">{e.accountNumber}</div>
                                <div className="cp-name-label">Bank Code (IFSC/Routing/Swift): {e.ifscCode}</div>
                                <div className="cp-country-label">Location: {e.city}, {e.state}, {e.country}</div>
                                <div className="cp-country-label">Last updated: {moment(e.updatedOn).utc().format('DD MMM YYYY hh:mm a')}</div>
                            </div>
                            <div className="cp-edit-bank-icon-container" onClick={() => { handleEditBankDetailsClick(e) }}>
                                <img src={require('../../../assets/ic_edit_icon.png')} className="img" />
                            </div>
                        </div>
                    ))}
                    {isEditBankClick === true && <div className="mt-2">
                        <CommonInputRow>
                            <CommonInputFloatHalf
                                type='text'
                                id='accountHolderName'
                                onChange={e => setUpdateAccountHolderName(e.target.value)}
                                placeholder='A/c Holder Name'
                                value={updateAccountHolderName}
                            />
                            <CommonInputFloatHalf
                                type='text'
                                id='bankName'
                                onChange={e => setUpdateBankName(e.target.value)}
                                placeholder='Bank Name'
                                value={updateBankName}
                            />
                        </CommonInputRow>

                        <CommonInputRow>
                            <CommonInputFloatHalf
                                type='password'
                                id='accountNumber'
                                onChange={e => setUpdateAccountNumber(e.target.value)}
                                placeholder='A/c Number'
                                value={updateAccountNumber}
                            />

                            <CommonInputFloatHalf
                                type='text'
                                id='confirmAccountNumber'
                                onChange={e => setUpdateConfirmAccountNumber(e.target.value)}
                                placeholder='Confirm A/c Number'
                                value={updateConfirmAccountNumber}
                            />
                        </CommonInputRow>

                        <CommonInputFloatFull
                            type='text'
                            id='ifscCode'
                            onChange={e => setUpdateIfscCode(e.target.value)}
                            placeholder='Bank Code (IFSC/Routing/Swift Code)'
                            value={updateIfscCode}
                        />
                        <div className='form-field-label-grey'>Bank Location</div>
                        {countryListSuccess && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={updateCountryDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={countryListSuccess}
                            placeholder='Select Country'
                            onChange={e => {
                                handleCountrySelectChange(e)
                            }} />}
                        <CommonInputRow>
                            {stateListSuccess && <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={updateStateDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={stateListSuccess}
                                placeholder='Select State'
                                onChange={e => {
                                    setUpdateStateDropdown(e);
                                    setUpdateCityDropdown(null)
                                    fetchCityList(countryCode, e.iso2)
                                }} />}
                            {cityListSuccess && <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={updateCityDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={cityListSuccess}
                                placeholder='Select City'
                                onChange={e => {
                                    setUpdateCityDropdown(e);
                                }} />}
                        </CommonInputRow>
                    </div>}
                    {isEmpty(customerDetailSuccess.data.bankDetails) && <div>
                        <CommonInputRow>
                            <CommonInputFloatHalf
                                type='text'
                                id='accountHolderName'
                                onChange={e => setAccountHolderName(e.target.value)}
                                placeholder='A/c Holder Name'
                                value={accountHolderName}
                            />
                            <CommonInputFloatHalf
                                type='text'
                                id='bankName'
                                onChange={e => setBankName(e.target.value)}
                                placeholder='Bank Name'
                                value={bankName}
                            />
                        </CommonInputRow>

                        <CommonInputRow>
                            <CommonInputFloatHalf
                                type='password'
                                id='accountNumber'
                                onChange={e => setAccountNumber(e.target.value)}
                                placeholder='A/c Number'
                                value={accountNumber}
                            />

                            <CommonInputFloatHalf
                                type='text'
                                id='confirmAccountNumber'
                                onChange={e => setConfirmAccountNumber(e.target.value)}
                                placeholder='Confirm A/c Number'
                                value={confirmAccountNumber}
                            />
                        </CommonInputRow>

                        <CommonInputFloatFull
                            type='text'
                            id='ifscCode'
                            onChange={e => setIfscCode(e.target.value)}
                            placeholder='Bank Code (IFSC/Routing/Swift Code)'
                            value={ifscCode}
                        />
                        <div className='form-field-label-grey'>Bank Location</div>
                        {countryListSuccess && <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={countryDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={countryListSuccess}
                            placeholder='Select Country'
                            onChange={e => {
                                handleCountrySelectChange(e)
                            }} />}
                        <CommonInputRow>
                            {stateListSuccess && <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={stateDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={stateListSuccess}
                                placeholder='Select State'
                                onChange={e => {
                                    setStateDropdown(e);
                                    setCityDropdown(null)
                                    fetchCityList(countryCode, e.iso2)
                                }} />}
                            {cityListSuccess && <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={cityDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={cityListSuccess}
                                placeholder='Select City'
                                onChange={e => {
                                    setCityDropdown(e);
                                }} />}
                        </CommonInputRow>
                    </div>} */}
                    <div className="cp-button-container">
                        {(isEmpty(customerDetailSuccess.data.firstName) || isEmpty(customerDetailSuccess.data.lastName) || isEmpty(customerDetailSuccess.data.email)) &&
                            <CommonButton label='Update Profile' onClick={() => handleUpdateProfileClick()} />}

                        {/* {isEmpty(customerDetailSuccess.data.bankDetails) &&
                            <CommonButton label='Add Bank Details' onClick={() => handleAddBankDetailsClick()} />} */}

                        {/* {!isEmpty(customerDetailSuccess.data.bankDetails) && isEditBankClick === true &&
                            <CommonButton label='Update Bank Details' onClick={() => handleEditBankDetailsSubmit()} />} */}

                    </div>
                </CommonFormBorder>}
            </CommonScreenContent >
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerProfile