const API_ENDPOINTS = {
    account: {
        login: 'user/login',
        userRegistration: 'user/registration',
        forgotPassword: 'user/forgotPassword',
        changePassword: 'user/changePassword',
        subscriptionList: 'subscription/list',
        operatorRegistration: 'user/operatorRegistration',
        operatorList: 'user/operatorList',
        vendorList: 'user/vendorList',
        operatorDetail: 'user/operatorDetail',
        updateOperatorLocation: 'user/updateOperatorLocation',
        vendorListByLocation: 'user/vendorListByLocation'
    },
    customer: {
        customerLogin: 'customer/login',
        customerList: 'customer/list',
        customerDetail: 'customer/detail',
        customerUpdateInfo: 'customer/updateInfo',
        addBankDetails: 'customer/bank/addDetails',
        updateBankDetails: 'customer/bank/updateDetails'
    },
    location: {
        countryList: 'https://api.countrystatecity.in/v1/countries',
        stateList: 'states',
        cityList: 'cities'
    },
    scheme: {
        addScheme: 'scheme/add',
        schemeDetail: 'scheme/detail',
        schemeList: 'scheme/list',
        updateStatus: 'scheme/updateStatus'
    },
    media: {
        addMedia: 'customerMedia/add',
        detail: 'customerMedia/detail',
        list: 'customerMedia/list',
        updateStatus: 'customerMedia/updateStatus',
        addComment: 'customerMedia/addComment'
    },
    pointsRequest: {
        createRequest: 'pointRequest/submitRequest',
        requestList: 'pointRequest/list',
        updateStatus: 'pointRequest/updateStatus'
    },
    invoice: {
        createInvoice: 'invoice/createInvoice',
        invoiceList: 'invoice/invoiceList',
        invoiceDetail: 'invoice/invoiceDetail',
        updatePayment: 'invoice/updatePayment'
    },
    pointsReward: {
        rewardList: 'pointsReward/list',
        redeemPoints: 'pointsReward/redeemPoints'
    }
}

export default API_ENDPOINTS