import { SchemeActionTypes } from './scheme.types'

//Add Offer
export const addSchemeRequest = data => ({
    type: SchemeActionTypes.ADD_SCHEME_REQUEST,
    payload: data
})

export const addSchemeRequestSuccess = data => ({
    type: SchemeActionTypes.ADD_SCHEME_REQUEST_SUCCESS,
    payload: data
})

export const addSchemeRequestError = data => ({
    type: SchemeActionTypes.ADD_SCHEME_REQUEST_ERROR,
    payload: data
})

export const addSchemeRequestStatus = data => ({
    type: SchemeActionTypes.ADD_SCHEME_REQUEST_STATUS,
    payload: data
})

//Offer List
export const schemeListRequest = data => ({
    type: SchemeActionTypes.SCHEME_LIST_REQUEST,
    payload: data
})

export const schemeListRequestSuccess = data => ({
    type: SchemeActionTypes.SCHEME_LIST_REQUEST_SUCCESS,
    payload: data
})

export const schemeListRequestError = data => ({
    type: SchemeActionTypes.SCHEME_LIST_REQUEST_ERROR,
    payload: data
})

//Offer Detail
export const schemeDetailRequest = data => ({
    type: SchemeActionTypes.SCHEME_DETAIL_REQUEST,
    payload: data
})

export const schemeDetailRequestSuccess = data => ({
    type: SchemeActionTypes.SCHEME_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const schemeDetailRequestError = data => ({
    type: SchemeActionTypes.SCHEME_DETAIL_REQUEST_ERROR,
    payload: data
})

//Offer Update Status
export const schemeUpdateStatusRequest = data => ({
    type: SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST,
    payload: data
})

export const schemeUpdateStatusRequestSuccess = data => ({
    type: SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const schemeUpdateStatusRequestError = data => ({
    type: SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST_ERROR,
    payload: data
})

export const schemeUpdateStatusRequestStatus = data => ({
    type: SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST_STATUS,
    payload: data
})