import { combineReducers } from 'redux'
import userReducer from './user/user.reducer'
import mediaReducer from './media/media.reducer'
import schemeReducer from './scheme/scheme.reducer'
import invoiceReducer from './invoice/invoice.reducer'
import pointsReducer from './points-request/points.reducer'
import customerReducer from './customer/customer.reducer'
import pointsRewardReducer from './points-reward/pointsReward.reducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: userReducer,
    media: mediaReducer,
    scheme: schemeReducer,
    invoice: invoiceReducer,
    points: pointsReducer,
    customer: customerReducer,
    pointsReward: pointsRewardReducer
})

// export default rootReducer
export default persistReducer(persistConfig, rootReducer)