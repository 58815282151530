import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addPointsRequestAPI, pointsRequestListAPI, pointsUpdateStatusAPI
} from '../api-requests/pointsRequest';
import {
    addPointsRequestError, addPointsRequestSuccess, pointsListRequestError,
    pointsListRequestSuccess, pointsUpdateStatusRequestError, pointsUpdateStatusRequestSuccess
} from './points.action';
import { PointsRequestActionTypes } from './pointsRequest.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handlePointsRequestUpdatePayment({ payload }) {
    try {
        const response = yield call(pointsUpdateStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(pointsUpdateStatusRequestSuccess(decryptedData))
            } else {
                yield put(pointsUpdateStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: PointsRequestActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(pointsUpdateStatusRequestError(error.response.data))
        }
    }
}

export function* pointsRequestUpdateStatusReq() {
    yield takeLatest(PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST, handlePointsRequestUpdatePayment)
}

function* handlePointsRequestList({ payload }) {
    try {
        const response = yield call(pointsRequestListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(pointsListRequestSuccess(decryptedData))
            } else {
                yield put(pointsListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: PointsRequestActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(pointsListRequestError(error.response.data))
        }
    }
}

export function* pointsRequestListReq() {
    yield takeLatest(PointsRequestActionTypes.POINTS_REQUEST_LIST_REQUEST, handlePointsRequestList)
}

function* handleAddPointsRequest({ payload }) {
    try {
        const response = yield call(addPointsRequestAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addPointsRequestSuccess(decryptedData))
            } else {
                yield put(addPointsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: PointsRequestActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addPointsRequestError(error.response.data))
        }
    }
}

export function* addPointsRequestReq() {
    yield takeLatest(PointsRequestActionTypes.ADD_POINTS_REQUEST, handleAddPointsRequest)
}