import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css'

const ImageSlider = ({ images, closeClickHandler }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const settings = {
        centerPadding: '0px',
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => setCurrentIndex(index),
        appendDots: dots => (
            <div
                style={{
                    backgroundColor: "#ddd",
                    borderRadius: "10px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        )
    };

    const handleNext = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    return (
        <div className="image-slider-overlay">
            <div className="image-slider-content">
                {currentIndex < images.length - 1 && <button className="btn image-slider-skip-btn" onClick={closeClickHandler}>
                    SKIP
                </button>}
                {currentIndex === images.length - 1 && (
                    <button className="image-slider-finish-btn" onClick={closeClickHandler}>
                        FINISH
                    </button>
                )}
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="image-slider-slide">
                            <img src={image} alt={`Slide ${index + 1}`} className='img' />
                        </div>
                    ))}
                </Slider>
                {/* <div className="image-slider-indicator">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`indicator-dot ${index === currentIndex ? 'active' : ''}`}
                        />
                    ))}
                </div> */}
                {/* <button className="image-slider-button" onClick={handleNext}>
                    Next
                </button> */}
                {/* {currentIndex === images.length - 1 && (
                    <button className="image-slider-finish-btn" onClick={closeClickHandler}>
                        Finish
                    </button>
                )} */}
            </div>
        </div>
    )
}

export default ImageSlider