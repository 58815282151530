import { takeLatest, call, put } from 'redux-saga/effects'
import {
    changePasswordAPI, cityListApi, countryListApi, forgotPasswordAPI, operatorList,
    operatorRegistrationAPI, stateListApi, subscriptionList, userLoginAPI, userRegistrationAPI,
    vendorList, customerLoginAPI, operatorDetail, updateOperatorLocations,
    vendorListByLocationAPI
} from '../api-requests/user';
import {
    changePasswordRequestError, changePasswordRequestSuccess, cityListRequestError,
    cityListRequestSuccess, countryListRequestError, countryListRequestSuccess, forgotPasswordRequestError,
    forgotPasswordRequestSuccess, loginError, loginSuccess, operatorListRequestError,
    operatorListRequestSuccess, operatorRegistrationRequestError, operatorRegistrationRequestSuccess,
    registerRequestError, registerRequestSuccess, stateListRequestError, stateListRequestSuccess,
    subscriptionListRequestError, subscriptionListRequestSuccess, vendorListRequestError,
    vendorListRequestSuccess, customerLoginRequestError, customerLoginRequestSuccess,
    operatorDetailRequestError, operatorDetailRequestSuccess, updateOperatorLocationRequestError,
    updateOperatorLocationRequestSuccess, vendorListByLocationRequestError, vendorListByLocationRequestSuccess
} from './user.action';
import { UserActionTypes } from './user.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleVendorListByLoctionRequest({ payload }) {
    try {
        const response = yield call(vendorListByLocationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(vendorListByLocationRequestSuccess(decryptedData))
            } else {
                yield put(vendorListByLocationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(vendorListByLocationRequestError(error.response.data))
        }
    }
}

export function* vendorListByLocationReq() {
    yield takeLatest(UserActionTypes.VENDOR_LIST_BY_LOCATION_REQUEST, handleVendorListByLoctionRequest)
}

function* handleUpdateOperatorLocationRequest({ payload }) {
    try {
        const response = yield call(updateOperatorLocations, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateOperatorLocationRequestSuccess(decryptedData))
            } else {
                yield put(updateOperatorLocationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateOperatorLocationRequestError(error.response.data))
        }
    }
}

export function* updateOperatorLocationReq() {
    yield takeLatest(UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST, handleUpdateOperatorLocationRequest)
}

function* handleOperatorDetailRequest({ payload }) {
    try {
        const response = yield call(operatorDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorDetailRequestSuccess(decryptedData))
            } else {
                yield put(operatorDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorDetailRequestError(error.response.data))
        }
    }
}

export function* operatorDetailReq() {
    yield takeLatest(UserActionTypes.OPERATOR_DETAIL_REQUEST, handleOperatorDetailRequest)
}

function* handleCustomerLoginRequest({ payload }) {
    try {
        const response = yield call(customerLoginAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerLoginRequestSuccess(decryptedData))
            } else {
                yield put(customerLoginRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(customerLoginRequestError(error.response.data))
        }
    }
}

export function* customerLoginReq() {
    yield takeLatest(UserActionTypes.CUSTOMER_LOGIN_REQUEST, handleCustomerLoginRequest)
}

function* handleVendorListRequest() {
    try {
        const response = yield call(vendorList);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(vendorListRequestSuccess(decryptedData))
            } else {
                yield put(vendorListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(vendorListRequestError(error.response.data))
        }
    }
}

export function* vendorListReq() {
    yield takeLatest(UserActionTypes.VENDOR_LIST_REQUEST, handleVendorListRequest)
}

function* handleOperatorListRequest() {
    try {
        const response = yield call(operatorList);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorListRequestSuccess(decryptedData))
            } else {
                yield put(operatorListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorListRequestError(error.response.data))
        }
    }
}

export function* operatorListReq() {
    yield takeLatest(UserActionTypes.OPERATOR_LIST_REQUEST, handleOperatorListRequest)
}

function* handleOperatorRegisterRequest({ payload }) {
    try {
        const response = yield call(operatorRegistrationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorRegistrationRequestSuccess(decryptedData))
            } else {
                yield put(operatorRegistrationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorRegistrationRequestError(error.response.data))
        }
    }
}

export function* operatorRegisterReq() {
    yield takeLatest(UserActionTypes.OPERATOR_REGISTRATION_REQUEST, handleOperatorRegisterRequest)
}

function* handleChangePassword({ payload }) {
    try {
        const response = yield call(changePasswordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(changePasswordRequestSuccess(decryptedData))
            } else {
                yield put(changePasswordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(changePasswordRequestError(error.response.data))
    }
}

export function* changePasswordReq() {
    yield takeLatest(UserActionTypes.CHANGE_PASSWORD_REQUEST, handleChangePassword)
}

function* handleForgotPassword({ payload }) {
    try {
        const response = yield call(forgotPasswordAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(forgotPasswordRequestSuccess(decryptedData))
            } else {
                yield put(forgotPasswordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(forgotPasswordRequestError(error.response.data))
    }
}

export function* forgotPasswordReq() {
    yield takeLatest(UserActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword)
}

function* handleSubscriptionList() {
    try {
        const response = yield call(subscriptionList);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(subscriptionListRequestSuccess(decryptedData))
            } else {
                yield put(subscriptionListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response.data)
        yield put(subscriptionListRequestError(error.response.data))
    }
}

export function* subscriptionListReq() {
    yield takeLatest(UserActionTypes.SUBSCRIPTION_LIST_REQUEST, handleSubscriptionList)
}

function* handleRegisterUser({ payload }) {
    try {
        const response = yield call(userRegistrationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(registerRequestSuccess(decryptedData))
            } else {
                yield put(registerRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(registerRequestError(error.response.data))
    }
}

export function* registerUserReq() {
    yield takeLatest(UserActionTypes.REGISTER_REQUEST, handleRegisterUser)
}

function* handleLoginUser({ payload }) {
    try {
        const response = yield call(userLoginAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(loginSuccess(decryptedData))
            } else {
                yield put(loginError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response)
        yield put(loginError(error.response.data))
    }
}

export function* loginUserReq() {
    yield takeLatest(UserActionTypes.LOGIN_USER_REQUEST, handleLoginUser)
}

function* handleCityList({ payload }) {
    try {
        const response = yield call(cityListApi, payload)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(cityListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error.response)
        yield put(cityListRequestError(error.response))
    }
}

export function* cityListReq() {
    yield takeLatest(UserActionTypes.CITY_LIST_REQUEST, handleCityList)
}

function* handleStateList({ payload }) {
    try {
        const response = yield call(stateListApi, payload)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(stateListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error.response)
        yield put(stateListRequestError(error.response))
    }
}

export function* stateListReq() {
    yield takeLatest(UserActionTypes.STATE_LIST_REQUEST, handleStateList)
}

function* handleCountryList() {
    try {
        const response = yield call(countryListApi)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(countryListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog('error', error.response)
        yield put(countryListRequestError(error.response))
    }
}

export function* countryListReq() {
    yield takeLatest(UserActionTypes.COUNTRY_LIST_REQUEST, handleCountryList)
}