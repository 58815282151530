import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import RouteGuard from './components/route-guard/route-guard.component'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from './utils/AppConstants'
import SchemeList from './pages/scheme/scheme-list/schemeList'
import CustomerLogin from './pages/account/customer-login/customerLogin'
import VendorCreateAccount from './pages/account/vendor-create-account/vendorCreateAccount'
import VendorLogin from './pages/account/vendor-login/vendorLogin'
import OperatorList from './pages/operator/operator-list/operatorList'
import OperatorDetail from './pages/operator/operator-detail/operatorDetail'
import AddOperator from './pages/operator/add-operator/addOperator'
import AddScheme from './pages/scheme/add-scheme/addScheme'
import MediaList from './pages/media/media-list/mediaList'
import InvoiceList from './pages/invoice/invoice-list/invoiceList'
import CreateInvoice from './pages/invoice/create-invoice/createInvoice'
import InvoiceDetail from './pages/invoice/invoice-detail/invoiceDetail'
import UploadMedia from './pages/media/upload-media/uploadMedia'
import MediaDetail from './pages/media/media-detail/mediaDetail'
import { useSelector, useDispatch } from 'react-redux';
import CustomSidebar from './components/sidebar/sidebar.component';
import PointsRequestList from './pages/cashback-request/points-request-list/pointsRequestList'
import CustomerProfile from './pages/account/customer-profile/customerProfile'
import PrivacyPolicyPage from './pages/privacy-policy/privacyPolicyScreen'
import CookiePolicyScreen from './pages/cookie-policy/cookiePolicyScreen'
import VendorTutorial from './pages/tutorials/vendor/vendor'
import OperatorTutorial from './pages/tutorials/operator/operator'
import CustomerTutorial from './pages/tutorials/customer/customer'
import PointsRewardList from './pages/points-reward/points-reward-list/pointsRewardList'
import VendorList from './pages/vendor/vendor-list/vendorList'
import QrCode from './pages/qr-code/qrCode'
import { onMessageListener, messaging } from './fcmAnalytics'
import { getToken } from "firebase/messaging";
import { displayConsoleLog, isEmpty, isLoggedInUserCustomer, isNull } from './utils/Utils';
import { customerUpdateInfoRequest, customerUpdateInfoRequestStatus } from './redux/customer/customer.action'
import ImageSlider from './components/image-slider/imageSlider'
import HomePage from './pages/home-page/homePage.js';

function App() {
  const { isLogin, displaySideBar, user } = useSelector((state) => state.user)
  const { customerUpdateInfoSuccess, customerUpdateInfoError, customerUpdateInfoReqStatus } = useSelector((state) => state.customer)
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [fcmToken, setFcmToken] = useState('')
  const CUSTOMER_TUTORIAL_IMAGES = [
    require("./assets/tutorials/customer/customer_tutorial_1.png"),
    require("./assets/tutorials/customer/customer_tutorial_2.png"),
    require("./assets/tutorials/customer/customer_tutorial_3.png"),
    require("./assets/tutorials/customer/customer_tutorial_4.png"),
  ]
  const [showCustomerTutorial, setShowCustomerTutorial] = useState(false)

  useEffect(() => {
    if (!isNull(location)) {
      if (location.pathname === ROUTES.promotionList) {
        if (isLogin === true && !isEmpty(user)) {
          setShowCustomerTutorial(false)
        } else {
          setShowCustomerTutorial(true)
        }
      } else {
        setShowCustomerTutorial(false)
      }
    }
  }, [location])

  useEffect(() => {
    getToken(messaging, { vapidKey: 'BK1Tw73Scwn_OSHdO36p5l_Fx13XXEjZngAUc2WbmYG1uqtMxW1JoM0ykdDw7oKFm5Z_k-GCxeQoF51_4MkU5lw' })
      .then((currentToken) => {
        if (currentToken) {
          setFcmToken(currentToken)
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          displayConsoleLog('No registration token available. Request permission to generate one.');
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        displayConsoleLog('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      })

  }, [])

  useEffect(() => {
    if (!isEmpty(fcmToken)) {
      localStorage.setItem(APP_CONSTANTS.KEY_FCM_TOKEN, fcmToken)
      displayConsoleLog('token', fcmToken)
      if (isLogin === true && isLoggedInUserCustomer(user)) {
        sendFcmTokenToServer()
      }

      // if (!isNull(customerLoginSuccess)) {
      //   sendFcmTokenToServer()
      // }

    }
  }, [fcmToken])

  const sendFcmTokenToServer = () => {
    if (!isNull(localStorage) && !isEmpty(localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN))) {
      const data = {
        customerId: user.data.userDetails.customerId,
        notificationToken: localStorage.getItem(APP_CONSTANTS.KEY_FCM_TOKEN)
      }
      dispatch(customerUpdateInfoRequest(data))
    }
  }

  useEffect(() => {
    if (customerUpdateInfoReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
      displayConsoleLog('success- notification', customerUpdateInfoSuccess.message)
    } else if (customerUpdateInfoReqStatus === API_CONSTANTS.ERROR_STATUS) {
      displayConsoleLog('error - notification', customerUpdateInfoError.message)
    }
    if (customerUpdateInfoReqStatus?.length) {
      dispatch(customerUpdateInfoRequestStatus(''))
    }
  }, [customerUpdateInfoSuccess, customerUpdateInfoError, customerUpdateInfoReqStatus])

  onMessageListener().then(payload => {
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    // displayConsoleLog('payload', payload)
    // displaySuccessToast(payload.notification.body)
  }).catch(err => displayConsoleLog('failed', err))

  const handleTutorialVendor = () => {
    navigate(ROUTES.tutorial.vendor)
  }

  const handleTutorialOperator = () => {
    navigate(ROUTES.tutorial.operator)
  }
  const handleTutorialCustomer = () => {
    navigate(ROUTES.tutorial.customer)
  }

  const handlePromotionClick = () => {
    navigate(ROUTES.promotionList)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: isLogin ? 'row' : 'column', height: '100vh', overflow: isLogin ? 'auto' : 'hidden' }}>
        {isLogin && user && displaySideBar === true && <CustomSidebar />}
        <div className="content-area-full">
          <Routes>
            <Route path={ROUTES.home} element={<HomePage />} />
            <Route path={ROUTES.promotionList} element={<SchemeList />} />
            <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
            <Route path={ROUTES.cookiePolicy} element={<CookiePolicyScreen />} />
            <Route path={ROUTES.customerLogin} element={<CustomerLogin />} />
            <Route path={ROUTES.vendorCreateAccount} element={<VendorCreateAccount />} />
            <Route path={ROUTES.vendorLogin} element={<VendorLogin />} />
            <Route path={ROUTES.tutorial.vendor} element={<VendorTutorial />} />
            <Route path={ROUTES.tutorial.operator} element={<OperatorTutorial />} />
            <Route path={ROUTES.tutorial.customer} element={<CustomerTutorial />} />
            <Route path={ROUTES.operatorList} element={
              <RouteGuard>
                <OperatorList />
              </RouteGuard>
            } />
            <Route path={ROUTES.operatorRegister} element={
              <RouteGuard>
                <AddOperator />
              </RouteGuard>
            } />
            <Route path={ROUTES.operatorDetail} element={
              <RouteGuard>
                <OperatorDetail />
              </RouteGuard>
            } />
            <Route path={ROUTES.addScheme} element={
              <RouteGuard>
                <AddScheme />
              </RouteGuard>
            } />
            <Route path={ROUTES.mediaList} element={
              <RouteGuard>
                <MediaList />
              </RouteGuard>
            } />
            <Route path={ROUTES.invoiceList} element={
              <RouteGuard>
                <InvoiceList />
              </RouteGuard>
            } />
            <Route path={ROUTES.uploadMedia} element={
              <RouteGuard>
                <UploadMedia />
              </RouteGuard>
            } />
            <Route path={ROUTES.mediaDetail} element={
              <RouteGuard>
                <MediaDetail />
              </RouteGuard>
            } />
            <Route path={ROUTES.createInvoice} element={
              <RouteGuard>
                <CreateInvoice />
              </RouteGuard>
            } />
            <Route path={ROUTES.invoiceDetail} element={
              <RouteGuard>
                <InvoiceDetail />
              </RouteGuard>
            } />
            <Route path={ROUTES.pointsRequestList} element={
              <RouteGuard>
                <PointsRequestList />
              </RouteGuard>
            } />
            <Route path={ROUTES.customerProfile} element={
              <RouteGuard>
                <CustomerProfile />
              </RouteGuard>
            } />
            <Route path={ROUTES.pointsRewardList} element={
              <RouteGuard>
                <PointsRewardList />
              </RouteGuard>
            } />
            <Route path={ROUTES.vendorList} element={
              <RouteGuard>
                <VendorList />
              </RouteGuard>
            } />
            <Route path={ROUTES.qrCode} element={
              <RouteGuard>
                <QrCode />
              </RouteGuard>
            } />
          </Routes>
        </div>
      </div>
      {showCustomerTutorial === true && <ImageSlider images={CUSTOMER_TUTORIAL_IMAGES} closeClickHandler={() => { setShowCustomerTutorial(false) }} />}
    </>
  );
}

export default App;
