export const UserActionTypes = {
    LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',

    CUSTOMER_LOGIN_REQUEST: 'CUSTOMER_LOGIN_REQUEST',
    CUSTOMER_LOGIN_REQUEST_SUCCESS: 'CUSTOMER_LOGIN_REQUEST_SUCCESS',
    CUSTOMER_LOGIN_REQUEST_ERROR: 'CUSTOMER_LOGIN_REQUEST_ERROR',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_REQUEST_SUCCESS: 'REGISTER_REQUEST_SUCCESS',
    REGISTER_REQUEST_ERROR: 'REGISTER_REQUEST_ERROR',
    REGISTER_REQUEST_STATUS: 'REGISTER_REQUEST_STATUS',

    SUBSCRIPTION_LIST_REQUEST: 'SUBSCRIPTION_LIST_REQUEST',
    SUBSCRIPTION_LIST_REQUEST_SUCCESS: 'SUBSCRIPTION_LIST_REQUEST_SUCCESS',
    SUBSCRIPTION_LIST_REQUEST_ERROR: 'SUBSCRIPTION_LIST_REQUEST_ERROR',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_REQUEST_SUCCESS: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
    FORGOT_PASSWORD_REQUEST_ERROR: 'FORGOT_PASSWORD_REQUEST_ERROR',
    FORGOT_PASSWORD_REQUEST_STATUS: 'FORGOT_PASSWORD_REQUEST_STATUS',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_REQUEST_SUCCESS: 'CHANGE_PASSWORD_REQUEST_SUCCESS',
    CHANGE_PASSWORD_REQUEST_ERROR: 'CHANGE_PASSWORD_REQUEST_ERROR',
    CHANGE_PASSWORD_REQUEST_STATUS: 'CHANGE_PASSWORD_REQUEST_STATUS',

    LOGOUT: 'LOGOUT',

    COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
    COUNTRY_LIST_REQUEST_SUCCESS: 'COUNTRY_LIST_REQUEST_SUCCESS',
    COUNTRY_LIST_REQUEST_ERROR: 'COUNTRY_LIST_REQUEST_ERROR',

    STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
    STATE_LIST_REQUEST_SUCCESS: 'STATE_LIST_REQUEST_SUCCESS',
    STATE_LIST_REQUEST_ERROR: 'STATE_LIST_REQUEST_ERROR',

    CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
    CITY_LIST_REQUEST_SUCCESS: 'CITY_LIST_REQUEST_SUCCESS',
    CITY_LIST_REQUEST_ERROR: 'CITY_LIST_REQUEST_ERROR',

    RESET_STATE_CITY: 'RESET_STATE_CITY',

    DISPLAY_SIDE_BAR: 'DISPLAY_SIDE_BAR',

    COMMON_ERROR: 'COMMON_ERROR',

    OPERATOR_REGISTRATION_REQUEST: 'OPERATOR_REGISTRATION_REQUEST',
    OPERATOR_REGISTRATION_REQUEST_SUCCESS: 'OPERATOR_REGISTRATION_REQUEST_SUCCESS',
    OPERATOR_REGISTRATION_REQUEST_ERROR: 'OPERATOR_REGISTRATION_REQUEST_ERROR',
    OPERATOR_REGISTRATION_REQUEST_STATUS: 'OPERATOR_REGISTRATION_REQUEST_STATUS',

    OPERATOR_LIST_REQUEST: 'OPERATOR_LIST_REQUEST',
    OPERATOR_LIST_REQUEST_SUCCESS: 'OPERATOR_LIST_REQUEST_SUCCESS',
    OPERATOR_LIST_REQUEST_ERROR: 'OPERATOR_LIST_REQUEST_ERROR',

    VENDOR_LIST_REQUEST: 'VENDOR_LIST_REQUEST',
    VENDOR_LIST_REQUEST_SUCCESS: 'VENDOR_LIST_REQUEST_SUCCESS',
    VENDOR_LIST_REQUEST_Error: 'VENDOR_LIST_REQUEST_Error',

    OPERATOR_DETAIL_REQUEST: 'OPERATOR_DETAIL_REQUEST',
    OPERATOR_DETAIL_REQUEST_SUCCESS: 'OPERATOR_DETAIL_REQUEST_SUCCESS',
    OPERATOR_DETAIL_REQUEST_ERROR: 'OPERATOR_DETAIL_REQUEST_ERROR',

    UPDATE_OPERATOR_LOCATIONS_REQUEST: 'UPDATE_OPERATOR_LOCATIONS_REQUEST',
    UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS: 'UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS',
    UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR: 'UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR',
    UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS: 'UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS',

    VENDOR_LIST_BY_LOCATION_REQUEST: 'VENDOR_LIST_BY_LOCATION_REQUEST',
    VENDOR_LIST_BY_LOCATION_REQUEST_SUCCESS: 'VENDOR_LIST_BY_LOCATION_REQUEST_SUCCESS',
    VENDOR_LIST_BY_LOCATION_REQUEST_ERROR: 'VENDOR_LIST_BY_LOCATION_REQUEST_ERROR'
}
