import { CustomerActionTypes } from './customer.types'

//Customer List
export const customerListRequest = data => ({
    type: CustomerActionTypes.CUSTOMER_LIST_REQUEST,
    payload: data
})

export const customerListRequestSuccess = data => ({
    type: CustomerActionTypes.CUSTOMER_LIST_REQUEST_SUCCESS,
    payload: data
})

export const customerListRequestError = data => ({
    type: CustomerActionTypes.CUSTOMER_LIST_REQUEST_ERROR,
    payload: data
})

//Customer Detail
export const customerDetailRequest = data => ({
    type: CustomerActionTypes.CUSTOMER_DETAIL_REQUEST,
    payload: data
})

export const customerDetailRequestSuccess = data => ({
    type: CustomerActionTypes.CUSTOMER_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const customerDetailRequestError = data => ({
    type: CustomerActionTypes.CUSTOMER_DETAIL_REQUEST_ERROR,
    payload: data
})

//Customer Update Info
export const customerUpdateInfoRequest = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST,
    payload: data
})

export const customerUpdateInfoRequestSuccess = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST_SUCCESS,
    payload: data
})

export const customerUpdateInfoRequestError = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST_ERROR,
    payload: data
})

export const customerUpdateInfoRequestStatus = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_INFO_REQUEST_STATUS,
    payload: data
})

//Customer Add Bank Detail
export const customerAddBankDetailRequest = data => ({
    type: CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST,
    payload: data
})

export const customerAddBankDetailRequestSuccess = data => ({
    type: CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const customerAddBankDetailRequestError = data => ({
    type: CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST_ERROR,
    payload: data
})

export const customerAddBankDetailRequestStatus = data => ({
    type: CustomerActionTypes.CUSTOMER_ADD_BANK_DETAIL_REQUEST_STATUS,
    payload: data
})

//Update bank details
export const customerUpdateBankDetailRequest = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST,
    payload: data
})

export const customerUpdateBankDetailRequestSuccess = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_SUCCESS,
    payload: data
})

export const customerUpdateBankDetailRequestError = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_ERROR,
    payload: data
})

export const customerUpdateBankDetailRequestStatus = data => ({
    type: CustomerActionTypes.CUSTOMER_UPDATE_BANK_DETAILS_REQUEST_STATUS,
    payload: data
})