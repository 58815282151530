import { UserActionTypes } from './user.types'

//Login
export const loginUserRequest = data => ({
    type: UserActionTypes.LOGIN_USER_REQUEST,
    payload: data
})

export const loginSuccess = user => ({
    type: UserActionTypes.LOGIN_USER_SUCCESS,
    payload: user
})

export const loginError = data => ({
    type: UserActionTypes.LOGIN_USER_ERROR,
    payload: data
})

//Register
export const registerRequest = data => ({
    type: UserActionTypes.REGISTER_REQUEST,
    payload: data
})

export const registerRequestSuccess = data => ({
    type: UserActionTypes.REGISTER_REQUEST_SUCCESS,
    payload: data
})

export const registerRequestError = data => ({
    type: UserActionTypes.REGISTER_REQUEST_ERROR,
    payload: data
})

export const registerRequestStatus = data => ({
    type: UserActionTypes.REGISTER_REQUEST_STATUS,
    payload: data
})

//Customer Login
export const customerLoginRequest = data => ({
    type: UserActionTypes.CUSTOMER_LOGIN_REQUEST,
    payload: data
})

export const customerLoginRequestSuccess = data => ({
    type: UserActionTypes.CUSTOMER_LOGIN_REQUEST_SUCCESS,
    payload: data
})

export const customerLoginRequestError = data => ({
    type: UserActionTypes.CUSTOMER_LOGIN_REQUEST_ERROR,
    payload: data
})

//Subscription list
export const subscriptionListRequest = () => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST
})

export const subscriptionListRequestSuccess = data => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST_SUCCESS,
    payload: data
})

export const subscriptionListRequestError = data => ({
    type: UserActionTypes.SUBSCRIPTION_LIST_REQUEST_ERROR,
    payload: data
})

//Forgot Password
export const forgotPasswordRequest = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST,
    payload: data
})

export const forgotPasswordRequestSuccess = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload: data
})

export const forgotPasswordRequestError = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_ERROR,
    payload: data
})

export const forgotPasswordRequestStatus = data => ({
    type: UserActionTypes.FORGOT_PASSWORD_REQUEST_STATUS,
    payload: data
})

//Change Password
export const changePasswordRequest = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST,
    payload: data
})

export const changePasswordRequestSuccess = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload: data
})

export const changePasswordRequestError = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_ERROR,
    payload: data
})

export const changePasswordRequestStatus = data => ({
    type: UserActionTypes.CHANGE_PASSWORD_REQUEST_STATUS,
    payload: data
})

//Operator Registration
export const operatorRegistrationRequest = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST,
    payload: data
})

export const operatorRegistrationRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST_SUCCESS,
    payload: data
})

export const operatorRegistrationRequestError = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST_ERROR,
    payload: data
})

export const operatorRegistrationRequestStatus = data => ({
    type: UserActionTypes.OPERATOR_REGISTRATION_REQUEST_STATUS,
    payload: data
})

//Operator List
export const operatorListRequest = () => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST
})

export const operatorListRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST_SUCCESS,
    payload: data
})

export const operatorListRequestError = data => ({
    type: UserActionTypes.OPERATOR_LIST_REQUEST_ERROR,
    payload: data
})

//Operator Detail
export const operatorDetailRequest = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST,
    payload: data
})

export const operatorDetailRequestSuccess = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const operatorDetailRequestError = data => ({
    type: UserActionTypes.OPERATOR_DETAIL_REQUEST_ERROR,
    payload: data
})

//Update Operator Location
export const updateOperatorLocationRequest = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST,
    payload: data
})

export const updateOperatorLocationRequestSuccess = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_SUCCESS,
    payload: data
})

export const updateOperatorLocationRequestError = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_ERROR,
    payload: data
})

export const updateOperatorLocationRequestStatus = data => ({
    type: UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST_STATUS,
    payload: data
})

//Vendor List
export const vendorListRequest = () => ({
    type: UserActionTypes.VENDOR_LIST_REQUEST
})

export const vendorListRequestSuccess = data => ({
    type: UserActionTypes.VENDOR_LIST_REQUEST_SUCCESS,
    payload: data
})

export const vendorListRequestError = data => ({
    type: UserActionTypes.VENDOR_LIST_REQUEST_Error,
    payload: data
})

//Vendor List by location
export const vendorListByLocationRequest = data => ({
    type: UserActionTypes.VENDOR_LIST_BY_LOCATION_REQUEST,
    payload: data
})

export const vendorListByLocationRequestSuccess = data => ({
    type: UserActionTypes.VENDOR_LIST_BY_LOCATION_REQUEST_SUCCESS,
    payload: data
})

export const vendorListByLocationRequestError = data => ({
    type: UserActionTypes.VENDOR_LIST_BY_LOCATION_REQUEST_ERROR,
    payload: data
})

//Country List
export const countryListRequest = () => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST
})

export const countryListRequestSuccess = data => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const countryListRequestError = data => ({
    type: UserActionTypes.COUNTRY_LIST_REQUEST_ERROR,
    payload: data
})

//State List
export const stateListRequest = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST,
    payload: data
})

export const stateListRequestSuccess = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST_SUCCESS,
    payload: data
})

export const stateListRequestError = data => ({
    type: UserActionTypes.STATE_LIST_REQUEST_ERROR,
    payload: data
})

//City List
export const cityListRequest = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST,
    payload: data
})

export const cityListRequestSuccess = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST_SUCCESS,
    payload: data
})

export const cityListRequestError = data => ({
    type: UserActionTypes.CITY_LIST_REQUEST_ERROR,
    payload: data
})

export const displaySideBarAct = data => ({
    type: UserActionTypes.DISPLAY_SIDE_BAR,
    payload: data
})