import React from "react";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from "../../utils/AppConstants";
import CommonButton from "../../components/button/common-button/commonButton";

const PartnerComponent = () => {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate(ROUTES.vendorLogin)
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-6 section-partner-with-us section-item-partner-content">
                    <div className="section-item-partner-label">
                        <div className="section-partner-label">Partner with us</div>
                        <div className="h-line" />
                    </div>
                    <div className="section-partner-label-one">Partner with us. <span className="section-partner-label-one section-partner-label-two">Get started to enhance your online visibility with our zero up-front cost loyalty program.</span></div>
                </div>
                <div className="col-6 section-partner-with-us section-item-button">
                    <CommonButton label='Get Started'
                        isBig={true}
                        onClick={() => handleClick()} />
                </div>
            </div>
        </div>
    )
}

export default PartnerComponent