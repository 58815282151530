import React, { useRef, useState, useEffect } from "react";
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { APP_CONSTANTS, ROUTES } from "../../utils/AppConstants";
import { clearLocalStorage, isNull } from "../../utils/Utils";

const NavigationBar = ({ navigate }) => {
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleOfferListClick = () => {
        navigate(ROUTES.promotionList)
    }

    const handleCustomerLoginClick = () => {
        navigate(ROUTES.customerLogin)
    }

    const handleVendorLoginClick = () => {
        navigate(ROUTES.vendorLogin)
    }

    const handleLogoutClick = () => {
        clearLocalStorage()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }

    return (
        <>
            <div className='hp-nav-header'>
                <div className="hp-logo-container">
                    {/* {isNull(user) && <img alt="" src={require('../../assets/logo_big_transparent.png')} className='nav-img' />}
                    {isNull(user) && <img alt="" src={require('../../assets/logo_big_transparent.png')} className='nav-img' />} */}
                    <img alt="" src={require('../../assets/logo_new.png')} className='img' />
                    <div className="hp-logo-label">MintBrand</div>
                </div>
                <div className="nav-item-button-container">
                    <div className="nav-product-btn black-font" onClick={() => handleOfferListClick()}>PROMOTIONS</div>
                    <div>
                        <div
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            className="nav-product-btn black-font"
                        >
                            ACCOUNT
                        </div>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                style={{ backgroundColor: '#fff' }}
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                                className="nav-menu-list-container"
                                            >
                                                {isNull(user) && <MenuItem onClick={handleCustomerLoginClick}>Customer</MenuItem>}
                                                {isNull(user) && <MenuItem onClick={handleVendorLoginClick}>Vendor</MenuItem>}
                                                {!isNull(user) && <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavigationBar