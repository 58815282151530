import { MediaActionTypes } from './media.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    addMediaSuccess: null,
    addMediaError: null,
    addMediaReqStatus: '',
    mediaListSuccess: null,
    mediaListError: null,
    mediaDetailSuccess: null,
    mediaDetailError: null,
    updateMediaStatusSuccess: null,
    updateMediaStatusError: null,
    updateMediaStatusReqStatus: '',
    mediaAddCommentSuccess: null,
    mediaAddCommentError: null,
    mediaAddCommentReqStatus: ''
}

const mediaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MediaActionTypes.ADD_MEDIA_REQUEST_SUCCESS:
            return {
                ...state,
                addMediaSuccess: action.payload,
                addMediaError: null,
                addMediaReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case MediaActionTypes.ADD_MEDIA_REQUEST_ERROR:
            return {
                ...state,
                addMediaSuccess: null,
                addMediaError: action.payload,
                addMediaReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case MediaActionTypes.ADD_MEDIA_REQUEST_STATUS:
            return {
                ...state,
                addMediaReqStatus: ''
            }
        case MediaActionTypes.MEDIA_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                mediaListSuccess: action.payload,
                mediaListError: null
            }
        case MediaActionTypes.MEDIA_LIST_REQUEST_ERROR:
            return {
                ...state,
                mediaListSuccess: null,
                mediaListError: action.payload
            }
        case MediaActionTypes.MEDIA_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                mediaDetailSuccess: action.payload,
                mediaDetailError: null
            }
        case MediaActionTypes.MEDIA_DETAIL_REQUEST_ERROR:
            return {
                ...state,
                mediaDetailSuccess: null,
                mediaDetailError: action.payload
            }
        case MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                updateMediaStatusSuccess: action.payload,
                updateMediaStatusError: null,
                updateMediaStatusReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST_ERROR:
            return {
                ...state,
                updateMediaStatusSuccess: null,
                updateMediaStatusError: action.payload,
                updateMediaStatusReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST_STATUS:
            return {
                ...state,
                updateMediaStatusReqStatus: ''
            }
        case MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST_SUCCESS:
            return {
                ...state,
                mediaAddCommentSuccess: action.payload,
                mediaAddCommentError: null,
                mediaAddCommentReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST_ERROR:
            return {
                ...state,
                mediaAddCommentSuccess: null,
                mediaAddCommentError: action.payload,
                mediaAddCommentReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST_STATUS:
            return {
                ...state,
                mediaAddCommentReqStatus: ''
            }
        case MediaActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default mediaReducer