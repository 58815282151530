import { takeLatest, call, put } from 'redux-saga/effects'
import {
    addSchemeAPI, schemeDetailAPI, schemeListAPI, schemeUpdateStatusAPI
} from '../api-requests/scheme';
import {
    addSchemeRequestError, addSchemeRequestSuccess, schemeDetailRequestError,
    schemeDetailRequestSuccess, schemeListRequestError, schemeListRequestSuccess,
    schemeUpdateStatusRequestError, schemeUpdateStatusRequestSuccess
} from './scheme.action';
import { SchemeActionTypes } from './scheme.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleSchemeUpdateStatus({ payload }) {
    try {
        const response = yield call(schemeUpdateStatusAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(schemeUpdateStatusRequestSuccess(decryptedData))
            } else {
                yield put(schemeUpdateStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SchemeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(schemeUpdateStatusRequestError(error.response.data))
        }
    }
}

export function* schemeUpdateStatusReq() {
    yield takeLatest(SchemeActionTypes.SCHEME_UPDATE_STATUS_REQUEST, handleSchemeUpdateStatus)
}

function* handleSchemeList({ payload }) {
    try {
        const response = yield call(schemeListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(schemeListRequestSuccess(decryptedData))
            } else {
                yield put(schemeListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SchemeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(schemeListRequestError(error.response.data))
        }
    }
}

export function* schemeListReq() {
    yield takeLatest(SchemeActionTypes.SCHEME_LIST_REQUEST, handleSchemeList)
}

function* handleSchemeDetail({ payload }) {
    try {
        const response = yield call(schemeDetailAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(schemeDetailRequestSuccess(decryptedData))
            } else {
                yield put(schemeDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SchemeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(schemeDetailRequestError(error.response.data))
        }
    }
}

export function* schemeDetailReq() {
    yield takeLatest(SchemeActionTypes.SCHEME_DETAIL_REQUEST, handleSchemeDetail)
}

function* handleAddScheme({ payload }) {
    try {
        const response = yield call(addSchemeAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addSchemeRequestSuccess(decryptedData))
            } else {
                yield put(addSchemeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: SchemeActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(addSchemeRequestError(error.response.data))
        }
    }
}

export function* addSchemeReq() {
    yield takeLatest(SchemeActionTypes.ADD_SCHEME_REQUEST, handleAddScheme)
}