import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import moment from "moment";
import { invoiceDetailRequest, invoiceUpdatePaymentRequest, invoiceUpdatePaymentRequestStatus } from '../../../redux/invoice/invoice.action'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { config } from '../../../config'
import Modal from 'react-bootstrap/Modal';
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";

const InvoiceDetail = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { state } = useLocation()
    const { invoiceDetailSuccess, invoiceDetailError,
        invoiceUpdatePaymentSuccess, invoiceUpdatePaymentError, invoiceUpdatePaymentReqStatus } = useSelector((state) => state.invoice)
    const dispatch = useDispatch()
    const [displayUpdatePaymentModal, setDisplayUpdatePaymentModal] = useState(false)
    const [paymentDropdown, setPaymentDropdown] = useState()
    const INVOICE_PAYMENT_STATUS_LIST = [
        { key: APP_CONSTANTS.PAYMENT_STATUS.PAID, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.PAYMENT_STATUS.PAID) },
        { key: APP_CONSTANTS.PAYMENT_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.PAYMENT_STATUS.ERROR) }
    ]
    const [showErrorInput, setShowErrorInput] = useState(false)
    const [errorInput, setErrorInput] = useState('')
    const [showPaymentModeInput, setShowPaymentModeInput] = useState(false)
    const [paymentModeInput, setPaymentModeInput] = useState('')
    const [displayReceiptModal, setDisplayReceiptModal] = useState(false)
    const [receiptImagePath, setReceiptImagePath] = useState('')

    useEffect(() => {
        fetchInvoiceDetail()
    }, [])

    const fetchInvoiceDetail = () => {
        setLoading(true)
        dispatch(invoiceDetailRequest(state))
    }

    useEffect(() => {
        if (!isNull(invoiceDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(invoiceDetailError)) {
            setLoading(false)
            displayErrorToast(invoiceDetailError.message)
        }
    }, [invoiceDetailSuccess, invoiceDetailError])

    useEffect(() => {
        if (invoiceUpdatePaymentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(invoiceUpdatePaymentSuccess.message)
            fetchInvoiceDetail()
        } else if (invoiceUpdatePaymentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(invoiceUpdatePaymentError.message)
        }
        if (invoiceUpdatePaymentReqStatus.length) {
            dispatch(invoiceUpdatePaymentRequestStatus(''))
        }
    }, [invoiceUpdatePaymentSuccess, invoiceUpdatePaymentError, invoiceUpdatePaymentReqStatus])

    const handleViewReceiptClick = item => {
        setReceiptImagePath(`${config.imageUrl}${item.image}`)
        setDisplayReceiptModal(true)
    }

    const handleUpdatePaymentStatusClick = () => {
        if (isEmpty(paymentDropdown)) {
            displayErrorToast('Payment status not selected')
        } else if (paymentDropdown.key === APP_CONSTANTS.PAYMENT_STATUS.PAID && isEmpty(paymentModeInput)) {
            displayErrorToast('Payment mode cannot be blank')
        } else if (paymentDropdown.key === APP_CONSTANTS.PAYMENT_STATUS.ERROR && isEmpty(errorInput)) {
            displayErrorToast('Error information cannot be blank')
        } else {
            let data = {
                invoiceId: invoiceDetailSuccess.data.invoiceId,
                paymentStatus: paymentDropdown.key
            }
            if (showPaymentModeInput === true) {
                data.paymentMode = paymentModeInput
            }
            if (showErrorInput === true) {
                data.reason = errorInput
            }
            setLoading(true)
            dispatch(invoiceUpdatePaymentRequest(data))
            setDisplayUpdatePaymentModal(false)
        }
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Invoice Details' />
                <CommonFormBorder>
                    {!isNull(invoiceDetailSuccess) && !isNull(invoiceDetailSuccess.data) &&
                        <div>
                            <div className="id-date-label">Invoice Date: {moment(invoiceDetailSuccess.data.createdOn).utc().format('DD MMM YYYY')}</div>
                            <FormFieldLabel className='up-bold-label' label='Vendor Details' />
                            {invoiceDetailSuccess.data.businessDetails && <div>
                                <div>{invoiceDetailSuccess.data.businessDetails.businessName}</div>
                            </div>}
                            <FormFieldLabel className='up-bold-label' label='Operator Details' />
                            {invoiceDetailSuccess.data.operatorDetails && <div>
                                <div>{invoiceDetailSuccess.data.operatorDetails.firstName} {invoiceDetailSuccess.data.operatorDetails.lastName}</div>
                                <div>{invoiceDetailSuccess.data.operatorDetails.email}</div>
                            </div>}
                            <FormFieldLabel className='up-bold-label' label='Scheme Details' />
                            {invoiceDetailSuccess.data.schemeDetails && <div>
                                <div className='form-field-label-grey'>Scheme Name</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.schemeDetails.schemeName}</div>
                                <div className='form-field-label-grey'>Brand Name</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.schemeDetails.brand ? invoiceDetailSuccess.data.schemeDetails.brand : '-'}</div>
                                <div className='form-field-label-grey'>Views</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.schemeDetails.viewsCount}</div>
                                <div className='form-field-label-grey'>Points</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.schemeDetails.points}</div>
                                <div className='form-field-label-grey'>Scheme Date</div>
                                <div className="form-field-text-label">Valid from : {moment(invoiceDetailSuccess.data.schemeDetails.startDate).utc().format('DD MMM YYYY')} to {moment(invoiceDetailSuccess.data.schemeDetails.endDate).utc().format('DD MMM YYYY')}</div>
                                <div className='form-field-label-grey'>Scheme Location</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.schemeDetails.city}, {invoiceDetailSuccess.data.schemeDetails.state}, {invoiceDetailSuccess.data.schemeDetails.country}</div>
                            </div>}
                            <FormFieldLabel className='up-bold-label' label='Payment Details' />
                            <div className='form-field-label-grey'>Payment Status</div>
                            <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(invoiceDetailSuccess.data.paymentStatus)}</div>
                            {invoiceDetailSuccess.data.paymentStatus === APP_CONSTANTS.PAYMENT_STATUS.PAID && <div>
                                <div className='form-field-label-grey'>Payment Mode</div>
                                <div className="form-field-text-label">{invoiceDetailSuccess.data.paymentMode ? removeSpecialCharAndChangeToPascal(invoiceDetailSuccess.data.paymentMode) : '-'}</div>
                            </div>}
                            {invoiceDetailSuccess.data.paymentStatus === APP_CONSTANTS.PAYMENT_STATUS.ERROR &&
                                !isEmpty(invoiceDetailSuccess.data.errorReason) && <div>
                                    <div className='form-field-label-grey'>Error reason</div>
                                    <div className="form-field-text-label">{invoiceDetailSuccess.data.errorReason}</div>
                                </div>}
                            <div className='form-field-label-grey'>Total Amount</div>
                            <div className="form-field-text-label">{getAmountWithCurrency(invoiceDetailSuccess.data, invoiceDetailSuccess.data.finalAmount)}</div>
                            <FormFieldLabel className='up-bold-label' label='Customer Media List' />
                            {!isEmpty(invoiceDetailSuccess.data.mediaDetailList) && <div>
                                {invoiceDetailSuccess.data.mediaDetailList.map(e => (
                                    <div className="id-receipt-parent-container">
                                        <div className="id-receipt-detail-container">
                                            <div className="id-receipt-detail-field-label">Media Number: <span className="id-receipt-detail-value-label">{e.customerMediaId}</span></div>
                                            <div className="id-receipt-detail-field-label">Views: <span className="id-receipt-detail-value-label">{e.mediaViewCount}</span></div>
                                            <div className="id-receipt-detail-field-label">Media: <span className="id-receipt-detail-value-label">{e.mediaType} / {e.mediaPlatform}</span></div>
                                            <div className="id-receipt-detail-field-label">Cashback Amount: <span className="id-receipt-detail-value-label">{getAmountWithCurrency(e, e.cashbackAmount)}</span></div>
                                            <div className="id-receipt-detail-field-label">Status: <span className="id-receipt-detail-value-label">{removeSpecialCharAndChangeToPascal(e.mediaStatus)}</span></div>
                                        </div>
                                        <div className="id-receipt-img-container" onClick={() => handleViewReceiptClick(e)}>
                                            <img src={require('../../../assets/ic_file_icon.png')} className="img" />
                                        </div>
                                    </div>
                                ))}
                            </div >}

                            {isLoggedInUserOperator(user) && invoiceDetailSuccess.data.paymentStatus === APP_CONSTANTS.PAYMENT_STATUS.PENDING && <ButtonRow>
                                <CommonButton label='Update Payment Status'
                                    onClick={() => { setDisplayUpdatePaymentModal(true) }} />
                            </ButtonRow>}

                        </div >}
                </CommonFormBorder >
            </CommonScreenContent >
            <Modal
                onHide={() => { setDisplayReceiptModal(false) }}
                show={displayReceiptModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(receiptImagePath) && <div>
                        <img src={receiptImagePath} className="img" />
                    </div>}
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { setDisplayUpdatePaymentModal(false) }}
                show={displayUpdatePaymentModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Payment Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={paymentDropdown}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={INVOICE_PAYMENT_STATUS_LIST}
                        placeholder='Select Payment Status'
                        onChange={e => {
                            setPaymentDropdown(e)
                            if (e.key === APP_CONSTANTS.PAYMENT_STATUS.PAID) {
                                setShowPaymentModeInput(true)
                            } else {
                                setShowPaymentModeInput(false)
                            }
                            if (e.key === APP_CONSTANTS.PAYMENT_STATUS.ERROR) {
                                setShowErrorInput(true)
                            } else {
                                setShowErrorInput(false)
                            }
                        }} />
                    {showErrorInput && <CommonInputFloatFull
                        type='text'
                        id='reasonInput'
                        onChange={e => setErrorInput(e.target.value)}
                        value={errorInput}
                        placeholder='Enter Error'
                    />}
                    {showPaymentModeInput && <CommonInputFloatFull
                        type='text'
                        id='successInput'
                        onChange={e => setPaymentModeInput(e.target.value)}
                        value={paymentModeInput}
                        placeholder='Payment Mode'
                    />}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleUpdatePaymentStatusClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default InvoiceDetail