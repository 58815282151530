import React from "react";
import './styles.css'

const CustomerTutorial = props => {
    return (
        <>
            <header className="tut-header">
                <h1>Welcome to JUSTGOMO Customer Guide</h1>
            </header>
            <nav className="tut-nav">
                <ul>
                    <li><a href="#login">LOGGING IN</a></li>
                    <li><a href="#offers">OFFERS</a></li>
                    <li><a href="#receipts">RECEIPTS</a></li>
                    <li><a href="#cashback">CASHBACKS</a></li>
                    <li><a href="#profile">PROFILE</a></li>
                </ul>
            </nav>
            <section className="tut-section" id="login">
                <h2>Logging In</h2>
                <p>To access your JUSTGOMO customer account, follow these steps:</p>
                <ol>
                    <li>Visit the JUSTGOMO website from any browser of your choice</li>
                    <li>Select the "Account" option from the navigation menu on the top.</li>
                    <li>Choose "Customer Login."</li>
                </ol>
                <p>You will be prompted to enter your mobile number for authentication. Once you successfully log in, you'll enter the customer console.</p>
            </section>
            <section className="tut-section" id="offers">
                <h2>Offers</h2>
                <p>The "Offers" section allows you to discover available offers from vendors. Here's how to navigate this section:</p>
                <ol>
                    <li>Click on the "Offers" option from the customer console.</li>
                    <li>On the top of the screen, you will find an location filter option. This helps filter offers specific to your area.</li>
                    <li>After selecting a location, you'll see a list of offers available in that area, with the most recent ones displayed first.</li>
                </ol>
            </section>

            <section className="tut-section" id="receipts">
                <h2>Receipts</h2>
                <p>In the "Receipts" section, you can manage and view the receipts you've uploaded. Here's how to use this feature:</p>
                <ol>
                    <li>Click on the "Receipts" option from the customer console.</li>
                    <li>On top of the screen, you'll find a filter to select receipt types. Choose from options like "Pending," "Operator Approved," "Vendor Approved," "Error," "Cancelled," and "Disputed."</li>
                    <li>After selecting a receipt type, you'll see a list of receipts that match your criteria.</li>
                    <li>You can click on each receipt to view its details.</li>
                </ol>
            </section>

            <section className="tut-section" id="cashback">
                <h2>Cashback</h2>
                <p>The "Cashback" section allows you to manage your cashback requests. Here's how to use this feature:</p>
                <ol>
                    <li>Click on the "Cashback" option from the customer console.</li>
                    <li>Use the request type selection filter on top to filter requests based on their status. Options include "Pending," "In-Process," "Paid," "Error," and "Reject."</li>
                    <li>After selecting a request type, you'll see a list of cashback requests that match your selection.</li>
                    <li>Each request corresponds to cashback earned for vendor-approved receipts under specific offers. You can view the details of each request.</li>
                </ol>
            </section>

            <section className="tut-section" id="profile">
                <h2>Profile</h2>
                <p>The "Profile" section allows you to manage your account information and cashback earnings. Here's how to update your profile and view your earnings:</p>
                <ol>
                    <li>Click on the "Profile" option from the customer console.</li>
                    <li>In the profile information section, you can view and update your customer name and email if they haven't been added previously.</li>
                    <li>The "Cashback Earned" section displays information about all the cashback you've earned from uploaded receipts. It includes details such as the currency in which cashback was earned and the country.</li>
                    <li>For each cashback earned, you have the option to request cashback. Clicking on this option will initiate a request to the operator to process your cashback.</li>
                    <li>The third section of your profile includes details of the bank account where you'll receive your cashback amount once the threshold is reached.</li>
                </ol>
            </section>

            <footer className="tut-footer">
                <p>Congratulations! You're now familiar with the customer functionalities on the JUSTGOMO platform. Feel free to explore offers, manage receipts, request cashback, and update your profile to make the most of your JUSTGOMO experience.</p>
                <p>If you encounter any issues or have questions, please contact our customer support for assistance.</p>
                <p>Enjoy your savings with JUSTGOMO!</p>
            </footer>
        </>
    )
}

export default CustomerTutorial