import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import TopButton from "../../../components/button/top-button/topButton";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { vendorListByLocationRequest } from '../../../redux/user/user.action'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { convertToCamelcaseWithSpecialChar, displayCurrencySymbolLeft, displayErrorToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal';
import { toPng } from "html-to-image";
import download from "downloadjs";
import QRCode from 'qrcode.react';
import Input from '@mui/material/Input';
import { config } from "../../../config";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonButton from "../../../components/button/common-button/commonButton";

const VendorList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user, vendorListByLocSuccess, vendorListByLocError } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const [showQRCodeDialog, setShowQRCodeDialog] = useState(false)
    const [customMessage, setCustomMessage] = useState('')
    const svgRef = useRef(null);
    const [qrSize, setQrSize] = useState(256)
    const [qrImgSize, setQrImgSize] = useState({ width: 35, height: 17.5 })
    const [labelSize, setLabelSize] = useState(14)
    const [customTextSize, setCustomTextSize] = useState(28)
    const ariaLabel = { 'aria-label': 'description' };

    const fetchVendorList = (country = null, state = null, city = null) => {
        const data = {}
        if (!isEmpty(country)) {
            data.country = country
        }
        if (!isEmpty(state)) {
            data.state = state
        }
        if (!isEmpty(city)) {
            data.city = city
        }
        setLoading(true)
        dispatch(vendorListByLocationRequest(data))
    }

    useEffect(() => {
        if (!isNull(vendorListByLocSuccess)) {
            setLoading(false)

        } else if (!isNull(vendorListByLocError)) {
            setLoading(false)
        }
    }, [vendorListByLocSuccess, vendorListByLocError])

    const handleCardClick = (element) => {
        navigate(ROUTES.mediaList, { state: element.otherDetails[0].businessId })
    }

    const handleGetQrCodeClick = (businessId) => {
        setQrSize(256)
        setQrImgSize({ width: 35, height: 17.5 })
        setLabelSize(14)
        setCustomTextSize(28)
        let url = `${config.webAppUrl}/${ROUTES.promotionList}?vendor=${businessId}`
        console.log('url', url)
        setQRCodeUrl(url)
        setCustomMessage('')
        setShowQRCodeDialog(true)
    }

    const downloadQR = () => {
        const width = svgRef.current.offsetWidth;
        toPng(svgRef.current, { width })
            .then(function (dataUrl) {
                download(dataUrl, "qr-code.png");
            })
            .catch(function (error) {
                console.error("oops, something went wrong!", error);
            });
    }

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newWidth = 5 + (5 * percentChange)
        let newHeight = 2.5 + (2.5 * percentChange)
        let newLabelSize = 4 + (4 * percentChange)
        let newCustomTextSize = 8 + (8 * percentChange)
        setQrImgSize({ width: newWidth, height: newHeight })
        setLabelSize(newLabelSize / 2)
        setCustomTextSize(newCustomTextSize / 2)
    }, [qrSize])

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Vendors' />
                </CommonInputRow>
                <FormFieldLabel className='up-bold-label' label='Select Location' />
                {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                {!isEmpty(user.data.userDetails.accessLocations) &&
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.country}
                        getOptionValue={e => e}
                        options={user.data.userDetails.accessLocations}
                        placeholder='Select Country'
                        onChange={e => {
                            setCountryDropdown(e)
                            if (isEmpty(e.stateCity)) {
                                fetchVendorList(e.country)
                            }
                        }} />
                }
                {!isEmpty(user.data.userDetails.accessLocations) && !isNull(countryDropdown) &&
                    !isEmpty(countryDropdown?.stateCity) &&
                    <CommonInputRow>
                        <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={stateDropdown}
                            getOptionLabel={e => e.state}
                            getOptionValue={e => e}
                            options={countryDropdown.stateCity}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e)
                                if (isEmpty(e.city)) {
                                    fetchVendorList(countryDropdown.country, e.state)
                                }
                            }} />
                        {!isEmpty(stateDropdown?.city) && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={cityDropdown}
                            getOptionLabel={e => e.value}
                            getOptionValue={e => e}
                            options={stateDropdown.city.map(city => {
                                return { key: city, value: city };
                            })}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e)
                                fetchVendorList(countryDropdown.country, stateDropdown.state, e.value)
                            }} />}
                    </CommonInputRow>}
                <div className="table-scroll-container">
                    {!isEmpty(vendorListByLocSuccess) && !isEmpty(vendorListByLocSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Business Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Location</th>
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Created On</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendorListByLocSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.otherDetails[0].businessName}</td>
                                    <td className="rl-table-col-item text-center">{element.city}, {element.state}, {element.country}</td>
                                    <td className="rl-table-col-item text-center">{element.otherDetails[0].status === 1 ? 'Active' : 'In-Active'}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.otherDetails[0].createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="vl-btn-row-container">
                                            <div className="vl-btn-container" onClick={() => { handleCardClick(element) }}>View Links</div>
                                            <div className="vl-btn-container" onClick={() => { handleGetQrCodeClick(element.otherDetails[0].businessId) }}>Get QR Code</div>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {!isNull(vendorListByLocError) && <div className="error-text-container">{vendorListByLocError.message}</div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowQRCodeDialog(false) }}
                show={showQRCodeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Download QR Code
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input className="mt-2 mb-2"
                        onChange={t => setCustomMessage(t.target.value)}
                        value={customMessage}
                        placeholder="Type Custom Message"
                        inputProps={ariaLabel}
                        fullWidth />
                    <div id="qrCode" className="qr-container" ref={svgRef}>
                        <div className="cqc-qr-container">
                            <QRCode value={qrCodeUrl} size={qrSize < 38 ? 38 : qrSize}
                                renderAs='svg'
                                imageSettings={{
                                    src: require('../../../assets/logo_new.png'),
                                    height: qrImgSize.height,
                                    width: qrImgSize.width,
                                    excavate: true
                                }}

                            />
                            <div className="cqc-powered-label" style={{ fontSize: labelSize }}>Powered By MintBrand.Buzz</div>
                            <div className="qr-code-footer">
                                <div className="qr-footer-text" style={{ fontSize: customTextSize }}>{customMessage}</div>
                            </div>
                        </div>
                    </div>
                    <CommonInputFloatFull
                        onChange={t => setQrSize(t.target.value)}
                        placeholder='Desired QR Code Size (In pixels)'
                        type='number'
                        value={qrSize}
                        isSubtext={true}
                        subText='Minimum size should be 38px (1cm)' />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton onClick={() => downloadQR()}
                        label='Download' />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )

}
export default VendorList