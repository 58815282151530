export const PointsRequestActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_POINTS_REQUEST: 'ADD_POINTS_REQUEST',
    ADD_POINTS_REQUEST_SUCCESS: 'ADD_POINTS_REQUEST_SUCCESS',
    ADD_POINTS_REQUEST_ERROR: 'ADD_POINTS_REQUEST_ERROR',
    ADD_POINTS_REQUEST_STATUS: 'ADD_POINTS_REQUEST_STATUS',

    POINTS_REQUEST_LIST_REQUEST: 'POINTS_REQUEST_LIST_REQUEST',
    POINTS_REQUEST_LIST_REQUEST_SUCCESS: 'POINTS_REQUEST_LIST_REQUEST_SUCCESS',
    POINTS_REQUEST_LIST_REQUEST_ERROR: 'POINTS_REQUEST_LIST_REQUEST_ERROR',

    POINTS_UPDATE_STATUS_REQUEST: 'POINTS_UPDATE_STATUS_REQUEST',
    POINTS_UPDATE_STATUS_REQUEST_SUCCESS: 'POINTS_UPDATE_STATUS_REQUEST_SUCCESS',
    POINTS_UPDATE_STATUS_REQUEST_ERROR: 'POINTS_UPDATE_STATUS_REQUEST_ERROR',
    POINTS_UPDATE_STATUS_REQUEST_STATUS: 'POINTS_UPDATE_STATUS_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',
}
