export const SchemeActionTypes = {
    LOGOUT: 'LOGOUT',

    ADD_SCHEME_REQUEST: 'ADD_SCHEME_REQUEST',
    ADD_SCHEME_REQUEST_SUCCESS: 'ADD_SCHEME_REQUEST_SUCCESS',
    ADD_SCHEME_REQUEST_ERROR: 'ADD_SCHEME_REQUEST_ERROR',
    ADD_SCHEME_REQUEST_STATUS: 'ADD_SCHEME_REQUEST_STATUS',

    SCHEME_DETAIL_REQUEST: 'SCHEME_DETAIL_REQUEST',
    SCHEME_DETAIL_REQUEST_SUCCESS: 'SCHEME_DETAIL_REQUEST_SUCCESS',
    SCHEME_DETAIL_REQUEST_ERROR: 'SCHEME_DETAIL_REQUEST_ERROR',

    SCHEME_LIST_REQUEST: 'SCHEME_LIST_REQUEST',
    SCHEME_LIST_REQUEST_SUCCESS: 'SCHEME_LIST_REQUEST_SUCCESS',
    SCHEME_LIST_REQUEST_ERROR: 'SCHEME_LIST_REQUEST_ERROR',

    SCHEME_UPDATE_STATUS_REQUEST: 'SCHEME_UPDATE_STATUS_REQUEST',
    SCHEME_UPDATE_STATUS_REQUEST_SUCCESS: 'SCHEME_UPDATE_STATUS_REQUEST_SUCCESS',
    SCHEME_UPDATE_STATUS_REQUEST_ERROR: 'SCHEME_UPDATE_STATUS_REQUEST_ERROR',
    SCHEME_UPDATE_STATUS_REQUEST_STATUS: 'SCHEME_UPDATE_STATUS_REQUEST_STATUS',

    COMMON_ERROR: 'COMMON_ERROR',
}
