import { all, call } from 'redux-saga/effects'
import {
    changePasswordReq, cityListReq, countryListReq, forgotPasswordReq,
    loginUserReq, operatorListReq, operatorRegisterReq, registerUserReq, stateListReq,
    subscriptionListReq, vendorListReq, customerLoginReq, operatorDetailReq,
    updateOperatorLocationReq, vendorListByLocationReq
} from './user/user.saga'
import { addMediaReq, mediaDetailReq, mediaListReq, mediaUpdateReq, mediaAddCommentReq } from './media/media.saga'
import { addInvoiceReq, invoiceDetailReq, invoiceListReq, invoiceUpdatePaymentReq } from './invoice/invoice.saga'
import { addPointsRequestReq, pointsRequestListReq, pointsRequestUpdateStatusReq } from './points-request/points.saga'
import {
    customerAddBankDetailReq, customerDetailReq, customerListReq, customerUpdateInfoReq,
    customerUpdateBankInfoReq
} from './customer/customer.saga'
import { addSchemeReq, schemeDetailReq, schemeListReq, schemeUpdateStatusReq } from './scheme/scheme.saga'
import { pointsRewardListReq, redeemPointsReward } from './points-reward/pointsReward.saga'

export default function* rootSaga() {
    yield all([
        call(loginUserReq),
        call(customerLoginReq),
        call(registerUserReq),
        call(subscriptionListReq),
        call(changePasswordReq),
        call(forgotPasswordReq),
        call(cityListReq),
        call(countryListReq),
        call(stateListReq),
        call(operatorListReq),
        call(operatorRegisterReq),
        call(vendorListReq),
        call(addMediaReq),
        call(mediaDetailReq),
        call(mediaListReq),
        call(mediaUpdateReq),
        call(addInvoiceReq),
        call(invoiceDetailReq),
        call(invoiceListReq),
        call(invoiceUpdatePaymentReq),
        call(addPointsRequestReq),
        call(pointsRequestListReq),
        call(pointsRequestUpdateStatusReq),
        call(customerAddBankDetailReq),
        call(customerDetailReq),
        call(customerListReq),
        call(customerUpdateInfoReq),
        call(addSchemeReq),
        call(schemeDetailReq),
        call(schemeListReq),
        call(schemeUpdateStatusReq),
        call(operatorDetailReq),
        call(updateOperatorLocationReq),
        call(customerUpdateBankInfoReq),
        call(pointsRewardListReq),
        call(redeemPointsReward),
        call(mediaAddCommentReq),
        call(vendorListByLocationReq),
    ])
}