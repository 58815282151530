export const PointsRewardActionTypes = {
    LOGOUT: 'LOGOUT',

    COMMON_ERROR: 'COMMON_ERROR',

    POINTS_REWARD_LIST_REQUEST: 'POINTS_REWARD_LIST_REQUEST',
    POINTS_REWARD_LIST_REQUEST_SUCCESS: 'POINTS_REWARD_LIST_REQUEST_SUCCESS',
    POINTS_REWARD_LIST_REQUEST_ERROR: 'POINTS_REWARD_LIST_REQUEST_ERROR',

    REDEEM_POINTS_REQUEST: 'REDEEM_POINTS_REQUEST',
    REDEEM_POINTS_REQUEST_SUCCESS: 'REDEEM_POINTS_REQUEST_SUCCESS',
    REDEEM_POINTS_REQUEST_ERROR: 'REDEEM_POINTS_REQUEST_ERROR',
    REDEEM_POINTS_REQUEST_STATUS: 'REDEEM_POINTS_REQUEST_STATUS'
}
