import React from "react";
import './styles.css'

const OperatorTutorial = props => {
    return (
        <>
            <header className="tut-header">
                <h1>Welcome to JUSTGOMO Operator Guide</h1>
            </header>
            <nav className="tut-nav-header">
                <a className="tut-nav-header-item" href="#registration">REGISTRATION</a>
                <a className="tut-nav-header-item" href="#login">LOGIN</a>
                <a className="tut-nav-header-item" href="#receipts">RECEIPTS</a>
                <a className="tut-nav-header-item" href="#cashback">CASHBACKS</a>
                <a className="tut-nav-header-item" href="#invoices">INVOICES</a>
            </nav>
            <section className="tut-section" id="registration">
                <h2>Registration</h2>
                <p>As an operator, you will be registered on the JUSTGOMO platform by the product owner. Your registration will be handled by the product owner, and you will receive your login credentials via email. These credentials are securely generated and not visible to the product owner.</p>
            </section>
            <section className="tut-section" id="login">
                <h2>Login</h2>
                <p>Once you receive your login credentials via email, follow these steps to access your operator account:</p>
                <ul>
                    <li>Access the Login Page:
                        <ul>
                            <li>Visit the JUSTGOMO platform and click on the "Vendor Login" option under Accounts options.</li>
                        </ul>
                    </li>
                    <li>Enter Your Credentials:
                        <ul>
                            <li>Input your registered email ID and the password received via email.</li>
                        </ul>
                    </li>
                    <li>Login to Your Account:
                        <ul>
                            <li>Click the "Login" button to access your operator console.</li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section className="tut-section" id="receipts">
                <h2>Receipts</h2>
                <p>In the operator console, you'll have access to receipt management tools.</p>
                <p>Viewing Receipts</p>
                <ul>
                    <li>Click on "Receipts" from the menu.</li>
                    <li>Utilize the filters at the top:
                        <ul>
                            <li>First, select a location from the available options.</li>
                            <li>Then, choose a receipt type from options like Pending, Operator Approved, Vendor Approved, Error, Cancelled, or Disputed.</li>
                        </ul>
                    </li>
                    <li>Receipts matching your criteria will be displayed.</li>
                </ul>
                <p>Managing Receipts</p>
                <ul>
                    <li>Click on a receipt to view its details.</li>
                    <li>You can update the receipt status to one of the following:
                        <ul>
                            <li>Operator Approved: If everything is correct with the uploaded receipt.</li>
                            <li>Error: Provide a reason for the error.</li>
                            <li>Dispute: Enter a reason for the dispute.</li>
                        </ul>
                    </li>
                    <li>Make the necessary updates and save.</li>
                </ul>
            </section>
            <section className="tut-section" id="cashback">
                <h2>Cashback</h2>
                <p>The "Cashback" section allows you to manage your cashback requests. Here's how to use this feature:</p>
                <ol>
                    <li>Click on the "Cashback" option from the operator console.</li>
                    <li>Use the request type selection filter on top to filter requests based on their status. Options include "Pending," "In-Process," "Paid," "Error," and "Reject."</li>
                    <li>After selecting a request type, you'll see a list of cashback requests that match your selection from the customers.</li>
                    <li>Each request corresponds to cashback earned for vendor-approved receipts under specific offers. You can view the details of each request.</li>
                    <li>If the request payment status is pending, you have the option to update it to either PAID or ERROR on cashback request detail screen.</li>
                </ol>
            </section>
            <section className="tut-section" id="invoices">
                <h2>Invoices</h2>
                <p>Viewing Invoices</p>
                <ul>
                    <li>Click on "Invoice" from the menu.</li>
                    <li>Select a location from the list, for which you have access.</li>
                    <li>A filter displaying available offers for that location will appear.</li>
                    <li>Choose an offer from the list to view invoices associated with it.</li>
                    <li>Invoices created by you for vendor-approved receipts under the selected offer will be displayed.</li>
                </ul>
                <p>Creating a New Invoice</p>
                <ul>
                    <li>Click the "New Invoice" button on the top of the screen.</li>
                    <li>Select a location and offer, similar to how you did when viewing invoices.</li>
                    <li>A list of vendor-approved receipts will appear.</li>
                    <li>Select the receipts for which you want to create an invoice (multi-selection is possible).</li>
                    <li>Click the "Submit" button at the bottom to create a new invoice.</li>
                    <li>The invoice will be visible to you and the vendor who created the offer.</li>
                </ul>
                <p>Invoice Details</p>
                <ul>
                    <li>Click on an invoice to view its details.</li>
                    <li>You can review all invoice information.</li>
                </ul>
                <p>Updating Payment Status</p>
                <ul>
                    <li>If the invoice payment status is pending, you have the option to update it to either PAID or ERROR.</li>
                </ul>
            </section>
            <footer className="tut-footer">
                <p>Congratulations! You've now learned how to navigate and use the operator console on JUSTGOMO. If you have any questions or need further assistance, please feel free to contact our support team.</p>
                <p>With these tools at your disposal, you can efficiently manage receipts and invoices for your assigned locations, ensuring a smooth and streamlined process for all users involved. We appreciate your contribution to the JUSTGOMO platform!</p>
            </footer>
        </>
    )
}

export default OperatorTutorial