import { takeLatest, call, put } from 'redux-saga/effects'
import {
    pointsRewardListAPI, redeemPointsAPI
} from '../api-requests/pointsReward';
import {
    pointsRewardListRequestError, pointsRewardListRequestSuccess, redeemPointsRequestError,
    redeemPointsRequestSuccess
} from './pointsReward.action';
import { PointsRewardActionTypes } from './pointsReward.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleRedeemPointsReward({ payload }) {
    try {
        const response = yield call(redeemPointsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(redeemPointsRequestSuccess(decryptedData))
            } else {
                yield put(redeemPointsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: PointsRewardActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(redeemPointsRequestError(error.response.data))
        }
    }
}

export function* redeemPointsReward() {
    yield takeLatest(PointsRewardActionTypes.REDEEM_POINTS_REQUEST, handleRedeemPointsReward)
}

function* handlePointsRewardList({ payload }) {
    try {
        const response = yield call(pointsRewardListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(pointsRewardListRequestSuccess(decryptedData))
            } else {
                yield put(pointsRewardListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: PointsRewardActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(pointsRewardListRequestError(error.response.data))
        }
    }
}

export function* pointsRewardListReq() {
    yield takeLatest(PointsRewardActionTypes.POINTS_REWARD_LIST_REQUEST, handlePointsRewardList)
}