import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function customerUpdateInfoAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.customer.customerUpdateInfo}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}
export function customerListAPI() {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.customer.customerList}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function customerDetailAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.customer.customerDetail}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function customerAddBankInfoAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.customer.addBankDetails}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function customerUpdateBankInfoAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.customer.updateBankDetails}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}