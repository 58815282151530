import React from "react";
import './styles.css'

const VendorTutorial = props => {
    return (
        <>
            <header className="tut-header">
                <h1>Welcome to JUSTGOMO Vendor (or Merchant) Guide</h1>
            </header>
            <nav className="tut-nav-header">
                <a className="tut-nav-header-item" href="#registration">REGISTRATION</a>
                <a className="tut-nav-header-item" href="#login">LOGIN</a>
                <a className="tut-nav-header-item" href="#offers">OFFERS</a>
                <a className="tut-nav-header-item" href="#receipts">RECEIPTS</a>
                <a className="tut-nav-header-item" href="#invoices">INVOICES</a>
            </nav>
            <section className="tut-section" id="registration">
                <h2>Registration</h2>
                <p>To get started as a vendor on JUSTGOMO, follow these steps:</p>
                <ul>
                    <li>Access the Registration Page:
                        <ul><li>From the login screen, click on the "Create Account" option.</li></ul>
                    </li>
                    <li>Provide Your Details:
                        <ul>
                            <li>Enter your email ID and create a strong password.</li>
                            <li>Fill in your business information accurately.</li>
                            <li>Complete any additional registration fields as required.</li>
                        </ul>
                    </li>
                    <li>Submit Registration:
                        <ul>
                            <li>After filling in all the necessary details, click "Register."</li>
                            <li>You'll be redirected back to login page upon successful registration.</li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section className="tut-section" id="login">
                <h2>Login</h2>
                <p>Once you've successfully registered, you can log in to your vendor account:</p>
                <ul>
                    <li>Access the Login Page:
                        <ul>
                            <li>Visit the JUSTGOMO platform and click on the "Account" option. Select the Vendor Login option</li>
                        </ul>
                    </li>
                    <li>Enter Your Credentials:
                        <ul><li>Input your registered email ID and password.</li></ul>
                    </li>
                    <li>Login to Your Account:
                        <ul><li>Click the "Login" button to access your vendor console.</li></ul>
                    </li>
                </ul>
            </section>
            <section className="tut-section" id="offers">
                <h2>Offers</h2>
                <p>Now that you're logged in, let's explore the features available in the vendor console.</p>
                <p>Creating a New Offer</p>
                <ul>
                    <li>Click on "Offers" from the menu.</li>
                    <li>To create a new offer, click the "Create Offer" button.</li>
                    <li>Fill in the offer details:
                        <ul>
                            <li>Offer name</li>
                            {/* <li>Brand name</li> */}
                            <li>Offer Image</li>
                            <li>Offer start and end date</li>
                            {/* <li>Currency, Minimum and maximum bill amount</li> */}
                            {/* <li>Locations where the offer will be available.</li> */}
                            <li>And, other required details</li>
                        </ul>
                    </li>
                    <li>Upon successful submission, your offer will be visible to customers.</li>
                </ul>
                <p>Viewing Offers</p>
                <ul>
                    <li>Within the "Offers" section, you can see a list of offers posted by you.</li>
                    <li>This is where you can manage your existing offers.</li>
                </ul>
            </section>
            <section className="tut-section" id="receipts">
                <h2>Receipts</h2>
                <p>Viewing Receipts</p>
                <ul>
                    <li>Click on "Receipts" from the menu.</li>
                    <li>Use the filters at the top:
                        <ul>
                            <li>First, select an offer filter.</li>
                            {/* <li>Then, choose a receipt type from options like Operator Approved, Error, Cancelled, or Disputed.</li> */}
                            <li>Then, choose a receipt type from list of available options</li>
                        </ul>
                    </li>
                    <li>Receipts that match your criteria will be displayed.</li>
                </ul>
                <p>Managing Receipts</p>
                <ul>
                    <li>Click on a receipt to view its details.</li>
                    <li>You can update the receipt status to one of the following:
                        <ul>
                            <li>Approved: Enter the cashback amount for the customer.</li>
                            <li>Error/Dispute: Provide a reason for the error/dispute.</li>
                            {/* <li>Dispute: Enter a reason for the dispute.</li> */}
                        </ul>
                    </li>
                    <li>Make the necessary updates and save.</li>
                </ul>
            </section>
            <section className="tut-section" id="invoices">
                <h2>Invoices</h2>
                <p>Viewing Invoices</p>
                <ul>
                    <li>Click on "Invoice" from the menu.</li>
                    <li>Select an offer from the list to view all invoices associated with that offer.</li>
                    <li>Here, you'll find invoices for approved receipts.</li>
                </ul>
                <p>Invoice Details</p>
                <ul>
                    <li>Click on an invoice to view its details.</li>
                    <li>You can review the invoice and associated information.</li>
                </ul>
            </section>
            <footer className="tut-footer">
                <p>Congratulations! You've now successfully learned how to navigate and utilize the vendor console on JUSTGOMO. If you have any further questions or need assistance, please don't hesitate to contact our support team.</p>
                <p>With these tools at your disposal, you can effectively manage your offers and track customer receipts and invoices effortlessly. We wish you success in your business endeavors on JUSTGOMO!</p>
            </footer>
        </>
    )
}

export default VendorTutorial