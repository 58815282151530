import { MediaActionTypes } from './media.types'

//Add Media
export const addMediaRequest = data => ({
    type: MediaActionTypes.ADD_MEDIA_REQUEST,
    payload: data
})

export const addMediaRequestSuccess = data => ({
    type: MediaActionTypes.ADD_MEDIA_REQUEST_SUCCESS,
    payload: data
})

export const addMediaRequestError = data => ({
    type: MediaActionTypes.ADD_MEDIA_REQUEST_ERROR,
    payload: data
})

export const addMediaRequestStatus = data => ({
    type: MediaActionTypes.ADD_MEDIA_REQUEST_STATUS,
    payload: data
})

//Media List
export const mediaListRequest = data => ({
    type: MediaActionTypes.MEDIA_LIST_REQUEST,
    payload: data
})

export const mediaListRequestSuccess = data => ({
    type: MediaActionTypes.MEDIA_LIST_REQUEST_SUCCESS,
    payload: data
})

export const mediaListRequestError = data => ({
    type: MediaActionTypes.MEDIA_LIST_REQUEST_ERROR,
    payload: data
})

//Media Detail
export const mediaDetailRequest = data => ({
    type: MediaActionTypes.MEDIA_DETAIL_REQUEST,
    payload: data
})

export const mediaDetailRequestSuccess = data => ({
    type: MediaActionTypes.MEDIA_DETAIL_REQUEST_SUCCESS,
    payload: data
})

export const mediaDetailRequestError = data => ({
    type: MediaActionTypes.MEDIA_DETAIL_REQUEST_ERROR,
    payload: data
})

//Media Update Status
export const mediaUpdateStatusRequest = data => ({
    type: MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST,
    payload: data
})

export const mediaUpdateStatusRequestSuccess = data => ({
    type: MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const mediaUpdateStatusRequestError = data => ({
    type: MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST_ERROR,
    payload: data
})

export const mediaUpdateStatusRequestStatus = data => ({
    type: MediaActionTypes.MEDIA_UPDATE_STATUS_REQUEST_STATUS,
    payload: data
})

//Media add comment
export const mediaAddCommentRequest = data => ({
    type: MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST,
    payload: data
})

export const mediaAddCommentRequestSuccess = data => ({
    type: MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST_SUCCESS,
    payload: data
})

export const mediaAddCommentRequestError = data => ({
    type: MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST_ERROR,
    payload: data
})

export const mediaAddCommentRequestStatus = data => ({
    type: MediaActionTypes.MEDIA_ADD_COMMENT_REQUEST_STATUS,
    payload: data
})