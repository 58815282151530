import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from "moment";
import { schemeListRequest } from '../../../redux/scheme/scheme.action'
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { displayCurrencySymbolLeft, displayErrorToast, isEmpty, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { config } from "../../../config";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../redux/user/user.action'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import CommonButton from "../../../components/button/common-button/commonButton";
import NavigationBar from "../../navigation-bar/navigationBar";
import TopButton from "../../../components/button/top-button/topButton";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import { CATEGORIES } from "../../../utils/Categories";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CustomSearchBar from '../../../components/search-bar/searchBar'

const SchemeList = props => {
    const [isLoading, setLoading] = useState(false)
    const { schemeListSuccess, schemeListError, commonError } = useSelector((state) => state.scheme)
    const { isLogin, user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [showSelectLocationModal, setShowSelectLocatonModal] = useState(false)
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [allSchemeExpired, setAllSchemeExpired] = useState(false)
    const [categoryDropdown, setCategoryDropdown] = useState()
    const [searchQuery, setSearchQuery] = useState('')
    const [emptyFieldCounter, setEmptyFieldCounter] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        if (!isEmpty(searchParams) && !isEmpty(searchParams.get('vendor'))) {
            const data = {
                businessId: parseFloat(searchParams.get('vendor'))
            }
            fetchSchemeList(data)
        } else {
            fetchSchemeList({})
        }

        if (!isLoggedInUserVendor(user))
            dispatch(countryListRequest())
    }, [])

    const fetchSchemeList = (data) => {
        setLoading(true)
        const updateData = {
            ...data
        }
        if (!isEmpty(categoryDropdown)) {
            let category = categoryDropdown.map(item => item.categoryKey).join('+')
            updateData.category = category
        }
        if (!isEmpty(searchQuery)) {
            updateData.searchQuery = searchQuery
        }
        if (!isNull(user) && isLoggedInUserVendor(user) && !isNull(user.data.userDetails.businessDetails)) {
            updateData.businessId = Number(user.data.userDetails.businessDetails.businessId)
        }
        dispatch(schemeListRequest(updateData))
    }

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(schemeListSuccess)) {
            setLoading(false)
            if (!isEmpty(schemeListSuccess.data)) {
                let expiredSchemes = schemeListSuccess.data.filter(e => Number(e.status) === 0)
                if (expiredSchemes.length === schemeListSuccess.data.length) {
                    setAllSchemeExpired(true)
                } else {
                    setAllSchemeExpired(false)
                }
            }
        } else if (!isNull(schemeListError)) {
            setLoading(false)
        }
    }, [schemeListSuccess, schemeListError])

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const handleSelectLocation = () => {
        const data = {}
        if (!isNull(countryDropdown)) {
            data.country = countryDropdown.name
            localStorage.setItem('ofCountry', JSON.stringify(countryDropdown))
            if (!isNull(stateDropdown)) {
                data.state = stateDropdown.name
                localStorage.setItem('ofState', JSON.stringify(stateDropdown))
            }
            if (!isNull(cityDropdown)) {
                data.city = cityDropdown.name
                localStorage.setItem('ofCit', JSON.stringify(cityDropdown))
            }
        }
        fetchSchemeList(data)
        setShowSelectLocatonModal(false)
    }

    const handleAddSchemeClick = () => {
        navigate(ROUTES.addScheme)
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSearchClick = () => {
        if (isEmpty(searchQuery)) {
            if (Number(emptyFieldCounter) < 3) {
                let currentCounter = emptyFieldCounter
                setEmptyFieldCounter(currentCounter + 1)
                const data = {}
                fetchSchemeList(data)
            } else {
                displayErrorToast('Please try refreshing the page.')
            }
        } else {
            const data = {}
            fetchSchemeList(data)
        }

    }

    const getCategoryName = (catKey) => {
        const category = CATEGORIES.find(e => e.categoryKey === catKey)
        if (!isNull(category)) {
            return category.name
        } else return ''
    }

    const isSchemeExpired = (schemeEndDate, currentDate) => {
        // const momen1 = new Date(schemeEndDate)
        // const moment2 = new Date(currentDate)
        // if (momen1.getTime() > moment2.getTime()) {
        //     return true
        // } else {
        //     return false
        // }
        if (moment(currentDate).isAfter(moment(schemeEndDate))) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <div>
                {!isLogin && isNull(user) && <NavigationBar navigate={navigate} />}
                {isLogin && !isNull(user) && <ScreenLabel label='Promotions' />}
                <CommonScreenContent>
                    {/* <ScreenLabel label='Schemes' /> */}
                    {!isLoggedInUserVendor(user) && <div className="offer-filter-container">
                        <CustomSearchBar
                            query={searchQuery}
                            placeholder='Search Promotions'
                            queryChange={e => setSearchQuery(e.target.value)}
                            clickListener={() => { handleSearchClick() }} />
                        <div className="offer-filter-item-container" onClick={() => { setShowSelectLocatonModal(true) }}>
                            <div className="offer-filter-image-container">
                                <img src={require('../../../assets/ic_filter.png')} className="img" />
                            </div>
                            <div className="offer-filter-label">FILTER</div>
                        </div>
                    </div>}
                    {isLoggedInUserVendor(user) &&
                        <div className="offer-add-btn-container">
                            <TopButton label='Add Promotion' onClick={() => { handleAddSchemeClick() }} />
                        </div>}
                    {!isNull(schemeListSuccess) && !isEmpty(schemeListSuccess.data) && !allSchemeExpired && <div className="offer-list-container">
                        {schemeListSuccess.data.map(element => {
                            if (isLoggedInUserVendor(user)) {
                                return (
                                    <Card sx={{ width: 345, marginRight: 1, marginTop: 1 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={`${config.imageUrl}${element.image}`}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <div className="offer-content-container">
                                                    <div className="offer-brand-label">{element.brand ? element.brand : 'Brand'}</div>
                                                    <div className="offer-title">{element.schemeName}</div>
                                                    {isSchemeExpired(element.endDate, element.currentTime) && <div className="offer-expired-label">Expired</div>}
                                                    <div className="offer-validity-label">Valid from: {moment(element.startDate).utc().format('DD/MM/YY')} to {moment(element.endDate).utc().format('DD/MM/YY')}</div>
                                                    <div className="offer-bill-label">&#10033; {element.points} points on {element.viewsCount} {element.countType ? removeSpecialCharAndChangeToPascal(element.countType) : 'N/A'}</div>
                                                    <div className="offer-category-container">{getCategoryName(element.categoryKey)}</div>
                                                    <div className="offer-business-label">{element.businessDetail?.businessName}</div>
                                                    <div className="offer-city-label">{element.areaName}, {element.city}</div>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )
                            } else {
                                if (Number(element.status) === 1) {
                                    return (
                                        <Card sx={{ width: 345, marginRight: 1, marginTop: 1 }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="200"
                                                    image={`${config.imageUrl}${element.image}`}
                                                    alt="green iguana"
                                                />
                                                <CardContent>
                                                    <div className="offer-content-container">
                                                        <div className="offer-brand-label">{element.brand ? element.brand : 'Brand'}</div>
                                                        <div className="offer-title">{element.schemeName}</div>
                                                        {isSchemeExpired(element.endDate, element.currentTime) && <div className="offer-expired-label">Expired</div>}
                                                        <div className="offer-validity-label">Valid from: {moment(element.startDate).utc().format('DD/MM/YY')} to {moment(element.endDate).utc().format('DD/MM/YY')}</div>
                                                        <div className="offer-bill-label">&#10033; {element.points} points on {element.viewsCount} {element.countType ? removeSpecialCharAndChangeToPascal(element.countType) : 'N/A'}</div>
                                                        <div className="offer-category-container">{getCategoryName(element.categoryKey)}</div>
                                                        <div className="offer-business-label">{element.businessDetail?.businessName}</div>
                                                        <div className="offer-city-label">{element.areaName}, {element.city}</div>
                                                        {/* <div className="offer-view-details">View Details</div> */}
                                                    </div>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    )
                                }
                            }
                        })}
                    </div>}
                    {!isNull(schemeListError) && <div className="content-error">{schemeListError.message}</div>}
                    {allSchemeExpired && <div className="content-error">No Promotion Found</div>}
                </CommonScreenContent>
            </div>

            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
            <Modal
                onHide={() => { setShowSelectLocatonModal(false) }}
                show={showSelectLocationModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Filter Promotions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label='Select Category' />
                    {CATEGORIES && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={categoryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={CATEGORIES}
                        placeholder='Select Category'
                        isMulti={true}
                        onChange={e => {
                            setCategoryDropdown(e)
                        }} />}
                    <FormFieldLabel label='Select Location' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    {stateListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border up-select-margin'
                        value={stateDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={stateListSuccess}
                        placeholder='Select State'
                        onChange={e => {
                            setStateDropdown(e);
                            setCityDropdown(null)
                            fetchCityList(countryCode, e.iso2)
                        }} />}
                    {cityListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border up-select-margin'
                        value={cityDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={cityListSuccess}
                        placeholder='Select City'
                        onChange={e => {
                            setCityDropdown(e);
                        }} />}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Apply'
                        onClick={() => { handleSelectLocation() }} />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SchemeList