import ScreenLabel from '../../components/label/screen-label/screenLabel'
import { ORGANISATION_DETAIL } from '../../utils/AppConstants'
import './styles.css'

const CookiePolicyScreen = props => {
    return (
        <div className='cp-container'>
            <ScreenLabel label='Cookie Policy' />
            <div className='cp-screenLabel'>Cookie Policy</div>
            <div className='cp-label'>Cookie Notice Statement</div>
            <div className='cp-updateLabel'>Last updated on [01-01-2023].</div>
            <div className='cp-text cp-first-inline'>We at JustGOMO (JustGOMO,” “we,” “us,” or “our”) have created this cookie notice (this “Cookie Notice”) to disclose how and why we collect, use and otherwise process information collected through “cookie” technology, unique identifiers and similar technologies on our Direct ordering Platform, Discover marketplace Platform, and our other Services, platforms, and websites. This Cookie Notice supplements our Privacy Policy, which you should review for more information about our data collection practices generally. Capitalized terms not defined in this Cookie Notice shall have the meaning set forth in our Privacy Policy or Terms of Service.</div>
            <div className='cp-text-small-bold'>Cookie Notice</div>
            <div className='cp-text cp-first-inline'>Cookies are small packets of data that a website stores on your computer’s or mobile device’s hard drive (or other storage medium) so that your computer will “remember” information about your visit. We use cookies and other online unique identifiers to help us collect other personal information and to enhance your experience using the Services. Specifically, we use cookies and online unique identifiers to authenticate users, personalize your experience when using the Services, analyze which features of the Services you use most frequently, and measure and optimize advertising and promotional effectiveness.</div>
            <div className='cp-text cp-first-inline'>We use both first- and third-party session cookies and persistent cookies. Below is a general primer on session and persistent cookies; information collected by cookies depends on its particular purpose.</div>
            <div className='cp-text cp-second-inline'><span className='cp-text-span-bold'>Session Cookies: </span>We use session cookies to make it easier for you to navigate our Services. A session ID cookie expires when you close your browser.</div>
            <div className='cp-text cp-second-inline'><span className='cp-text-span-bold'>Persistent Cookies: </span>A persistent cookie remains on your hard drive for an extended period of time or until you delete them. You can remove persistent cookies by following directions provided in your web browser’s “help” file. Persistent cookies enable us to track and target the interests of our visitors to personalize the experience on our Services.</div>
            <div className='cp-text cp-first-inline'>In some cases, we may associate information that you have provided to us (e.g., email address) with the cookies that we use. In addition to facilitating the purposes described above, this is useful in understanding your engagement with other content related to our Services (e.g., email open rates, URL click-throughs).</div>
            <div className='cp-text-small-bold'>Third-Party Analytics Providers</div>
            <div className='cp-text cp-first-inline'>We use one or more third–party analytics services to evaluate your use of the Services, as the case may be, by compiling reports on activity (based on their collection of IP addresses, Internet service provider, browser type, operating system and language, referring and exit pages and URLs, data and time, amount of time spent on particular pages, what sections of the Services you visit, number of links clicked, search terms and other similar usage data) and analyzing performance metrics. These third parties use cookies and other technologies to help collect, analyze, and provide us reports or other data. For more information about these third-party analytics providers, please see our Privacy Policy.</div>
            <div className='cp-text-small-bold'>Third-Party Advertisers/Remarketers</div>
            <div className='cp-text cp-first-inline'>We may share or receive information about you with/from advertising and remarketing providers or similar partners, for purposes of personalizing or otherwise understanding how you engage with ads or other content. These third parties may use cookies, pixel tags, or other technologies to collect information in furtherance of such purposes, including to tailor, target (i.e., behavioral, contextual, retargeting, and remarketing), analyze, report on, and/or manage advertising campaigns or other initiatives. For example, when a browser visits a site, pixel tags enable us and these third-parties to recognize certain cookies stored within the browser to learn which ads or other content bring a user to a given site. Information that we may receive from these third parties, including through their service providers, may include advertising identifiers, IP addresses, reports, and campaign data.</div>
            <div className='cp-text-small-bold'>Controlling Cookies and Other Rights</div>
            <div className='cp-text cp-first-inline'>If you do not want us to place a cookie on your device, you may be able to turn that feature off on your device. You may refuse to accept cookies from the Service at any time by activating the setting on your browser which allows you to refuse cookies. Further information about the procedure to follow in order to disable cookies can be found on your Internet browser provider’s website via your help screen. You may wish to refer to http://www.allaboutcookies.org/manage-cookies/index.html for information on commonly used browsers. For more information about targeting and advertising cookies and how you can opt out, you can also visit http://optout.aboutads.info. Please be aware that if cookies are disabled, not all features of the Services may operate properly or as intended.</div>
            <div className='cp-text-small-bold'>Contact Details</div>
            <div className='cp-text cp-first-inline'>If you have any questions or comments about this notice, our Cookie Policy, the ways in which we collect and use your personal information, your choices and rights regarding such use, please do not hesitate to contact us at:</div>
            <div className='cp-text cp-first-inline cp-text-black'>{ORGANISATION_DETAIL.ORGANISATION_NAME}</div>
            {/* <div className='cp-text cp-first-inline cp-text-zero-margin'>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE1}</div> */}
            <div className='cp-text cp-first-inline cp-text-zero-margin'>{ORGANISATION_DETAIL.ORG_ADDRESS_LINE2}</div>
            <div className='cp-text cp-first-inline cp-text-zero-margin cp-text-black'>Email: {ORGANISATION_DETAIL.ORG_SUPPORT_EMAIL}</div>
            {/* <div className='cp-text cp-first-inline'>If you are you are California resident, you may have the right to direct JustGOMO not to sell your personal information. To exercise this right, or for more information about your rights under California law generally, please visit our CA Privacy Notice and/or Do Not Sell Request.</div> */}
        </div>
    )
}

export default CookiePolicyScreen