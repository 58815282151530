import { PointsRequestActionTypes } from './pointsRequest.types'

//Add Points
export const addPointsRequest = data => ({
    type: PointsRequestActionTypes.ADD_POINTS_REQUEST,
    payload: data
})

export const addPointsRequestSuccess = data => ({
    type: PointsRequestActionTypes.ADD_POINTS_REQUEST_SUCCESS,
    payload: data
})

export const addPointsRequestError = data => ({
    type: PointsRequestActionTypes.ADD_POINTS_REQUEST_ERROR,
    payload: data
})

export const addPointsRequestStatus = data => ({
    type: PointsRequestActionTypes.ADD_POINTS_REQUEST_STATUS,
    payload: data
})

//Points List
export const pointsListRequest = data => ({
    type: PointsRequestActionTypes.POINTS_REQUEST_LIST_REQUEST,
    payload: data
})

export const pointsListRequestSuccess = data => ({
    type: PointsRequestActionTypes.POINTS_REQUEST_LIST_REQUEST_SUCCESS,
    payload: data
})

export const pointsListRequestError = data => ({
    type: PointsRequestActionTypes.POINTS_REQUEST_LIST_REQUEST_ERROR,
    payload: data
})

//Points Update Status
export const pointsUpdateStatusRequest = data => ({
    type: PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST,
    payload: data
})

export const pointsUpdateStatusRequestSuccess = data => ({
    type: PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST_SUCCESS,
    payload: data
})

export const pointsUpdateStatusRequestError = data => ({
    type: PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST_ERROR,
    payload: data
})

export const pointsUpdateStatusRequestStatus = data => ({
    type: PointsRequestActionTypes.POINTS_UPDATE_STATUS_REQUEST_STATUS,
    payload: data
})