import React, { useEffect, useState } from "react";
import './styles.css'
import { useLocation } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    mediaDetailRequest, mediaUpdateStatusRequest, mediaUpdateStatusRequestStatus,
    mediaAddCommentRequest, mediaAddCommentRequestStatus
} from '../../../redux/media/media.action'
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import moment from "moment";
import { displayCurrencySymbolLeft, displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserProductOwner, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import { config } from "../../../config";
import { CurrencyLabel } from "../../../utils/CurrencyLabel";

const MediaDetail = props => {
    const { state } = useLocation()
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { mediaDetailSuccess, mediaDetailError, updateMediaStatusSuccess,
        updateMediaStatusError, updateMediaStatusReqStatus,
        mediaAddCommentSuccess, mediaAddCommentError, mediaAddCommentReqStatus } = useSelector(state => state.media)
    const [displayUpdateStatusModal, setDisplayUpdateStatusModal] = useState(false)
    const [receiptStatusDropdown, setReceiptStatusDropdown] = useState()
    const [displayReasonInput, setDisplayReasonInput] = useState(false)
    const [displayCashbackInput, setDisplayCashbackInput] = useState(false)
    const [reasonInput, setReasonInput] = useState('')
    const [cashbackInput, setCashbackInput] = useState('')
    const [pointsInput, setPointsInput] = useState('')
    const [currencyDropdown, setCurrencyDropdown] = useState()
    const [displayReceiptModal, setDisplayReceiptModal] = useState(false)
    const [receiptImagePath, setReceiptImagePath] = useState('')
    const [displayCommentsInput, setDisplayCommentsInput] = useState(false)
    const [comments, setComments] = useState('')
    const [displayAddCommentModal, setDisplayAddCommentModal] = useState(false)
    const VENDOR_RECEIPT_STATUS = [
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED) },
    ]

    useEffect(() => {
        fetchReceiptDetails()
    }, [])

    const fetchReceiptDetails = () => {
        setLoading(true)
        dispatch(mediaDetailRequest(state))
    }

    useEffect(() => {
        if (!isNull(mediaDetailSuccess)) {
            setLoading(false)
        } else if (!isNull(mediaDetailError)) {
            setLoading(false)
            displayErrorToast(mediaDetailError.message)
        }
    }, [mediaDetailSuccess, mediaDetailError])

    useEffect(() => {

        if (updateMediaStatusReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(updateMediaStatusSuccess.message)
            fetchReceiptDetails()
        } else if (updateMediaStatusReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(updateMediaStatusError.message)
        }
        if (updateMediaStatusReqStatus.length) {
            dispatch(mediaUpdateStatusRequestStatus(''))
        }
    }, [updateMediaStatusSuccess, updateMediaStatusError, updateMediaStatusReqStatus])

    useEffect(() => {

        if (mediaAddCommentReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(mediaAddCommentSuccess.message)
            fetchReceiptDetails()
        } else if (mediaAddCommentReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(mediaAddCommentError.message)
        }
        if (mediaAddCommentReqStatus?.length) {
            dispatch(mediaAddCommentRequestStatus(''))
        }
    }, [mediaAddCommentSuccess, mediaAddCommentError, mediaAddCommentReqStatus])

    const displayButtonRow = () => {
        if (
            !isNull(mediaDetailSuccess) &&
            !isNull(mediaDetailSuccess.data) &&
            !isEmpty(mediaDetailSuccess.data.mediaStatus)
        ) {
            if (
                (isLoggedInUserVendor(user) &&
                    mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING) ||
                (isLoggedInUserCustomer(user) &&
                    mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED) ||
                (isLoggedInUserCustomer(user) &&
                    mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DRAFT)
            ) {
                return true;
            }
        }
        return false;
    }

    const displayAddCommentButton = () => {
        if (
            !isNull(mediaDetailSuccess) &&
            !isNull(mediaDetailSuccess.data) &&
            !isEmpty(mediaDetailSuccess.data.mediaStatus)
        ) {
            if (isLoggedInUserCustomer(user) &&
                mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED
            ) {
                return true;
            }
        }
        return false;
    }

    const displaySubmitButton = () => {
        if (
            !isNull(mediaDetailSuccess) &&
            !isNull(mediaDetailSuccess.data) &&
            !isEmpty(mediaDetailSuccess.data.mediaStatus)
        ) {
            if (isLoggedInUserCustomer(user) &&
                mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DRAFT
            ) {
                return true;
            }
        }
        return false;
    }

    const displayUpdateStatusButton = () => {
        if (
            !isNull(mediaDetailSuccess) &&
            !isNull(mediaDetailSuccess.data) &&
            !isEmpty(mediaDetailSuccess.data.mediaStatus)
        ) {
            if (
                (isLoggedInUserVendor(user) &&
                    mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING)
            ) {
                return true;
            }
        }
        return false;
    }

    const handleUpdateStatusSubmitClick = () => {
        if (isEmpty(receiptStatusDropdown)) {
            displayErrorToast('Media status not selected')
        } else if (displayReasonInput === true && isEmpty(reasonInput)) {
            displayErrorToast('Reason cannot be blank')
        } else if (displayCommentsInput === true && isEmpty(comments)) {
            displayErrorToast('Comments cannot be blank')
        } else if (displayCashbackInput === true && isEmpty(pointsInput)) {
            displayErrorToast('Points cannot be blank')
        } else if (displayCashbackInput === true && parseFloat(pointsInput) < 0) {
            displayErrorToast('Points cannot be less than 0')
        } else {
            const data = {
                customerMediaId: mediaDetailSuccess.data.customerMediaId,
                status: receiptStatusDropdown.key,
                userType: user.data.userDetails.userType
            }
            if (displayReasonInput === true) {
                data.reason = reasonInput
            }
            if (displayCommentsInput === true) {
                data.comments = comments
            }
            if (displayCashbackInput === true) {
                data.points = parseFloat(pointsInput)
                // data.cashbackAmount = parseFloat(cashbackInput)
                // data.currencyLabel = currencyDropdown.code
                // data.currencySymbol = currencyDropdown.symbol
                // data.currencyRegion = currencyDropdown.region
            }
            setLoading(true)
            dispatch(mediaUpdateStatusRequest(data))
            setDisplayUpdateStatusModal(false)
        }
    }

    const handleAddCommentSubmitClick = () => {
        if (isEmpty(comments)) {
            displayErrorToast('Comments cannot be blank')
        } else {
            const data = {
                customerMediaId: mediaDetailSuccess.data.customerMediaId,
                userType: user.data.userDetails.userType,
                comments: comments
            }
            setLoading(true)
            dispatch(mediaAddCommentRequest(data))
            setDisplayAddCommentModal(false)
        }
    }

    const handleEarnRewardClick = () => {
        const data = {
            customerMediaId: mediaDetailSuccess.data.customerMediaId,
            status: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING,
            userType: user.data.userDetails.userType
        }
        setLoading(true)
        dispatch(mediaUpdateStatusRequest(data))
    }

    const handleViewReceiptClick = item => {
        setReceiptImagePath(`${config.imageUrl}${item.image}`)
        setDisplayReceiptModal(true)
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Link Details' />
                {!isNull(mediaDetailSuccess) && <CommonFormBorder>
                    <FormFieldLabel className='up-bold-label' label='Social Media Link Details' />
                    <div className='form-field-label-grey'>Customer Media Number</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.customerMediaId}</div>
                    <div className='form-field-label-grey'>Media Link</div>
                    {!isEmpty(mediaDetailSuccess.data.mediaLinkList) && <div className="media-list-container">
                        {mediaDetailSuccess.data.mediaLinkList.map(e => (
                            <div className="media-list-row-container">
                                <div className="media-type-label">{e.mediaPlatform} {removeSpecialCharAndChangeToPascal(e.mediaType)}</div>
                                {/* <div className="media-link-label">{e.mediaLink}</div> */}
                                <div className="md-media-link-container" onClick={() => { window.open(`${e.mediaLink}`, '_blank') }}>
                                    <div className="form-field-text-label">{e.mediaLink}</div>
                                    <div className="md-link-icon-container">
                                        <img src={require('../../../assets/ic_link_icon.png')} className="img" />
                                    </div>
                                </div>
                                <div className="media-platform-label"><span className="media-view-count-label">{e.mediaViewCount}</span> {removeSpecialCharAndChangeToPascal(e.countType)}</div>
                            </div>
                        ))}
                    </div>}
                    {/* <div className="md-media-link-container" onClick={() => { window.open(`${mediaDetailSuccess.data.mediaLink}`, '_blank') }}>
                        <div className="form-field-text-label">{mediaDetailSuccess.data.mediaLink}</div>
                        <div className="md-link-icon-container">
                            <img src={require('../../../assets/ic_link_icon.png')} className="img" />
                        </div>
                    </div> */}

                    {/* <div className='form-field-label-grey'>Media Type & Platform</div> */}
                    {/* <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(mediaDetailSuccess.data.mediaType)} / {mediaDetailSuccess.data.mediaPlatform}</div> */}
                    {/* <div className='form-field-label-grey'>View Count</div> */}
                    {/* <div className="form-field-text-label">{mediaDetailSuccess.data.mediaViewCount}</div> */}
                    {mediaDetailSuccess.data.mediaStatus === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED && <div>
                        <div className='form-field-label-grey'>Reward Points</div>
                        <div className="form-field-text-label">{mediaDetailSuccess.data.points}</div>
                        {/* <div className='form-field-label-grey'>Cashback Amount</div>
                        <div className="form-field-text-label">{getAmountWithCurrency(mediaDetailSuccess.data, mediaDetailSuccess.data.cashbackAmount)}</div> */}
                    </div>}

                    <div className='form-field-label-grey'>Media Link Status</div>
                    <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(mediaDetailSuccess.data.mediaStatus)}</div>
                    {!isEmpty(mediaDetailSuccess.data.comments) && <div>
                        <FormFieldLabel label='Comments' />
                        <div>
                            {mediaDetailSuccess.data.comments.map(e => (
                                <div className="cp-cashback-row-container">
                                    <div className="cp-country-label">{removeSpecialCharAndChangeToPascal(e.sender)}</div>
                                    <div className="cp-name-label">{e.comment}</div>
                                </div>
                            ))}
                        </div>
                        {/* <div className="form-field-text-label">{mediaDetailSuccess.data.comments}</div> */}
                    </div>}
                    {!isEmpty(mediaDetailSuccess.data.cancellationReason) && <div>
                        <FormFieldLabel label='Reason' />
                        <div className="form-field-text-label">{removeSpecialCharAndChangeToPascal(mediaDetailSuccess.data.cancellationReason)}</div>
                    </div>}
                    <div className='form-field-label-grey'>Uploaded Attachments</div>
                    <div className="id-receipt-img-container" onClick={() => handleViewReceiptClick(mediaDetailSuccess.data)}>
                        <img src={require('../../../assets/ic_file_icon.png')} className="img" />
                    </div>
                    <FormFieldLabel className='up-bold-label mt-4' label='Promotion Details' />
                    <div className='form-field-label-grey'>Promotion Name</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.schemeDetail.schemeName}</div>
                    <div className='form-field-label-grey'>Brand Name</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.schemeDetail.brand}</div>
                    <div className='form-field-label-grey'>Required {mediaDetailSuccess.data.schemeDetail.countType ? removeSpecialCharAndChangeToPascal(mediaDetailSuccess.data.schemeDetail.countType) : '-'}</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.schemeDetail.viewsCount}</div>
                    <div className='form-field-label-grey'>Points</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.schemeDetail.points}</div>
                    <div className='form-field-label-grey'>Promotion Date</div>
                    <div className="form-field-text-label">Valid from : {moment(mediaDetailSuccess.data.schemeDetail.startDate).utc().format('DD MMM YYYY')} to {moment(mediaDetailSuccess.data.schemeDetail.endDate).utc().format('DD MMM YYYY')}</div>
                    {!isEmpty(mediaDetailSuccess.data.schemeDetail.areaName) && <>
                        <div className='form-field-label-grey'>Promotion Area Name</div>
                        <div className="form-field-text-label">{mediaDetailSuccess.data.schemeDetail.areaName}</div>
                    </>}
                    <div className='form-field-label-grey'>Promotion Location</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.schemeDetail.city}, {mediaDetailSuccess.data.schemeDetail.state}, {mediaDetailSuccess.data.schemeDetail.country}</div>
                    <FormFieldLabel className='up-bold-label mt-4' label='Vendor Details' />
                    <div className='form-field-label-grey'>Business Name</div>
                    <div className="form-field-text-label">{mediaDetailSuccess.data.businessDetail.businessName}</div>
                    {displayButtonRow() && <ButtonRow>
                        {displayUpdateStatusButton() && <CommonButton label='Update Status'
                            onClick={() => {
                                setDisplayUpdateStatusModal(true)
                            }}
                        />}
                        {displayAddCommentButton() && <CommonButton label='Add Comment'
                            onClick={() => {
                                setDisplayAddCommentModal(true)
                            }}
                        />}
                        {displaySubmitButton() && <CommonButton label='Earn Reward'
                            onClick={() => {
                                handleEarnRewardClick()
                            }}
                        />}
                    </ButtonRow>}
                </CommonFormBorder>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setDisplayUpdateStatusModal(false) }}
                show={displayUpdateStatusModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Media Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={receiptStatusDropdown}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={VENDOR_RECEIPT_STATUS}
                        placeholder='Select Status'
                        onChange={e => {
                            setReceiptStatusDropdown(e)
                            if (e.key === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED) {
                                setDisplayCashbackInput(true)
                            } else {
                                setDisplayCashbackInput(false)
                            }
                            if (e.key === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR ||
                                e.key === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED ||
                                e.key === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED) {
                                setDisplayReasonInput(true)
                            } else {
                                setDisplayReasonInput(false)
                            }
                            if (e.key === APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED) {
                                setDisplayCommentsInput(true)
                            } else {
                                setDisplayCommentsInput(false)
                            }
                        }} />
                    {displayCommentsInput && <CommonInputFloatFull
                        type='text'
                        id='commentsInput'
                        onChange={e => setComments(e.target.value)}
                        value={comments}
                        placeholder='Comments'
                    />}
                    {displayReasonInput && <CommonInputFloatFull
                        type='text'
                        id='reasonInput'
                        onChange={e => setReasonInput(e.target.value)}
                        value={reasonInput}
                        placeholder='Enter Reason'
                    />}
                    {displayCashbackInput && <CommonInputFloatFull
                        type='number'
                        id='points'
                        onChange={e => setPointsInput(e.target.value)}
                        value={pointsInput}
                        placeholder='Points'
                    />}
                    {/* {displayCashbackInput && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={currencyDropdown}
                        getOptionLabel={e => `${e.symbol} (${e.code})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setCurrencyDropdown(e)
                        }} />}
                    {displayCashbackInput && <CommonInputFloatFull
                        type='number'
                        id='cahbackInput'
                        onChange={e => setCashbackInput(e.target.value)}
                        value={cashbackInput}
                        placeholder='Cashback Amount'
                    />} */}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleUpdateStatusSubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setDisplayAddCommentModal(false) }}
                show={displayAddCommentModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Comment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFloatFull
                        type='text'
                        id='commentsInput'
                        onChange={e => setComments(e.target.value)}
                        value={comments}
                        placeholder='Comments'
                    />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit'
                        onClick={() => { handleAddCommentSubmitClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setDisplayReceiptModal(false) }}
                show={displayReceiptModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {!isEmpty(receiptImagePath) && <div>
                        <img src={receiptImagePath} className="img" />
                    </div>}
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default MediaDetail