import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import TopButton from "../../../components/button/top-button/topButton";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { schemeListRequest } from '../../../redux/scheme/scheme.action'
import { mediaListRequest } from '../../../redux/media/media.action'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { convertToCamelcaseWithSpecialChar, displayCurrencySymbolLeft, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import Select from 'react-select'
import { useLocation } from 'react-router-dom';

const MediaList = props => {
    const FETCH_MEDIA_TYPE = {
        BY_SCHEME: 'BY_SCHEME',
        BY_COUNTRY: 'BY_COUNTRY',
        BY_STATE: 'BY_STATE',
        BY_CITY: 'BY_CITY'
    }
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { mediaListSuccess, mediaListError } = useSelector(state => state.media)
    const { schemeListSuccess, schemeListError, commonError } = useSelector((state) => state.scheme)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [schemeDropdown, setSchemeDropdown] = useState()
    const [displayMediaFilter, setDisplayMediaFilter] = useState(false)
    const [filteredMediaList, setFilteredMediaList] = useState([])
    const [mediaFilterDropdown, setMediaFilterDropdown] = useState()
    const [fetchMediaByType, setFetchMediaType] = useState('')
    const { state } = useLocation()
    const VENDOR_MEDIA_FILTER_LIST = [
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED) }
    ]

    const ALL_MEDIA_FILTER_LIST = [
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DRAFT, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DRAFT) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.PENDING) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CANCELLED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.DISPUTED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.ERROR) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.COMPLETED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.COMPLETED) },
        { key: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED, value: removeSpecialCharAndChangeToPascal(APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.CUSTOMER_ACTION_REQUIRED) }
    ]

    useEffect(() => {
        if (isLoggedInUserCustomer(user)) {
            setDisplayMediaFilter(true)
        }
        if (isLoggedInUserVendor(user) && !isNull(user.data.userDetails.businessDetails)) {
            setLoading(true)
            const data = {
                businessId: Number(user.data.userDetails.businessDetails.businessId)
            }
            dispatch(schemeListRequest(data))
        }
        if (isLoggedInUserOperator(user) && !isEmpty(state)) {
            setLoading(true)
            const data = {
                businessId: Number(state)
            }
            dispatch(schemeListRequest(data))
        }
    }, [])

    const fetchMediaList = (country = null, state = null, city = null, schemeId = null) => {
        const data = {}
        if (!isNull(mediaFilterDropdown)) {
            data.customerVideoStatus = mediaFilterDropdown.key
        }
        if (isLoggedInUserCustomer(user)) {
            data.customerId = user.data.userDetails.customerId
        }
        if (isLoggedInUserVendor(user)) {
            data.schemeId = schemeId
        }
        if (isLoggedInUserOperator(user) && !isEmpty(state)) {
            data.schemeId = schemeId
        }
        setLoading(true)
        dispatch(mediaListRequest(data))

    }

    useEffect(() => {
        if (!isNull(mediaListSuccess)) {
            setLoading(false)
            if (!isEmpty(mediaListSuccess.data)) {
                setFilteredMediaList(mediaListSuccess.data)
            } else {
                setFilteredMediaList([])
            }
        } else if (!isNull(mediaListError)) {
            setLoading(false)
            setFilteredMediaList([])
        }
    }, [mediaListSuccess, mediaListError])

    useEffect(() => {
        if (!isNull(schemeListSuccess)) {
            setLoading(false)

        } else if (!isNull(schemeListError)) {
            setLoading(false)
        }
    }, [schemeListSuccess, schemeListError])

    const handleUploadMedia = () => {
        navigate(ROUTES.uploadMedia)
    }

    const handleCardClick = (element) => {
        navigate(ROUTES.mediaDetail, { state: element.customerMediaId })
    }

    useEffect(() => {
        if (isLoggedInUserCustomer(user) === false) {
            if (!isNull(schemeDropdown)) {
                setDisplayMediaFilter(true)
            } else {
                setDisplayMediaFilter(false)
            }
        }
    }, [schemeDropdown])

    useEffect(() => {
        if (!isNull(mediaFilterDropdown)) {
            setFilteredMediaList([])
            if (isLoggedInUserCustomer(user)) {
                fetchMediaList()
            } else {
                if (fetchMediaByType === FETCH_MEDIA_TYPE.BY_SCHEME) {
                    fetchMediaList(null, null, null, schemeDropdown.schemeId)
                } else if (fetchMediaByType === FETCH_MEDIA_TYPE.BY_COUNTRY) {
                    fetchMediaList(countryDropdown.country)
                } else if (fetchMediaByType === FETCH_MEDIA_TYPE.BY_STATE) {
                    fetchMediaList(countryDropdown.country, stateDropdown.state)
                } else if (fetchMediaByType === FETCH_MEDIA_TYPE.BY_CITY) {
                    fetchMediaList(countryDropdown.country, stateDropdown.state, cityDropdown.value)
                }
            }
        }
    }, [mediaFilterDropdown])

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Social Media Links' />
                    {isLoggedInUserCustomer(user) && <TopButton label='Share Media Link'
                        onClick={() => handleUploadMedia()} />}
                </CommonInputRow>
                {(isLoggedInUserVendor(user) || isLoggedInUserOperator(user)) && <div>
                    <FormFieldLabel className='up-bold-label' label='Select Promotion' />
                    {!isEmpty(schemeListSuccess) &&
                        <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={schemeDropdown}
                            getOptionLabel={e => e.schemeName}
                            getOptionValue={e => e}
                            options={schemeListSuccess.data}
                            placeholder='Select Promotion'
                            onChange={e => {
                                setSchemeDropdown(e)
                                setDisplayMediaFilter(true)
                                setFetchMediaType(FETCH_MEDIA_TYPE.BY_SCHEME)
                                // setFilteredMediaList([])
                                // fetchMediaList(null, null, null, e.offerId)
                            }} />
                    }
                    {!isEmpty(schemeListError) && <div>No promotion available</div>}
                </div>}
                {displayMediaFilter && <div>
                    <FormFieldLabel className='up-bold-label' label='Select Link Status' />
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={mediaFilterDropdown}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e}
                        options={isLoggedInUserVendor(user) ? VENDOR_MEDIA_FILTER_LIST : ALL_MEDIA_FILTER_LIST}
                        placeholder='Select Link Status'
                        onChange={e => {
                            setMediaFilterDropdown(e)
                        }} />
                </div>}
                <div className="table-scroll-container">
                    {mediaListSuccess && !isEmpty(filteredMediaList) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Link Id</th>
                                {/* <th scope="col" className="rl-table-col-title text-center">Type</th> */}
                                {/* <th scope="col" className="rl-table-col-title text-center">Platform</th> */}
                                {/* <th scope="col" className="rl-table-col-title text-center">Views</th> */}
                                <th scope="col" className="rl-table-col-title text-center">Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Promotion</th>
                                <th scope="col" className="rl-table-col-title text-center">End Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Vendor Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredMediaList.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.customerMediaId}</td>
                                    {/* <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.mediaType)}</td> */}
                                    {/* <td className="rl-table-col-item text-center">{element.mediaPlatform}</td> */}
                                    {/* <td className="rl-table-col-item text-center">{element.mediaViewCount}</td> */}
                                    <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.mediaStatus)}</td>
                                    <td className="rl-table-col-item text-center">{element.schemeDetail.schemeName}</td>
                                    <td className="rl-table-col-item text-center">{moment(element.schemeDetail.endDate).utc().format('DD MMM YYYY')}</td>
                                    <td className='rl-table-col-item text-center'>{element.businessDetail.businessName}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>View Details</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>
                {!isNull(mediaListError) && <div className="error-text-container">{mediaListError.message}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )

}
export default MediaList