import React from "react";
import './styles.css'

const CustomSearchBar = ({ query, queryChange, clickListener, placeholder }) => {
    return (
        <div className="csb-input-container">
            <input type="text" value={query} onChange={queryChange} placeholder={placeholder} className="csb-bordered-input" />
            <div className="csb-image-container" onClick={clickListener}>
                <img src={require('../../assets/ic_search_purple.png')} className="img" />
            </div>
        </div>
    )
}

export default CustomSearchBar