import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContentCenter from "../../../components/screen/common-screen-content-center/commonScreenContentCenter";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from "../../navigation-bar/navigationBar";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import { customerLoginRequest, displaySideBarAct } from '../../../redux/user/user.action'
import { useDispatch, useSelector } from 'react-redux'
import { APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";

const CustomerLogin = props => {
    const [isLoading, setLoading] = useState(false)
    const [employerName, setEmployerName] = useState('')
    const [mobile, setMobile] = useState()
    let navigate = useNavigate();
    const { user, isLogin, loginErrorData } = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast('Login Successful')
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, JSON.stringify(user.data.authToken))
            dispatch(displaySideBarAct(true))
            navigate(ROUTES.promotionList, { replace: true })
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleLoginClick = () => {
        if (isNull(mobile)) {
            displayErrorToast('Mobile number cannot be blank')
        } else {
            const mobileWithPlusSymbol = mobile.replace('+', "")
            const data = {
                mobile: parseInt(mobileWithPlusSymbol)
            }
            // if (!isEmpty(employerName)) {
            //     data.employerName = employerName
            // }
            setLoading(true)
            dispatch(customerLoginRequest(data))
        }
    }

    return (
        <>
            <NavigationBar navigate={navigate} />
            <CommonScreenContentCenter>
                <CommonFormBorder>
                    <div className="cl-logo-container">
                        <img src={require('../../../assets/logo_new.png')} className="img" />
                    </div>
                    <div className="cl-welcome-label">Welcome!</div>
                    <div className="cl-sub-label">Continue with your mobile number</div>
                    <div className="cl-input">
                        <PhoneInput
                            placeholder="Mobile Number"
                            value={mobile}
                            defaultCountry="US"
                            onChange={setMobile} />
                    </div>
                    {/* <CommonInputFloatFull
                        type='text'
                        id='employerName'
                        onChange={e => setEmployerName(e.target.value)}
                        placeholder='Employer Name'
                        value={employerName} /> */}
                    <ButtonRow>
                        <CommonButton label='Login'
                            onClick={() => { handleLoginClick() }} />
                    </ButtonRow>

                </CommonFormBorder>
            </CommonScreenContentCenter>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CustomerLogin