import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Select from 'react-select'
import moment from "moment";
import { schemeListRequest } from '../../../redux/scheme/scheme.action'
import { invoiceListRequest } from '../../../redux/invoice/invoice.action'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { displayCurrencySymbolLeft, isEmpty, isLoggedInUserCustomer, isLoggedInUserOperator, isLoggedInUserVendor, isNull, removeSpecialCharAndChangeToPascal } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import { ROUTES } from "../../../utils/AppConstants";
import TopButton from "../../../components/button/top-button/topButton";

const InvoiceList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { schemeListSuccess, schemeListError, commonError } = useSelector((state) => state.scheme)
    const { invoiceListSuccess, invoiceListError } = useSelector((state) => state.invoice)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [offerDropdown, setOfferDropdown] = useState()

    useEffect(() => {
        if (isLoggedInUserVendor(user)) {
            fetchSchemeList()
        }
    }, [])

    const fetchSchemeList = (country = null, state = null, city = null) => {
        setLoading(true)
        const data = {}
        if (isLoggedInUserOperator(user)) {
            if (!isEmpty(country)) {
                data.country = country
            }
            if (!isEmpty(state)) {
                data.state = state
            }
            if (!isEmpty(city)) {
                data.city = city
            }
        }
        if (isLoggedInUserVendor(user)) {
            data.businessId = Number(user.data.userDetails.businessDetails.businessId)
        }
        dispatch(schemeListRequest(data))
    }

    const fetchInvoiceList = (schemeId) => {
        setLoading(true);
        const data = {
            schemeId: schemeId
        }
        dispatch(invoiceListRequest(data))
    }

    useEffect(() => {
        if (!isNull(schemeListSuccess)) {
            setLoading(false)
        } else if (!isNull(schemeListError)) {
            setLoading(false)
        }
    }, [schemeListSuccess, schemeListError])

    useEffect(() => {
        if (!isNull(invoiceListSuccess)) {
            setLoading(false)
        } else if (!isNull(invoiceListError)) {
            setLoading(false)
        }
    }, [invoiceListSuccess, invoiceListError])

    const handleCardClick = element => {
        navigate(ROUTES.invoiceDetail, { state: element.invoiceId })
    }

    const displayOfferListToOperator = () => {
        if (isEmpty(user.data.userDetails.accessLocations)) {
            return false
        }
        if (isEmpty(countryDropdown)) {
            return false
        }
        if (!isEmpty(countryDropdown) && !isEmpty(countryDropdown?.stateCity) && isEmpty(stateDropdown)) {
            return false
        }
        if (!isEmpty(countryDropdown) && !isEmpty(countryDropdown?.stateCity) && !isEmpty(stateDropdown) && !isEmpty(stateDropdown?.city) && isEmpty(cityDropdown)) {
            return false
        }

        return true
        // return (
        //     !isEmpty(user.data.userDetails.accessLocations) &&
        //     !isEmpty(countryDropdown) &&
        //     (!isEmpty(countryDropdown?.stateCity) || !isEmpty(stateDropdown?.city))
        // );
    }

    const handleNewInvoiceClick = () => {
        navigate(ROUTES.createInvoice)
    }

    const getAmountWithCurrency = (e, amount) => {
        return (displayCurrencySymbolLeft(e.currencyRegion) ?
            <span>
                {e.currencySymbol} {amount}
            </span>
            : <span>
                {amount} {e.currencySymbol}
            </span>
        )
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    <ScreenLabel label='Invoices' />
                    {isLoggedInUserOperator(user) && <TopButton label='New Invoice'
                        onClick={() => { handleNewInvoiceClick() }} />}
                </CommonInputRow>

                {isLoggedInUserOperator(user) && <div>
                    <FormFieldLabel className='up-bold-label' label='Select Location' />
                    {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                    {!isEmpty(user.data.userDetails.accessLocations) &&
                        <Select
                            menuPlacement="auto"
                            className='select-full-width-no-border'
                            value={countryDropdown}
                            getOptionLabel={e => e.country}
                            getOptionValue={e => e}
                            options={user.data.userDetails.accessLocations}
                            placeholder='Select Country'
                            onChange={e => {
                                setCountryDropdown(e)
                                if (isEmpty(e.stateCity)) {
                                    fetchSchemeList(e.country)
                                }
                            }} />
                    }
                    {!isEmpty(user.data.userDetails.accessLocations) && !isNull(countryDropdown) &&
                        !isEmpty(countryDropdown?.stateCity) &&
                        <CommonInputRow>
                            <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={stateDropdown}
                                getOptionLabel={e => e.state}
                                getOptionValue={e => e}
                                options={countryDropdown.stateCity}
                                placeholder='Select State'
                                onChange={e => {
                                    setStateDropdown(e)
                                    if (isEmpty(e.city)) {
                                        fetchSchemeList(countryDropdown.country, e.state)
                                    }
                                }} />
                            {!isEmpty(stateDropdown?.city) && <Select
                                menuPlacement="auto"
                                className='select-half-width'
                                value={cityDropdown}
                                getOptionLabel={e => e.value}
                                getOptionValue={e => e}
                                options={stateDropdown.city.map(city => {
                                    return { key: city, value: city };
                                })}
                                placeholder='Select City'
                                onChange={e => {
                                    setCityDropdown(e)
                                    fetchSchemeList(countryDropdown.country, stateDropdown.state, e.value)
                                }} />}
                        </CommonInputRow>}
                </div>}

                {(isLoggedInUserCustomer(user) || isLoggedInUserVendor(user)) &&
                    <div>
                        {!isEmpty(schemeListSuccess) &&
                            <div>
                                <FormFieldLabel className='up-bold-label' label='Select Scheme' />
                                <Select
                                    menuPlacement="auto"
                                    className='select-full-width-no-border'
                                    value={offerDropdown}
                                    getOptionLabel={e => e.schemeName}
                                    getOptionValue={e => e}
                                    options={schemeListSuccess.data}
                                    placeholder='Select Scheme'
                                    onChange={e => {
                                        setOfferDropdown(e)
                                        fetchInvoiceList(e.schemeId)
                                    }} />
                            </div>
                        }
                        {!isNull(schemeListError) && <div className="error-text-container">{schemeListError.message}</div>}
                    </div>

                }
                {isLoggedInUserOperator(user) && displayOfferListToOperator() &&
                    <div>
                        {!isEmpty(schemeListSuccess) &&
                            <div>
                                <FormFieldLabel className='up-bold-label' label='Select Scheme' />
                                <Select
                                    menuPlacement="auto"
                                    className='select-full-width-no-border'
                                    value={offerDropdown}
                                    getOptionLabel={e => e.schemeName}
                                    getOptionValue={e => e}
                                    options={schemeListSuccess.data}
                                    placeholder='Select Scheme'
                                    onChange={e => {
                                        setOfferDropdown(e)
                                        fetchInvoiceList(e.schemeId)
                                    }} />
                            </div>}
                        {!isNull(schemeListError) && <div className="error-text-container">{schemeListError.message}</div>}

                    </div>
                }
                {!isNull(invoiceListSuccess) && <div className="table-scroll-container">
                    {!isNull(invoiceListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Invoice Number</th>
                                <th scope="col" className="rl-table-col-title text-center">Invoice Amount</th>
                                <th scope="col" className="rl-table-col-title text-center">Payment Status</th>
                                <th scope="col" className="rl-table-col-title text-center">Payment Mode</th>
                                {isLoggedInUserOperator(user) && <th scope="col" className="rl-table-col-title text-center">Vendor</th>}
                                {isLoggedInUserVendor(user) && <th scope="col" className="rl-table-col-title text-center">Operator</th>}
                                <th scope="col" className="rl-table-col-title text-center">Invoice Date</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.invoiceId}</td>
                                    <td className="rl-table-col-item text-center">{getAmountWithCurrency(element, element.finalAmount)}</td>
                                    <td className="rl-table-col-item text-center">{removeSpecialCharAndChangeToPascal(element.paymentStatus)}</td>
                                    <td className="rl-table-col-item text-center">{element.paymentMode}</td>
                                    {isLoggedInUserOperator(user) && <td className='rl-table-col-item text-center'>{element.businessDetail.businessName}</td>}
                                    {isLoggedInUserVendor(user) && <td className='rl-table-col-item text-center'>{element.operatorDetail.firstName} {element.operatorDetail.lastName}</td>}
                                    <td className="rl-table-col-item text-center">{moment(element.createdOn).utc().format('DD MMM YYYY')}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>View Details</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isNull(invoiceListError) && <div className="error-text-container">{invoiceListError.message}</div>}
            </CommonScreenContent >
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )

}
export default InvoiceList