import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import CommonScreenContent from "../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../components/common-form-border/commonFormBorder";
import { toPng } from "html-to-image";
import download from "downloadjs";
import QRCode from 'qrcode.react';
import Input from '@mui/material/Input';
import { config } from "../../config";
import { displayErrorToast } from "../../utils/Utils";
import CommonInputFloatFull from "../../components/input/common-input-float-full/commonInputFloatFull";
import { ROUTES } from "../../utils/AppConstants";
import ButtonRow from "../../components/button/button-row/buttonRow";
import CommonButton from "../../components/button/common-button/commonButton";

const QrCode = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [qrCodeUrl, setQRCodeUrl] = useState('')
    const [customMessage, setCustomMessage] = useState('')
    const svgRef = useRef(null);
    const [qrSize, setQrSize] = useState(256)
    const [qrImgSize, setQrImgSize] = useState({ width: 35, height: 17.5 })
    const [labelSize, setLabelSize] = useState(14)
    const [customTextSize, setCustomTextSize] = useState(28)
    const ariaLabel = { 'aria-label': 'description' };

    useEffect(() => {
        setQrSize(256)
        setQrImgSize({ width: 35, height: 17.5 })
        setLabelSize(14)
        setCustomTextSize(28)
        let url = `${config.webAppUrl}/${ROUTES.promotionList}?vendor=${user.data.userDetails.businessDetails.businessId}`
        console.log('url', url)
        setQRCodeUrl(url)
        setCustomMessage('')
    }, [])

    useEffect(() => {
        if (qrSize < 38) {
            displayErrorToast('QR Code size cannot be less than 38 pixels')
        }
        let newQrSize = qrSize < 38 ? 38 : qrSize
        let percentChange = Math.round((newQrSize - 38) / 38)
        let newWidth = 5 + (5 * percentChange)
        let newHeight = 2.5 + (2.5 * percentChange)
        let newLabelSize = 4 + (4 * percentChange)
        let newCustomTextSize = 8 + (8 * percentChange)
        setQrImgSize({ width: newWidth, height: newHeight })
        setLabelSize(newLabelSize / 2)
        setCustomTextSize(newCustomTextSize / 2)
    }, [qrSize])

    const downloadQR = () => {
        const width = svgRef.current.offsetWidth;
        toPng(svgRef.current, { width })
            .then(function (dataUrl) {
                download(dataUrl, "qr-code.png");
            })
            .catch(function (error) {
                console.error("Oops, something went wrong!", error);
            });
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='QR Code' />
                <CommonFormBorder>
                    <Input className="mt-2 mb-2"
                        onChange={t => setCustomMessage(t.target.value)}
                        value={customMessage}
                        placeholder="Type Custom Message"
                        inputProps={ariaLabel}
                        fullWidth />
                    <div id="qrCode" className="qr-container" ref={svgRef}>
                        <div className="cqc-qr-container">
                            <QRCode value={qrCodeUrl} size={qrSize < 38 ? 38 : qrSize}
                                renderAs='svg'
                                imageSettings={{
                                    src: require('../../assets/logo_new.png'),
                                    height: qrImgSize.height,
                                    width: qrImgSize.width,
                                    excavate: true
                                }}

                            />
                            <div className="cqc-powered-label" style={{ fontSize: labelSize }}>Powered By MintBrand.Buzz</div>
                            <div className="qr-code-footer">
                                <div className="qr-footer-text" style={{ fontSize: customTextSize }}>{customMessage}</div>
                            </div>
                        </div>
                    </div>
                    <CommonInputFloatFull
                        onChange={t => setQrSize(t.target.value)}
                        placeholder='Desired QR Code Size (In pixels)'
                        type='number'
                        value={qrSize}
                        isSubtext={true}
                        subText='Minimum size should be 38px (1cm)' />
                    <ButtonRow>
                        <CommonButton label='Download QR Code' onClick={() => { downloadQR() }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default QrCode