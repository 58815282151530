import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { addSchemeRequest, addSchemeRequestStatus } from '../../../redux/scheme/scheme.action'
import Select from 'react-select'
import { countryListRequest, stateListRequest, cityListRequest } from '../../../redux/user/user.action'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isNull } from "../../../utils/Utils";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputFloatHalf from "../../../components/input/common-input-float-half/commonInputFloafHalf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { CurrencyLabel } from "../../../utils/CurrencyLabel";
import { CATEGORIES } from "../../../utils/Categories";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import { LINK_COUNT_TYPE } from "../../../utils/MediaType";

const AddScheme = props => {
    const [isLoading, setLoading] = useState(false)
    const { addSchemeSuccess, addSchemeError, addSchemeReqStatus, commonError } = useSelector((state) => state.scheme)
    const { user, countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [schemeName, setSchemeName] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [points, setPoints] = useState('')
    const [viewCount, setViewCount] = useState('')
    const [brandName, setBrandName] = useState('')
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [categoryDropdown, setCategoryDropdown] = useState()
    const schemeImageRef = useRef(null)
    const [countTypeDropdown, setCountTypeDropdown] = useState()
    const [areaName, setAreaName] = useState('')

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
    }, [])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    useEffect(() => {
        if (addSchemeReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addSchemeSuccess.message)
            clearFields()
        } else if (addSchemeReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addSchemeError.message)
        }
        if (addSchemeReqStatus.length) {
            dispatch(addSchemeRequestStatus(''))
        }
    }, [addSchemeSuccess, addSchemeError, addSchemeReqStatus])

    const clearFields = () => {
        setSchemeName('')
        setStartDate(new Date())
        setEndDate(new Date())
        setPoints('')
        setViewCount('')
        setBrandName('')
        setCountryDropdown();
        setStateDropdown();
        setCityDropdown();
        setCountryCode('')
        setImagePath(null)
        setImageName('')
        setAreaName('')
        if (schemeImageRef.current) {
            schemeImageRef.current.value = null
        }
    }

    const handleSubmitClick = () => {
        if (isEmpty(schemeName)) {
            displayErrorToast('Promotion name cannot be blank')
        } else if (isEmpty(brandName)) {
            displayErrorToast('Promotion brand name cannot be blank')
        } else if (isNull(categoryDropdown)) {
            displayErrorToast('Promotion category not selected')
        } else if (isEmpty(startDate)) {
            displayErrorToast('Start date not selected')
        } else if (isEmpty(endDate)) {
            displayErrorToast('End date cannot not selected')
        } else if (isEmpty(points)) {
            displayErrorToast('Points cannot be blank')
        } else if (Number(points) < 0) {
            displayErrorToast('Points cannot be less than 0')
        } else if (isEmpty(viewCount)) {
            displayErrorToast('Count?Number cannot be blank')
        } else if (Number(viewCount) < 0) {
            displayErrorToast('Count/Number cannot be less than 0')
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Promotion country not selected')
        } else if (isNull(countTypeDropdown)) {
            displayErrorToast('Count/Number type not selected')
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Promotion country state not selected')
        } else if (isEmpty(areaName)) {
            displayErrorToast('Area name cannot be blank')
        } else {
            let fd = new FormData()
            fd.append('businessId', user.data.userDetails.businessDetails.businessId)
            fd.append('schemeName', schemeName)
            fd.append('brand', brandName)
            fd.append('categoryId', categoryDropdown.categoryId)
            fd.append('categoryKey', categoryDropdown.categoryKey)
            fd.append('startDate', moment(startDate).utc().format("YYYY-MM-DD hh:mm:ss A"))
            fd.append('endDate', moment(endDate).utc().format("YYYY-MM-DD hh:mm:ss A"))
            fd.append('viewsCount', viewCount)
            fd.append('countType', countTypeDropdown.key)
            fd.append('points', points)
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('areaName', areaName)
            fd.append('country', countryDropdown.name)
            if (!isNull(stateDropdown))
                fd.append('state', stateDropdown.name)
            if (!isNull(cityDropdown))
                fd.append('city', cityDropdown.name)
            if (!isNull(imagePath) && !isEmpty(imageName))
                fd.append('image', imagePath, imageName)
            setLoading(true)
            dispatch(addSchemeRequest(fd))
        }
    }

    const handleImageChange = event => {
        setImagePath(event.target.files[0])
        setImageName(event.target.files[0].name)
    }

    useEffect(() => {
        if (!isNull(categoryDropdown)) {
            console.log('category', categoryDropdown)
        }
    }, [categoryDropdown])

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Add Promotion' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter details to add new promotion' />
                    <CommonInputFloatFull
                        type='text'
                        id='schemeName'
                        onChange={e => setSchemeName(e.target.value)}
                        placeholder='Promotion Name'
                        value={schemeName}
                    />
                    <CommonInputFloatFull
                        type='text'
                        id='brand'
                        onChange={e => setBrandName(e.target.value)}
                        placeholder='Brand Name'
                        value={brandName}
                    />
                    <FormFieldLabel label='Select Category' />
                    {CATEGORIES && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={categoryDropdown}
                        getOptionLabel={e => `${e.name}`}
                        getOptionValue={e => e}
                        options={CATEGORIES}
                        placeholder='Select Category'
                        onChange={e => {
                            setCategoryDropdown(e)
                        }}
                    />}
                    <CommonInputRow>
                        <div className="as-date-container">
                            <FormFieldLabel label='Start Date' />
                            <DatePicker
                                className="as-date-picker"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect={false}
                            />
                        </div>
                        <div className="as-date-container">
                            <FormFieldLabel label='End Date' />
                            <DatePicker
                                minDate={startDate}
                                className="as-date-picker"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                showTimeSelect={false}
                            />
                        </div>
                    </CommonInputRow>
                    <FormFieldLabel className='up-bold-label' label='Promotion Information' />
                    <CommonInputRow>
                        <CommonInputFloatHalf
                            type='number'
                            id='points'
                            onChange={e => setPoints(e.target.value)}
                            placeholder='Reward Points'
                            value={points}
                        />
                        <CommonInputFloatHalf
                            type='number'
                            id='viewCount'
                            onChange={e => setViewCount(e.target.value)}
                            placeholder='Count/Number'
                            value={viewCount}
                        />
                    </CommonInputRow>
                    <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countTypeDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={LINK_COUNT_TYPE}
                        placeholder='Select Count/Number Type'
                        onChange={e => {
                            setCountTypeDropdown(e)
                        }} />
                    <FormFieldLabel className='up-bold-label' label='Select Promotion Location' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow className='margin-bottom-10'>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                            }} />}
                    </CommonInputRow>
                    <CommonInputFloatFull
                        type='text'
                        id='areaName'
                        onChange={e => setAreaName(e.target.value)}
                        placeholder='Area Name'
                        value={areaName}
                    />
                    <FormFieldLabel className='up-bold-label' label='Select Promotion Image' />
                    <div className="input-group">
                        <input type="file" ref={schemeImageRef} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>

                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => handleSubmitClick()} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default AddScheme