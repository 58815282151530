import React, { useEffect, useState } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { useDispatch, useSelector } from 'react-redux'
import { pointsRewardListRequest, redeemPointsRequest, redeemPointsRequestStatus } from '../../../redux/points-reward/pointsReward.action'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import {
    displayErrorToast, displaySuccessToast, isEmpty,
    isLoggedInUserVendor, isNull
} from "../../../utils/Utils";
import Modal from 'react-bootstrap/Modal';
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonInputFloatFull from "../../../components/input/common-input-float-full/commonInputFloatFull";
import CommonButton from "../../../components/button/common-button/commonButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const PointsRewardList = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { pointsRewardListSuccess, pointsRewardListError,
        redeemPointsSuccess, redeemPointsError, redeemPointsReqStatus } = useSelector(state => state.pointsReward)
    const dispatch = useDispatch()
    const [points, setPoints] = useState('')
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [showRedeemModal, setShowRedeemModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [showFilteredList, setShowFilteredList] = useState(false)
    const [filteredList, setFilteredList] = useState([])

    useEffect(() => {
        if (isLoggedInUserVendor(user)) {
            fetchPointsRewardList()
        }
    }, [])

    const fetchPointsRewardList = () => {
        setLoading(true)
        const data = {
            businessId: Number(user.data.userDetails.businessDetails.businessId)
        }
        dispatch(pointsRewardListRequest(data))
    }

    useEffect(() => {
        if (!isNull(pointsRewardListSuccess)) {
            setLoading(false)
        } else if (!isNull(pointsRewardListError)) {
            setLoading(false)
        }
    }, [pointsRewardListSuccess, pointsRewardListError])

    useEffect(() => {
        if (redeemPointsReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(redeemPointsSuccess.message)
            fetchPointsRewardList()
        } else if (redeemPointsReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(redeemPointsError.message)
        }
        if (redeemPointsReqStatus?.length) {
            dispatch(redeemPointsRequestStatus(''))
        }
    }, [redeemPointsSuccess, redeemPointsError, redeemPointsReqStatus])

    const handleCardClick = item => {
        if (!isNull(item)) {
            setPoints('')
            setSelectedRecord(item)
            setShowRedeemModal(true)
        } else {
            displayErrorToast('Points reward information is not available')
        }
    }

    const handleRedeemClick = () => {
        if (isNull(selectedRecord)) {
            displayErrorToast('Point reward information is not available. Please try again')
        } else if (isEmpty(points)) {
            displayErrorToast('Points to redeem cannot be blank')
        } else if (parseFloat(points) <= 0) {
            displayErrorToast('Invalid points entered')
        } else {
            setLoading(true)
            const data = {
                pointsRewardId: selectedRecord.pointsRewardId,
                businessId: Number(user.data.userDetails.businessDetails.businessId),
                customerId: selectedRecord.customerId,
                points: points
            }
            dispatch(redeemPointsRequest(data))
            setShowRedeemModal(false)
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSearchClick = () => {
        if (isEmpty(searchQuery)) {
            displayErrorToast('Please enter number to search')
        } else if (searchQuery.length <= 5) {
            displayErrorToast('Please enter atleast 5 digits')
        } else if (isNull(pointsRewardListSuccess)) {
            displayErrorToast('No record found')
        } else {
            let filteredData = pointsRewardListSuccess.data.filter(e => e.customerDetail.mobile.toString().indexOf(`${searchQuery}`) > -1)
            if (!isEmpty(filteredData)) {
                setFilteredList(filteredData)
                setShowFilteredList(true)
            } else {
                displayErrorToast('No record found')
            }
        }
    }

    useEffect(() => {
        if (isEmpty(searchQuery)) {
            setShowFilteredList(false)
        }
    }, [searchQuery])

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Points Reward' />
                <div className="offer-filter-container">
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Search with Number</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type='number'
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={e => handleSearchClick()}
                                        edge="end"
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search with Number"
                        />
                    </FormControl>
                </div>
                {!isNull(pointsRewardListSuccess) && <div className="table-scroll-container">
                    {showFilteredList === false && !isNull(pointsRewardListSuccess.data) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Number</th>
                                <th scope="col" className="rl-table-col-title text-center">Points</th>
                                <th scope="col" className="rl-table-col-title text-center">Business Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Location</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pointsRewardListSuccess.data.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.customerDetail ? element.customerDetail.firstName ? element.customerDetail.firstName : '' : ''} {element.customerDetail ? element.customerDetail.lastName ? element.customerDetail.lastName : '' : ''}</td>
                                    <td className="rl-table-col-item text-center">{element.customerDetail ? element.customerDetail.mobile : ''}</td>
                                    <td className="rl-table-col-item text-center">{element.points}</td>
                                    <td className="rl-table-col-item text-center">{element.businessName}</td>
                                    <td className="rl-table-col-item text-center">{element.city}, {element.state}, {element.country}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>Redeem</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                    {showFilteredList === true && !isNull(filteredList) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Number</th>
                                <th scope="col" className="rl-table-col-title text-center">Points</th>
                                <th scope="col" className="rl-table-col-title text-center">Business Name</th>
                                <th scope="col" className="rl-table-col-title text-center">Location</th>
                                <th scope="col" className="rl-table-col-title text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">{element.customerDetail ? element.customerDetail.firstName ? element.customerDetail.firstName : '' : ''} {element.customerDetail ? element.customerDetail.lastName ? element.customerDetail.lastName : '' : ''}</td>
                                    <td className="rl-table-col-item text-center">{element.customerDetail ? element.customerDetail.mobile : ''}</td>
                                    <td className="rl-table-col-item text-center">{element.points}</td>
                                    <td className="rl-table-col-item text-center">{element.businessName}</td>
                                    <td className="rl-table-col-item text-center">{element.city}, {element.state}, {element.country}</td>
                                    <td className="rl-table-col-item text-center" >
                                        <div className="resl-btn-container" onClick={() => { handleCardClick(element) }}>Redeem</div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {!isNull(pointsRewardListError) && <div className="error-text-container">{pointsRewardListError.message}</div>}
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowRedeemModal(false) }}
                show={showRedeemModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Redeem Points
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isNull(selectedRecord) && <div>
                        <div className='form-field-label-grey'>Customer Name</div>
                        <div className="form-field-text-label">{selectedRecord.customerDetail ? selectedRecord.customerDetail.firstName ? selectedRecord.customerDetail.firstName : '' : ''} {selectedRecord.customerDetail ? selectedRecord.customerDetail.lastName ? selectedRecord.customerDetail.lastName : '' : ''}</div>
                        <div className='form-field-label-grey'>Number</div>
                        <div className="form-field-text-label">{selectedRecord.customerDetail ? selectedRecord.customerDetail.mobile : ''}</div>
                        <div className='form-field-label-grey'>Available Points</div>
                        <div className="form-field-text-label">{selectedRecord.points}</div>
                        <FormFieldLabel label='Enter points to redeem' />
                        <CommonInputFloatFull
                            type='number'
                            id='points'
                            onChange={e => setPoints(e.target.value)}
                            placeholder='Points'
                            value={points}
                        />
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Submit' onClick={() => { handleRedeemClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default PointsRewardList