import axios from 'axios'
import { config } from '../../config'
import { APP_CONSTANTS } from '../../utils/AppConstants'
import API_ENDPOINTS from './endpoints'

export function addMediaAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'post',
        url: `${config.baseUrl}/${API_ENDPOINTS.media.addMedia}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function mediaListAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.media.list}`,
        params: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function mediaDetailAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'get',
        url: `${config.baseUrl}/${API_ENDPOINTS.media.detail}/${payload}`,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function mediaUpdateStatusAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.media.updateStatus}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}

export function mediaAddCommentAPI(payload) {
    const user = JSON.parse(sessionStorage.getItem(APP_CONSTANTS.USER))
    return axios.request({
        method: 'put',
        url: `${config.baseUrl}/${API_ENDPOINTS.media.addComment}`,
        data: payload,
        headers: {
            'authToken': user ? user.authToken : config.cid
        }
    })
}