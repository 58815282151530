import React, { useEffect, useState } from "react";
import './styles.css'
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import moment from "moment";
import { schemeListRequest } from '../../../redux/scheme/scheme.action'
import { mediaListRequest } from '../../../redux/media/media.action'
import { addInvoiceRequest, addInvoiceRequestStatus } from '../../../redux/invoice/invoice.action'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONSTANTS, APP_CONSTANTS } from "../../../utils/AppConstants";
import { displayErrorToast, displaySuccessToast, isEmpty, isLoggedInUserOperator, isNull } from "../../../utils/Utils";
import { useNavigate } from 'react-router-dom';
import CommonFormBorder from "../../../components/common-form-border/commonFormBorder";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import CommonButton from "../../../components/button/common-button/commonButton";
import { Checkbox } from "@mui/material";
import { CurrencyLabel } from "../../../utils/CurrencyLabel";

const CreateInvoice = props => {
    const [isLoading, setLoading] = useState(false)
    const { user } = useSelector(state => state.user)
    const { schemeListSuccess, schemeListError, commonError } = useSelector((state) => state.scheme)
    const { addInvoiceSuccess, addInvoiceError, addInvoiceReqStatus } = useSelector((state) => state.invoice)
    const { mediaListSuccess, mediaListError } = useSelector(state => state.media)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [schemeDropdown, setSchemeDropdown] = useState()
    const [currencyDropdown, setCurrencyDropdown] = useState()
    const [selectedMediaList, setSelectedMediaList] = useState([])
    const [displayMediaList, setDisplayMediaList] = useState(false)

    useEffect(() => {
        if (addInvoiceReqStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast(addInvoiceSuccess.message)
            setTimeout(() => {
                navigate(-1)
            }, 800)
        } else if (addInvoiceReqStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addInvoiceError.message)
        }
        if (addInvoiceReqStatus.length) {
            dispatch(addInvoiceRequestStatus(''))
        }
    }, [addInvoiceSuccess, addInvoiceError, addInvoiceReqStatus])

    const fetchSchemeList = (country = null, state = null, city = null) => {
        setLoading(true)
        const data = {}
        if (isLoggedInUserOperator(user)) {
            if (!isEmpty(country)) {
                data.country = country
            }
            if (!isEmpty(state)) {
                data.state = state
            }
            if (!isEmpty(city)) {
                data.city = city
            }
        }
        dispatch(schemeListRequest(data))
    }

    const fetchCustomerMediaList = (schemeId) => {
        const data = {
            schemeId: schemeId,
            receiptStatus: APP_CONSTANTS.CUSTOMER_MEDIA_STATUS.POINTS_REWARDED
        }
        setLoading(true)
        dispatch(mediaListRequest(data))
    }

    useEffect(() => {
        if (!isNull(schemeListSuccess)) {
            setLoading(false)
        } else if (!isNull(schemeListError)) {
            setLoading(false)
            displayMediaList(false)
        }
    }, [schemeListSuccess, schemeListError])

    useEffect(() => {
        if (!isNull(mediaListSuccess)) {
            setLoading(false)
            setDisplayMediaList(true)
        } else if (!isNull(mediaListError)) {
            setLoading(false)
            setDisplayMediaList(false)
        }
    }, [mediaListSuccess, mediaListError])

    const displaySchemeListToOperator = () => {
        if (isEmpty(user.data.userDetails.accessLocations)) {
            return false
        }
        if (isEmpty(countryDropdown)) {
            return false
        }
        if (!isEmpty(countryDropdown) && !isEmpty(countryDropdown?.stateCity) && isEmpty(stateDropdown)) {
            return false
        }
        if (!isEmpty(countryDropdown) && !isEmpty(countryDropdown?.stateCity) && !isEmpty(stateDropdown) && !isEmpty(stateDropdown?.city) && isEmpty(cityDropdown)) {
            return false
        }

        return true
        // return (
        //     !isEmpty(user.data.userDetails.accessLocations) &&
        //     !isEmpty(countryDropdown) &&
        //     (!isEmpty(countryDropdown?.stateCity) || !isEmpty(stateDropdown?.city))
        // );
    }

    const handleCreateInvoiceClick = () => {
        if (!isLoggedInUserOperator(user)) {
            displayErrorToast('Only operator is allowed to create invoice')
        } else if (!displaySchemeListToOperator()) {
            displayErrorToast('Location not selected')
        } else if (isEmpty(schemeDropdown)) {
            displayErrorToast('No scheme selected')
        } else if (isEmpty(currencyDropdown)) {
            displayErrorToast('No currency selected')
        } else if (isEmpty(selectedMediaList)) {
            displayErrorToast('No media selected')
        } else {
            const data = {
                businessId: schemeDropdown.businessId,
                operatorId: user.data.userDetails.userId,
                schemeId: schemeDropdown.schemeId,
                productCode: APP_CONSTANTS.PRODUCT_NAME,
                customerMediaIds: selectedMediaList,
                currencyLabel: currencyDropdown.code,
                currencySymbol: currencyDropdown.symbol,
                currencyRegion: currencyDropdown.region
            }
            setLoading(true)
            dispatch(addInvoiceRequest(data))
        }
    }

    const isMediaSelected = customerMediaId => {
        if (!selectedMediaList.includes(customerMediaId)) {
            return false
        } else return true
    }

    const addOrRemoveFromSelectedMedia = (customerMediaId) => {
        setSelectedMediaList((currentList) => {
            const receiptIndex = currentList.indexOf(customerMediaId);
            if (receiptIndex === -1) {
                return [...currentList, customerMediaId]; // Add the item if it doesn't exist
            } else {
                const updatedList = [...currentList];
                updatedList.splice(receiptIndex, 1); // Remove the item if it exists
                return updatedList;
            }
        });
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Create New Invoice' />
                <CommonFormBorder>
                    <SubTextLabel label='Enter below details to create new invoice' />
                    {isLoggedInUserOperator(user) && <div>
                        <FormFieldLabel className='up-bold-label' label='Select Location' />
                        {isEmpty(user.data.userDetails.accessLocations) && <div>No location access provided. Kindly request access to view receipts</div>}
                        {!isEmpty(user.data.userDetails.accessLocations) &&
                            <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={countryDropdown}
                                getOptionLabel={e => e.country}
                                getOptionValue={e => e}
                                options={user.data.userDetails.accessLocations}
                                placeholder='Select Country'
                                onChange={e => {
                                    setCountryDropdown(e)
                                    if (isEmpty(e.stateCity)) {
                                        setDisplayMediaList(false)
                                        fetchSchemeList(e.country)
                                    }
                                }} />
                        }
                        {!isEmpty(user.data.userDetails.accessLocations) && !isNull(countryDropdown) &&
                            !isEmpty(countryDropdown?.stateCity) &&
                            <CommonInputRow>
                                <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={stateDropdown}
                                    getOptionLabel={e => e.state}
                                    getOptionValue={e => e}
                                    options={countryDropdown.stateCity}
                                    placeholder='Select State'
                                    onChange={e => {
                                        setStateDropdown(e)
                                        if (isEmpty(e.city)) {
                                            setDisplayMediaList(false)
                                            fetchSchemeList(countryDropdown.country, e.state)
                                        }
                                    }} />
                                {!isEmpty(stateDropdown?.city) && <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={cityDropdown}
                                    getOptionLabel={e => e.value}
                                    getOptionValue={e => e}
                                    options={stateDropdown.city.map(city => {
                                        return { key: city, value: city };
                                    })}
                                    placeholder='Select City'
                                    onChange={e => {
                                        setCityDropdown(e)
                                        fetchSchemeList(countryDropdown.country, stateDropdown.state, e.value)
                                    }} />}
                            </CommonInputRow>}
                        {isLoggedInUserOperator(user) && displaySchemeListToOperator() &&
                            <div>
                                {!isEmpty(schemeListSuccess) &&
                                    <div>
                                        <FormFieldLabel className='up-bold-label' label='Select Scheme' />
                                        <Select
                                            menuPlacement="auto"
                                            className='select-full-width-no-border'
                                            value={schemeDropdown}
                                            getOptionLabel={e => e.schemeName}
                                            getOptionValue={e => e}
                                            options={schemeListSuccess.data}
                                            placeholder='Select Scheme'
                                            onChange={e => {
                                                setSchemeDropdown(e)
                                                fetchCustomerMediaList(e.schemeId)
                                            }} />
                                    </div>}
                                {!isNull(schemeListError) && <div className="error-text-container">{schemeListError.message}</div>}

                            </div>
                        }
                        {isLoggedInUserOperator(user) && displaySchemeListToOperator() && !isNull(schemeDropdown) &&
                            <div>
                                <FormFieldLabel className='up-bold-label' label='Select Currency' />
                                <Select
                                    menuPlacement="auto"
                                    className='select-full-width-no-border'
                                    value={currencyDropdown}
                                    getOptionLabel={e => `${e.symbol} (${e.code})`}
                                    getOptionValue={e => e}
                                    options={CurrencyLabel}
                                    placeholder='Select Currency'
                                    onChange={e => {
                                        setCurrencyDropdown(e)
                                    }} />
                            </div>
                        }
                    </div>}
                    {!isNull(schemeDropdown) && displayMediaList && !isNull(mediaListSuccess) && <FormFieldLabel className='up-bold-label' label='Select Customer Media' />}
                    {!isNull(schemeDropdown) && displayMediaList && !isNull(mediaListSuccess) && mediaListSuccess.data.map(e => (
                        <div className="ci-receipt-list-item-container">
                            <div>
                                <div className="ci-receipt-number-label">Media Number : {e.customerMediaId}</div>
                                {e.invoiceNumber > 0 && <div className="ci-invoice-warning">Invoice already created for customer media with Invoice number: {e.invoiceNumber}</div>}
                            </div>
                            <Checkbox
                                checked={isMediaSelected(e.customerMediaId)}
                                onChange={e1 => addOrRemoveFromSelectedMedia(e.customerMediaId)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color="success"
                                size="medium" />
                        </div>
                    ))}
                    {!isNull(mediaListError) && <div>No vendor approved customer media available</div>}
                    <ButtonRow>
                        <CommonButton label='Create Invoice' onClick={() => {
                            handleCreateInvoiceClick()
                        }} />
                    </ButtonRow>
                </CommonFormBorder>
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default CreateInvoice