export const ROUTES = {
    home: `/`,
    customerLogin: '/customerLogin',
    vendorLogin: '/vendorLogin',
    vendorCreateAccount: '/vendorCreateAccount',
    operatorRegister: '/operatorRegister',
    operatorList: '/operatorList',
    operatorDetail: '/operatorDetail',
    customerProfile: '/customerProfile',
    promotionList: '/promotionList',
    addScheme: '/addPromotion',
    schemeDetail: '/promotionDetail',
    mediaList: '/mediaList',
    mediaDetail: '/mediaDetail',
    uploadMedia: '/uploadMedia',
    invoiceList: '/invoiceList',
    invoiceDetail: '/invoiceDetail',
    createInvoice: '/createInvoice',
    pointsRequestList: '/pointsRequestList',
    privacyPolicy: '/privacyPolicy',
    cookiePolicy: '/cookiePolicy',
    tutorial: {
        vendor: '/tutorial/vendor',
        operator: '/tutorial/operator',
        customer: '/tutorial/customer'
    },
    pointsRewardList: '/pointsRewardList',
    vendorList: '/vendorList',
    qrCode: '/qrCode'
}

export const API_CONSTANTS = {
    SUCCESS_STATUS: 'SUCCESS_STATUS',
    ERROR_STATUS: 'ERROR_STATUS'
}

export const ORGANISATION_DETAIL = {
    ORGANISATION_NAME: 'GoMoBites LLC',
    ORG_ADDRESS_LINE2: 'Dublin, CA 94568',
    ORG_SUPPORT_EMAIL: 'support@gomobites.com'
}

export const APP_CONSTANTS = {
    PRODUCT_NAME: 'MINTBRAND',
    USER: 'USER',
    USER_TOKEN: 'USER_TOKEN',
    LOGOUT: 'LOGOUT',
    RESET_STATE_CITY: 'RESET_STATE_CITY',
    DISPLAY_COOKIE_CONSENT: 'DISPLAY_COOKIE_CONSENT',
    DISPLAY_NEWS_LETTER_CONSENT: 'DISPLAY_NEWS_LETTER_CONSENT',
    MENU_ITEM_TYPE: {
        OPERATOR_LIST: 'OPERATOR_LIST',
        PROMOTION_LIST: 'PROMOTION_LIST',
        MEDIA_LIST: 'MEDIA_LIST',
        INVOICE_LIST: 'INVOICE_LIST',
        CASHBACK_LIST: 'CASHBACK_LIST',
        PROFILE: 'PROFILE',
        POINTS_REWARD: 'POINTS_REWARD',
        VENDOR_LIST: 'VENDOR_LIST',
        QR_CODE: 'QR_CODE'
    },
    MENU_ITEM_DISPLAY_NAME: {
        OPERATOR_LIST: 'Operators',
        VENDOR_LIST: 'Vendors',
        PROMOTION_LIST: 'Promotions',
        INVOICE_LIST: 'Invoices',
        MEDIA_LIST: 'Medias',
        CASHBACK_LIST: 'Cashback',
        PROFILE: 'Profile',
        LOGOUT: 'Log Out',
        POINTS_REWARD: 'Points Reward',
        QR_CODE: 'QR Code'
    },
    ENVIRONMENT: {
        LOCAL: 'LOCAL',
        LOCAL_CUSTOMER: 'LOCAL_CUSTOMER',
        DEV: 'DEV',
        PROD: 'PROD'
    },
    KEY_FCM_TOKEN: 'KEY_FCM_TOKEN',
    SESSION_EXPIRE_MESSAGE: 'Your session expire. Please try login again',
    COMMON_ERROR: 'COMMON_ERROR',
    EMAIL_ALREADY_SUBSCRIBED_ERROR_MESSAGE: 'Email already subscribed',
    DEVICE_TYPE: {
        ANDROID: 'ANDROID',
        IOS: 'IOS',
        DESKTOP: 'DESKTOP',
        WINDOWS: 'WINDOWS',
        MAC_OS: 'MAC_OS'
    },
    KEY_DEVICE_ID: 'deviceId',
    USER_ROLE_TYPE: {
        PRODUCT_OWNER: 'PRODUCT_OWNER',
        OPERATOR: 'OPERATOR',
        VENDOR: 'VENDOR',
        CUSTOMER: 'CUSTOMER'
    },
    CUSTOMER_MEDIA_STATUS: {
        DRAFT: 'DRAFT',
        PENDING: 'PENDING',
        POINTS_REWARDED: 'POINTS_REWARDED',
        CANCELLED: 'CANCELLED',
        DISPUTED: 'DISPUTED',
        ERROR: 'ERROR',
        COMPLETED: 'COMPLETED',
        CUSTOMER_ACTION_REQUIRED: 'CUSTOMER_ACTION_REQUIRED'
    },
    PAYMENT_STATUS: {
        PENDING: 'PENDING',
        PAID: 'PAID',
        ERROR: 'ERROR'
    },
    CASHBACK_REQUEST_STATUS: {
        PENDING: 'PENDING',
        IN_PROCESS: 'IN_PROCESS',
        PAID: 'PAID',
        REJECTED: 'REJECTED',
        ERROR: 'ERROR'
    }

}