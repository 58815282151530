import { PointsRewardActionTypes } from './pointsReward.types'
import { API_CONSTANTS } from '../../utils/AppConstants'

const INITIAL_STATE = {
    pointsRewardListSuccess: null,
    pointsRewardListError: null,
    redeemPointsSuccess: null,
    redeemPointsError: null,
    redeemPointsReqStatus: ''
}

const pointsRewardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PointsRewardActionTypes.POINTS_REWARD_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                pointsRewardListSuccess: action.payload,
                pointsRewardListError: null
            }
        case PointsRewardActionTypes.POINTS_REWARD_LIST_REQUEST_ERROR:
            return {
                ...state,
                pointsRewardListSuccess: null,
                pointsRewardListError: action.payload
            }
        case PointsRewardActionTypes.REDEEM_POINTS_REQUEST_SUCCESS:
            return {
                ...state,
                redeemPointsSuccess: action.payload,
                redeemPointsError: null,
                redeemPointsReqStatus: API_CONSTANTS.SUCCESS_STATUS
            }
        case PointsRewardActionTypes.REDEEM_POINTS_REQUEST_ERROR:
            return {
                ...state,
                redeemPointsSuccess: null,
                redeemPointsError: action.payload,
                redeemPointsReqStatus: API_CONSTANTS.ERROR_STATUS
            }
        case PointsRewardActionTypes.REDEEM_POINTS_REQUEST_STATUS:
            return {
                ...state,
                redeemPointsReqStatus: ''
            }
        case PointsRewardActionTypes.LOGOUT:
            return INITIAL_STATE
        default:
            return state
    }
}

export default pointsRewardReducer