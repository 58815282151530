import React, { useEffect, useState } from "react";
import './styles.css'
import NavigationBar from "../navigation-bar/navigationBar";
import { useNavigate, useSearchParams } from 'react-router-dom';
import CommonButton from "../../components/button/common-button/commonButton";
import { ROUTES } from "../../utils/AppConstants";
import PartnerComponent from "../partner-component/partnerComponent";

const HomePage = props => {
    let navigate = useNavigate();

    const handleViewPromotions = () => {
        navigate(ROUTES.promotionList)
    }

    const handlePrivacyPolicyClick = () => {
        navigate(ROUTES.privacyPolicy)
    }

    const handleCookiePolicyClick = () => {
        navigate(ROUTES.cookiePolicy)
    }

    const mailToSupportClickHandler = () => {
        window.location = "mailto:info@mintbrand.buzz"
    }
    return (<>
        <NavigationBar navigate={navigate} />
        <div className="hero">
            <header className="container">
                <div className="row hero-card m-0 p-0">
                    <div class="col-sm-7 m-0 p-0 hero-content">
                        <h1 className="hero-heading">Customer-Influencers, Loyalty Points, Promotions, and Beyond...</h1>
                        <div className="hero-sub-heading">Enhance your brand visibility by rewarding, incentivizing, and sharing experiences.</div>
                        <div className="hero-button-container">
                            <CommonButton label='View Promotions'
                                onClick={() => { handleViewPromotions() }} />
                        </div>
                    </div>
                    <div class="col-sm-5 m-0 p-0 hero-image-container">
                        <div>
                            <img src={require('../../assets/home/header_img.png')} className='img' />
                        </div>

                    </div>
                </div>
            </header>
        </div>
        <section className="section-one">
            <h1 className="heading-one">Hop on the MintBrand Adventure!</h1>
            <div className="sub-heading-one">Have you ever considered gifting loyalty points to customer-influencers instead of cash? That's precisely what we provide - a innovative loyalty reward program that recognizes and rewards creative customers for sharing their experiences on social media. It's a win-win-win situation! Creative customers willingly promote your brand at no upfront cost, incentivizing your most loyal and imaginative patrons. This approach not only enhances your visibility on social media but also attracts new patrons who discover your brand through these promotions.</div>
            <div className="hp-feature-row">
                <div className="hp-feature-card">Innovative Loyalty Program</div>
                <div className="hp-feature-card">No Up-Front Cost</div>
                <div className="hp-feature-card">Recognition For Creativity</div>
                <div className="hp-feature-card">Empowered Customer Advocacy</div>
                <div className="hp-feature-card">Social Visibility Growth</div>
                <div className="hp-feature-card">Attraction of New Customers</div>
                <div className="hp-feature-card">Business Growth</div>
            </div>
        </section>
        <div className="quote-section-container">
            <h1 className="text-center">A Community Where Your Brand Meets Social Alchemy</h1>
            <h6 className="text-center">Transform your Customer's experiences with our Loyalty Program and enhance your social media visibility effortlessly by influencers!</h6>
            <h5>MintBrand.Buzz</h5>
        </div>
        <section className="outcome-section-container">
            <div className='row'>
                <div className='col-sm-6'>
                    <img alt="" src={require('../../assets/home/outcome_img.png')} className='img' />
                </div>
                <div className='col-sm-6 hp-container-2'>
                    <h5 className="outcome-section-heading">Your Brands Outcome</h5>
                    <p className="outcome-section-text">&#x2022; Boosts brand visibility on social media.</p>
                    <p className="outcome-section-text">&#x2022; Recognition turns customers into advocates.</p>
                    <p className="outcome-section-text">&#x2022; Loyalty points maximize marketing returns.</p>
                    <p className="outcome-section-text">&#x2022; Customer-influencers expand brand reach.</p>
                    <p className="outcome-section-text">&#x2022; Engaging with influencers builds a loyal community.</p>
                    <p className="outcome-section-text">&#x2022; Regular optimization ensures program effectiveness.</p>
                    <p className="outcome-section-text mb-3">&#x2022; A unique loyalty approach sets the brand apart positively.</p>
                    <h5 className="outcome-section-heading">Your Consumer’s Outcome</h5>
                    <p className="outcome-section-text">&#x2022; Engage customers for tangible benefits and incentives.</p>
                    <p className="outcome-section-text">&#x2022; Share experiences for personal validation and acknowledgment.</p>
                    <p className="outcome-section-text">&#x2022; Loyalty points enhance the value of customer engagement.</p>
                    <p className="outcome-section-text">&#x2022; Promote the brand easily and reap rewarding outcomes.</p>
                    <p className="outcome-section-text">&#x2022; Creative promotions directly boost brand visibility and influence.</p>
                    <p className="outcome-section-text">&#x2022; Exclusive rewards motivate, fostering customer appreciation.</p>
                    <p className="outcome-section-text">&#x2022; Personal engagement creates a profound emotional connection.</p>
                </div>
            </div>
        </section>
        <PartnerComponent />
        <div className='screenFooter'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4 colElementItemCenter'>
                        <div className='footer-logo-container'>
                            <img src={require('../../assets/logo_new.png')} className='img' />
                        </div>
                    </div>
                    <div className='col-sm-4 colElement'>
                        <div className="colElementHeading">Links</div>
                        <div className="h-sub-link-text" onClick={() => { handleViewPromotions() }}>Promotions</div>
                        <div className="h-sub-link-text" onClick={() => { handlePrivacyPolicyClick() }}>Privacy Policy</div>
                        <div className="h-sub-link-text" onClick={() => { handleCookiePolicyClick() }}>Cookie Policy</div>
                    </div>
                    {/* <div className='col-sm-3 colElement'>
              <div className="colElementHeading">Tutorials</div>
              <div className="h-sub-link-text" onClick={() => { handleTutorialVendor() }}>Vendor (or Merchant)</div>
              <div className="h-sub-link-text" onClick={() => { handleTutorialCustomer() }}>Customer</div>
            </div> */}
                    <div className="col-sm-4 colElementCenter">
                        <div className="colElementHeadingLogo">Contact</div>
                        <div className="colElement14Label" onClick={() => { mailToSupportClickHandler() }}>info@mintbrand.buzz</div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default HomePage